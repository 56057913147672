var React = require('react');
var Parse = require('parse').Parse;
var ParseReact = require('parse-react-2');

var Contact = React.createClass({displayName: "Contact",
  mixins: [ParseReact.Mixin],

  getInitialState: function() {
    return {
      messageSent:false
    };
  },
  
  observe: function() {
    return {
      user: ParseReact.currentUser
    };
  },

  getVal: function(prop){
    var val = React.findDOMNode(prop)
    if(val!=undefined && val!=null){
      return val.value;
    }
    return '';
  },

  sendMessage: function(){
    var self = this;
    var registeredUser = this.data.user?true:false;
    var name = this.getVal(this.refs.name);
    var email = this.getVal(this.refs.email);
    var message = this.getVal(this.refs.message);
    var userId=''
    if(registeredUser){
      userId = this.data.user.objectId
    }

    console.log({
      name:name,
      email:email,
      registeredUser:registeredUser,
      userId:userId,
      message:message
    })
    var formData = new FormData();

    formData.append('name', name);
    formData.append('email', email);
    formData.append('user', userId);
    formData.append('message', message);

    $.ajax({
      url: '/acct/smsg',
      type : 'POST',
      data : formData,
      processData: false,  // tell jQuery not to process the data
      contentType: false,  // tell jQuery not to set contentType
      success : function(data) {
        self.setState({
          messageSent:true
        })
      }
    })

  },

  render: function() {

    var content=''
    if(!this.state.messageSent){

      var email = ''
      if(this.data && this.data.user && this.data.user.email){
        email = this.data.user.email
        console.log(email)
      }
      console.log('render')

      content= (
        React.createElement("div", null, 
          React.createElement("h1", {className: "howHeader"}, "Contact Us"), 
            React.createElement("div", {style: {marginLeft:'10%'}}, 
              React.createElement("p", null, 
                "Please use the form below to let us know if you have any questions.", React.createElement("br", null), 
                "If you would like to reach us by phone, please call Chris Muffels at (301) 718-8900."
              ), 

              React.createElement("span", {className: "labelField"}, "Name:"), React.createElement("br", null), 
              React.createElement("input", {
                className: "rewardField", 
                type: "text", 
                ref: "name", 
                placeholder: "name", 
                defaultValue: ''}), 
              React.createElement("br", null), React.createElement("br", null), 

              React.createElement("span", {className: "labelField"}, "Email:"), React.createElement("br", null), 
              React.createElement("input", {
                className: "rewardField", 
                type: "text", 
                ref: "email", 
                style: {width:'300px!important'}, 
                placeholder: "email address", 
                defaultValue: email}), 
              React.createElement("br", null), React.createElement("br", null), 

              React.createElement("span", {className: "labelField"}, "Message:"), React.createElement("br", null), 
              React.createElement("textarea", {
                rows: 10, 
                columns: 80, 
                style: {width:'400px'}, 
                type: "text", 
                ref: "message", 
                placeholder: "message", 
                defaultValue: ''}), 
              React.createElement("br", null), React.createElement("br", null), 
              React.createElement("button", {onClick: this.sendMessage, className: "StripeCheckout stripeButton", style: {width:'135px'}}, 
                React.createElement("span", null, "Send")
              ), 
              React.createElement("br", null), React.createElement("br", null)
            )
        )
      )
    }
    else{
      content= (
        React.createElement("div", null, 
          React.createElement("h1", {className: "howHeader"}, "Contact Us"), 

          React.createElement("div", {style: {marginLeft:'10%'}}, 
            React.createElement("p", null, 
              "Your message has been sent"
            ), 
            React.createElement("br", null), React.createElement("br", null)
          )
        )
      )

    }
    return content

  }

});

module.exports = Contact;