var React = require('react');
var Parse = require('parse').Parse;
var ParseReact = require('parse-react-2');

var Lightbox= require('../Lightbox/Lightbox.react.js');
var LightboxModal= require('../Lightbox/LightboxModal.react.js');
var LightboxTrigger= require('../Lightbox/LightboxTrigger.react.js');


var ModelDetails= require('./ModelDetails.js');
var NewModel= require('./NewModel.js');

var Models = React.createClass({displayName: "Models",
  mixins: [ParseReact.Mixin],

  getInitialState: function() {
    return {
      user:null,
      customerId:null,
      jobStatus:{},
      page:0,
      pageLimit:10
    }
  },
  // componentWillUnMount: function(){
  //   console.log({models:'componentWillUnMount'});
  //   socket.removeAllListeners(this.props.user);
  // },

  componentDidMount: function() {
    var self = this;
    if(Parse.User.current()){
      var customerId = Parse.User.current().get('stripeCustomer')
      this.setState({
        user:Parse.User.current(),
        customerId:customerId
      },this.getCards)
    }
    else{
      console.log('Not Logged In')
    }
    socket.on(this.props.user, function(m){
      console.log({models:'socketEvent',d:m});
      //{jobStatus:{job:job.id,status:notifyStatus}}
      if(!self.isLightBoxOpen && m.jobStatus && m.jobStatus.job && m.jobStatus.status){
        var js=self.state.jobStatus
        js[m.jobStatus.job]=m.jobStatus.status
        // ParseReact.Mutation.Set(
        //   {className: 'UserJob', objectId: m.jobStatus.job},
        //   {touch:new Date().getTime()})
        // .dispatch()
        // console.log(js)

        self.setState({jobStatus:js})

        // setTimeout(function() {
        //   self.refreshQueries()
        // }, 1000);
      }
      console.log(m)
    });
  },
  
  lightboxOpened: function(){
    this.isLightBoxOpen = true;
    // console.log('LightBoxOpen')
  },
  lightboxClosed: function(){
    var self=this;
    this.isLightBoxOpen = false;
    // console.log('LightBoxClosed')

    setTimeout(function() {
      self.refreshQueries()
    }, 1000);
  },
  observe: function(props, state) {
    console.log({observe:arguments})
    return {
      jobs:(new Parse.Query('UserJob')).exists('expires').notEqualTo('deleted',true).descending("createdAt").limit(state.pageLimit).skip(state.page * state.pageLimit)
    };
  },
  nextPage: function(){
    var page = this.state.page
    page++;
    console.log({nextPage:page})
    this.setState({
      page: page
    })
  },
  prevPage: function(){
    var page = this.state.page
    page--;
    page = Math.max(0,page)
    console.log({prevPage:page})
    this.setState({
      page: page
    })
  },
  render: function() {
    var self=this;

    
    var content=React.createElement("div", {className: "loading"});
      console.log({render:this.data.jobs})

    if (!this.pendingQueries().length) {


                    // <Lightbox key={job.objectId+'_view'}>
                    //   <LightboxTrigger>
                    //       <a href='#'>Details</a>
                    //   </LightboxTrigger>
                    //   <LightboxModal>
                    //     <ModelDetails 
                    //       key={'modelDetails_'+job.objectId}
                    //       user={self.props.user} 
                    //       job={job} 
                    //       customerId={self.state.customerId} 
                    //       gotoAccount={self.props.gotoAccount} />
                    //   </LightboxModal>
                    // </Lightbox>
    if(self.data.jobs){
        var prevPageLink = React.createElement("span", null, "   ", React.createElement("a", {onClick: self.prevPage}, '<<<'), "   ")
        var nextPageLink = React.createElement("span", null, "   ", React.createElement("a", {onClick: self.nextPage}, '>>>'), "   ")

        if(self.state.page==0){
          prevPageLink = null
        }
        if(self.data.jobs.length<10){
          nextPageLink = null
        }

        var modelTable = null;
        if(self.data.jobs && self.data.jobs.length){
          modelTable = []
          modelTable.push(React.createElement("span", {style: {fontSize:'.8em'}}, "This table records the status of all uploaded PEST cases."))
          modelTable.push(
            React.createElement("table", {key: "modelTable", className: "jobTable"}, 
              React.createElement("thead", null, 
                React.createElement("tr", null, 
                  React.createElement("td", null, "id"), 
                  React.createElement("td", null, "Model Name"), 
                  React.createElement("td", null, "Created"), 
                  React.createElement("td", null, "Status"), 
                  React.createElement("td", null, "Action")

                )
              ), 
              React.createElement("tbody", null, 
            $.map(self.data.jobs,function(job,jidx){
              var status = self.state.jobStatus[job.objectId] || job.status
              return (
                React.createElement("tr", {key: 'modelRow_'+job.objectId}, 
                  React.createElement("td", null, job.objectId), 
                  React.createElement("td", null, job.modelName), 
                  React.createElement("td", null, job.createdAt.toISOString().replace(/[A-Z]/gi,' ').trim().split('.')[0]), 
                  React.createElement("td", null, status), 
                  React.createElement("td", null, 
                    React.createElement("a", {onClick: function(){self.props.modelDetails(job)}}, "Details")
                  )
                )
              )
            }), 
                React.createElement("tr", null, 
                  React.createElement("td", {colSpan: "5", style: {textAlign:'center'}}, 
                    prevPageLink, 
                    nextPageLink
                  )
                )
              )
            )
          )
        }
        else{
          modelTable=React.createElement("span", null, "You currently have no PEST cases uploaded.")
        }

        content = (
          React.createElement("div", null, 
            React.createElement("div", null), React.createElement("br", null), 

            React.createElement("span", {className: "labelField"}, "My PEST Cases:"), React.createElement("br", null), 
            React.createElement("br", null), 
            React.createElement(Lightbox, {key: 'newModel', opened: self.lightboxOpened, closed: self.lightboxClosed}, 
              React.createElement(LightboxTrigger, null, 
                  React.createElement("a", {className: "button buttonJob"}, React.createElement("span", null, "New"))
              ), 
              React.createElement(LightboxModal, null, 
                React.createElement(NewModel, {user: self.props.user, modelDetails: self.props.modelDetails})
              )
            ), 
            React.createElement("br", null), 

            React.createElement("br", null), 
            modelTable
            
          )
        )
      }

    }
    return (
      React.createElement("div", {className: "appContent"}, 
        content
      )
    );
      
  },

});

module.exports = Models;