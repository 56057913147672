

var React = require('react/addons');


var Lightbox = React.createClass({displayName: "Lightbox",

    getInitialState: function(){
        return { display: false };
    },
    componentWillReceiveProps: function(p) {
     console.log('Lightbox:props()');
     this.props = p;
    },

    componentWillMount: function(){
        if (this.props.data)
            this.setState(this.props.data);
    },

    openLightbox: function(){
        this.setState({display: true});
        if(this.props.opened){
            this.props.opened(true)
        }
    },

    closeLightbox: function(){
        this.setState({display: false});
        if(this.props.closed){
            this.props.closed(true)
        }
    },

    setLightboxState: function(obj){
        this.setState(obj);
    },

    render: function(){
        var childrenWithProps = this.props.children.map(function(child, i) {
            var childProps = {
                openLightbox: this.openLightbox,
                closeLightbox: this.closeLightbox,
                setLightboxState: this.setLightboxState,
                key: i
            };
            for (var j in this.state){
                childProps[j] = this.state[j];
            }
            var childWithProps = React.addons.cloneWithProps(child, childProps);
            return childWithProps;
        }, this);
console.log(this.props.style)
        return (
            React.createElement("div", {style: this.props.style}, 
                childrenWithProps
            )
        );
    }
});

module.exports = Lightbox;