var React = require('react');
var Parse = require('parse').Parse;

//Parse.initialize('APPLICATION_ID', 'JAVASCRIPT_KEY');
//Production
Parse.initialize('nLUGPDgYqrTgeYTtF6ozQJU6qQjQS8D9oVFtsUj7', 'y5kPUKjkxa2Zzb4hLzaKdmbn4TPrQ1NLBG1ZMKvC');
// Dev
// Parse.initialize('ohnbZ5tc7jsp5G7dpDArFAmNUZw27zSvfYh4mj9k', '3WIZcvW1G5zK46p3zPaakKg9TOAQtp19tVzkDuWS');

Parse.serverURL = 'https://parseapi.back4app.com/'

var LoginWrapper = require('./LoginWrapper.js');

React.render(
  React.createElement(LoginWrapper, null),
  document.getElementById('app')
);