var React = require('react');
var Parse = require('parse').Parse;
var ParseReact = require('parse-react-2');

var Lightbox= require('../Lightbox/Lightbox.react.js');
var LightboxModal= require('../Lightbox/LightboxModal.react.js');
var LightboxTrigger= require('../Lightbox/LightboxTrigger.react.js');



var TermsAndConditions = require('./TermsAndConditions.js')
var PrivacyPolicy = require('./PrivacyPolicy.js')

var Home = React.createClass({displayName: "Home",

  getInitialState: function() {
    return {
      error: null,
      signup: this.props.signup || false,
      forgot: false
    };
  },
  
  observe: function() {
    return {
    };
  },

  lightboxOpened: function(){
    this.isLightBoxOpen = true;
    // console.log('LightBoxOpen')
  },
  lightboxClosed: function(){
    var self=this;
    this.isLightBoxOpen = false;
    // console.log('LightBoxClosed')
  },
  render: function() {
    var self=this;
    var emailField = null
    var passField = null
    var verifyemailField = null
    var termsPrivacy = null

    if(this.state.signup || this.state.forgot){
      verifyemailField = (
        React.createElement("div", {className: "row"}, 
          React.createElement("label", {htmlFor: "email"}, "Verify Email"), 
          React.createElement("input", {ref: "verifyemail", id: "email", type: "text"})
        )
      )
      if(this.state.signup)
      {
        termsPrivacy = (
          React.createElement("div", null, 
            React.createElement("div", {className: "rowTerms"}, 
              React.createElement("input", {ref: "acceptTerms", id: "acceptTerms", type: "checkbox"}), 
              React.createElement(Lightbox, {style: {display:'inline-block'}, key: 'modalTOS', opened: self.lightboxOpened, closed: self.lightboxClosed}, 
                React.createElement(LightboxTrigger, null, 
                    React.createElement("label", {htmlFor: "acceptTerms"}, "I accept the ", React.createElement("a", null, "terms of service agreement"))
                ), 
                React.createElement(LightboxModal, null, 
                    React.createElement(TermsAndConditions, null)
                )
              )
            ), 
            React.createElement("div", {className: "rowTerms"}, 
              React.createElement("input", {ref: "acceptPrivacy", id: "acceptPrivacy", type: "checkbox"}), 
              React.createElement(Lightbox, {style: {display:'inline-block'}, key: 'modalPP', opened: self.lightboxOpened, closed: self.lightboxClosed}, 
                React.createElement(LightboxTrigger, null, 
                    React.createElement("label", {htmlFor: "acceptPrivacy"}, "I have read the ", React.createElement("a", null, "privacy policy"))
                ), 
                React.createElement(LightboxModal, null, 
                    React.createElement(PrivacyPolicy, null)
                )
              )
            )
          )
        )
        
      }
    }
    if(!this.state.forgot){
      emailField = (
        React.createElement("div", {className: "row"}, 
          React.createElement("label", {htmlFor: "email"}, "Email"), 
          React.createElement("input", {ref: "email", id: "email", type: "text"})
        )
      )

      passField = (
        React.createElement("div", {className: "row"}, 
          React.createElement("label", {htmlFor: "password"}, "Password"), 
          React.createElement("input", {ref: "password", id: "password", type: "password"})
        )
      )

    }
    return (
      React.createElement("div", null, 
        React.createElement("h1", null), 
        React.createElement("h2", null), 
        React.createElement("div", {className: "loginForm", onKeyDown: this.keyDown}, 
          
            this.state.error ?
            React.createElement("div", {className: "row centered errors"}, this.state.error) :
            null, 
          
          emailField, 
          verifyemailField, 
          passField, 
          termsPrivacy, 
          React.createElement("div", {className: "row centered"}, 
            React.createElement("a", {className: "button", onClick: this.submit}, 
              this.state.forgot ? 'Reset' : (this.state.signup ? 'Sign up' : 'Log in')
            )
          ), 
          React.createElement("div", {className: "row centered"}, 
              
            React.createElement("a", {onClick: this.toggleSignup}, 
              this.state.signup ? 'log in' : 'sign up'
            ), 
            React.createElement("a", {className: "forgotLink", onClick: this.forgot}, 
              this.state.forgot ? 'log in' : 'forgot'
            )
          )
        )
      )
    );
  },
  

  submit: function() {
    var self = this;
    var email = null;
    var acceptPrivacy = false;
    var acceptTerms = false;

    if (this.state.signup || this.state.forgot) {
        var email = React.findDOMNode(this.refs.verifyemail).value;
        email = email.toLowerCase();

        if(this.state.signup){
          acceptPrivacy = React.findDOMNode(this.refs.acceptPrivacy).checked;
          acceptTerms = React.findDOMNode(this.refs.acceptTerms).checked;
          if(!acceptPrivacy || ! acceptTerms){          
            this.setState({
              error: 'Please review our terms and privacy policy to continue.'
            });
            return;

          }
        }

        if(this.state.forgot){
          if(email.length){
            Parse.User.requestPasswordReset(email, {
              success: function() {            
                self.setState({
                  error: 'Reset instructions have been sent'
                }); 
              },
              error: function(error) {
                self.setState({
                  error: "Ooops - we couldn't find your account.  Try again."
                }); 
              }
            });           
          }
          else{            
            this.setState({
              error: 'Please enter valid email address'
            });
          }
          return;
        }
    }
    var username = React.findDOMNode(this.refs.email).value;
    var password = React.findDOMNode(this.refs.password).value;
    

    if (username.length && password.length) {

      username = username.toLowerCase();
      password = password;
      
      if (this.state.signup) {
        
        if(!email.length){
          this.setState({
            error: 'Please enter all fields'
          });
          return;
        }
        if(username != email){
          this.setState({
            error: 'Please confirm email'
          });
          return;
        }

        // console.log('signup');
        var u = new Parse.User({
          username: username,
          password: password,
          email:username,
          acceptPrivacy:acceptPrivacy,
          acceptTerms:acceptTerms 

        });
        u.signUp().then(function() {
          //do somethign after signup
          Parse.Cloud.run('sendSignupEmail', { to: username}, {
          success: function(res) {
            console.log('new user email sent')
          },
          error: function(err){
            console.log('new user email not sent')
          }
        })

        }, function(suErr) {
          // console.log(suErr)
          self.setState({
            error: 'Invalid account information: ' + suErr.message
          });
        });
      } else {
        Parse.User.logIn(username, password).then(function() {
          self.setState({
            error: null
          });
        }, function() {
          self.setState({
            error: 'Incorrect username or password'
          });
        });
      }
    } else {
      this.setState({
        error: 'Please enter all fields'
      });
    }
  },

  keyDown: function(e) {
    if (e.keyCode === 13) {
      this.submit();
    }
  },

  toggleSignup: function() {
    this.setState({
      error:'',
      signup: !this.state.signup,
      forgot:false
    });
  },
  forgot: function() {
    this.setState({
      error:'',
      signup: false,
      forgot:!this.state.forgot
    });
  }

});

module.exports = Home;