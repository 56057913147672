var React = require('react');
var Parse = require('parse').Parse;
var ParseReact = require('parse-react-2');

var Spinner = require('react-spinkit');


var ModelDetails = React.createClass({displayName: "ModelDetails",
  mixins: [ParseReact.Mixin],

  getInitialState: function() {
    console.log(this.props)
    return {
      analyzingPCost:true,
      uploadFile:null,
      modelName:this.props.modelName||'',
      cpuCount:10,
      anyForbiddenFiles:[],
      allPstFiles:[],
      allHpFiles:[],
      pstFile:null,
      isHPFile:false,
      useHPFile:false,
      useMKL:false,
      pestcheckRun:false,
      useWhisper:false,
      cloneId:this.props.cloneId,
      newModel:null,
      pcost_result:{},
      // zip_id:new Date().getTime()
    }
  },


  componentDidMount: function() {
  },
  
  
  observe: function() {
    return {
      // jobs:(new Parse.Query('UserJob')).equalTo('user',this.props.user)
    };
  },
  noop:function(){},

  handleChange: function(event) {

    var self=this;

    var saveButton  = React.findDOMNode(this.refs.saveButton);
    // var cpuCount  = React.findDOMNode(this.refs.cpuCount);
    var modelName = React.findDOMNode(this.refs.modelName);
    var pstSelect  = React.findDOMNode(this.refs.pstSelect);
    // var hpSelect  = React.findDOMNode(this.refs.hpSelect);

    // cpuCount.value = cpuCount.value.replace(/^[0-9]*/gi,'')

    var modelNameVal = modelName.value.toLowerCase().replace(/[^0-9a-z\_]/gi,'_')
    modelNameVal = modelNameVal.replace(/__/gi,'_').replace(/__/gi,'_').replace(/__/gi,'_').replace(/__/gi,'_').replace(/__/gi,'_').replace(/__/gi,'_')
    modelNameVal = modelNameVal.slice(0,15)

    console.log(modelNameVal)
    // modelNameVal = modelNameVal.replace(/[^0-9a-z]/gi,'_')
    // var cpuCountVal = +cpuCount.value.replace(/[^0-9]/gi,'')
    // if(cpuCountVal==0){
    //   cpuCountVal=''
    // }
    var newState ={
      modelName: modelNameVal,
      // cpuCount: cpuCountVal
    }
    var postOp = self.noop;

    if(pstSelect && pstSelect.value) {
      if(newState.pstFile!=pstSelect.value){
        postOp = self.runPCost;
      }
      newState.pstFile=pstSelect.value
    }
    var hpmExists = false;
    if(newState.pstFile && this.state.allHpFiles.length){
      hpmExists = this.state.allHpFiles.map(function(xi){return xi.toLowerCase().trim()}).indexOf(newState.pstFile.toLowerCase().trim().replace('.pst','.hp'))>=0?true:false
    }
    newState.isHPFile=hpmExists
    newState.useHPFile=hpmExists&&self.state.useHPFile
    newState.useMKL=self.state.useMKL
    newState.pestcheckRun = self.state.pestcheckRun
    newState.useWhisper = self.state.useWhisper

    // if(hpSelect && hpSelect.value) {
    //   newState.hpFile=hpSelect.value
    // }
    self.setState(newState,postOp)
  },
  cachePstFile:function(zip,name){
    var self = this;
    zip.files[name].async('string').then(function(content) {
      self.pstFileContents[name]=content
    });
  },
  runPCostInitial:function(index){
    var index = index || 1;
    var self = this;
    if(self.state.allPstFiles.length && index<10){
      if(!self.pstFileContents[self.state.allPstFiles[0]]){
        setTimeout(function(){self.runPCostInitial(index+1)},500)
      }
      else{
        self.runPCost(self.state.allPstFiles[0])
      }
    }
  },
  runPCost:function(filename){
    var self = this;
    var filename = filename || self.state.pstFile;
//     var test_result = `
//  PCOST_HP version 14.02. Watermark Numerical Computing.
 
//  Estimated cost without hp starter file = $1171.17
//  Estimated cost with hp starter file    = $1166.88

//  Some statistics.
//    Number of adjustable parameters                                   : 79
//    Jacobian runs per iteration before high order switch              : 316
//    Jacobian runs per iteration after  high order switch              : na
//    Parameter upgrade testing runs per iteration                      : 10
//    Number of upgrade testing cycles per iteration                    : 1
//    Number of iterations used in cost estimation                      : 8

//  Possible idle agents per iteration (in units of model runs).
//    Initial model run (if not using hp starter file)                  : 9
//    Initial model run (if using hp starter file)                      : 0
//    Filling Jacobian matrix before switch to higher order derivatives : 4
//    Filling Jacobian matrix after  switch to higher order derivatives : na
//    Parameter upgrade testing                                         : 0
// `

    var filedata = self.pstFileContents[filename]
    if(filedata){

      self.setState({
        analyzingPCost:true,
        pcost_error:false
      })
      var formData = new FormData();
      formData.append('filename', filename);
      formData.append('filedata', filedata);


      $.ajax({
        //url: '/upload',
        url: '/pcost',
        type : 'POST',
        data : formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(pcr) {
          var pcrError = pcr.error;
          if(!pcrError){
            console.log({pcost_success:true})
          }
          else{
            console.log({pcost_success:false,err:pcr.error})
          }

          if(!pcrError){
            if(pcr.data.indexOf('Error condition prevents')>0){
              pcrError = true
            }
            else{
              //var resLines = test_result.split('\n')
              //var resLines = (pcr.data||'').split().split('\n')
              var resLines = (pcr.data||'').split('Some specifications:-')[1].split('\n')

              var kvp = resLines.map(function(rl){
                if(rl.indexOf(':')){
                  var kv = rl.split(':')
                  if(kv.length>1){
                    var kvres = {}
                    var kk = kv[0].trim();
                    var vv = kv[1].trim();
                    if(kk && kk.length && vv && vv.length){
                      kvres.name=kk;
                      kvres.value=vv;
                      return kvres                   
                    }
                  }
                }
              })
              var values = kvp.filter(function(t){return t})
              var adjParam =1;
              var maxParam=1;
              var maxIter=1;

              values.map(function(vv,vx){
                if(vx==1 && !isNaN(+vv.value)){
                  adjParam = +vv.value
                }
                if(vx<6 && !isNaN(+vv.value)){
                  maxParam = Math.max(maxParam,+vv.value)
                }
                if(vx==6 && !isNaN(+vv.value)){
                  maxIter = Math.max(maxIter,+vv.value)
                }
              })
            }
          }
          // console.log({pcost_result:data})

          self.setState({
            analyzingPCost:false,
            pcost_error:pcrError,
            pcost_result:{
              adj_parameters:adjParam,
              use_parameters:maxParam,
              use_iterations:maxIter,
              factors:values
            }
          })
          // console.log({
          //   pcost_result:{
          //     use_parameters:maxParam,
          //     use_iterations:maxIter,
          //     factors:values
          //   }
          // })
        },
        error: function(err){
          // console.log({pcost_result:test_result})
          console.log({pcost_error:err})

        }
      });
    }
    else{
      console.log("can't find data for file: " + filename)
    }
  },

  handleFileUpload: function(e) {
    var self=this;

    var file = e.target.files[0]


    self.pstFileContents={}
    var zip = new JSZip();
    zip.loadAsync(file)
     .then(
        function(zip) {
          var allPst = []
          var allHp = []
          var anyForbidden = []
          // model.zip, model_ow.zippest_hp.exe, pest_hp.lic,  microsoft*.dll newtonsoft*.dll system.spatial.dll *microsoft*.rtf pest_result_full\\*_taskmaster* >nul)"
          var allForbidden = [
            'pest_hp.zip',
            'pest_hp.exe',
            'pwhisp_hp.exe',
            'write_license.exe',
            'genie.exe',
            'azuretools.zip',
            'azcopy.exe',
            'license (microsoft azure storage tools).rtf',
            'microsoft.windowsazure.storage.datamovement.dll',
            'microsoft.windowsazure.storage.dll',
            'microsoft.windowsazure.storage.tabledatamovement.dll',
            'third party notice (microsoft azure storage tools).rtf',
            'microsoft.data.edm.dll',
            'microsoft.data.odata.dll',
            'microsoft.data.services.client.dll',
            'newtonsoft.json.dll',
            'system.spatial.dll'
          ]


          for(ff in zip.files){
            var fflcs = ff.toLowerCase()
            if(fflcs.indexOf('_macos')>=0){continue}
            if(allForbidden.indexOf(fflcs)>=0){
              anyForbidden.push(ff)
            }
            if(fflcs.slice(-4)=='.pst'){
              allPst.push(ff)
              self.cachePstFile(zip,ff)
            }
            if(fflcs.slice(-3)=='.hp'){
              allHp.push(ff)
            }
          }

         // console.log({zip:zip})

          var usePstFile = allPst.length?allPst[0]:null
          var hpmExists = false;

          if(usePstFile && allHp.length){
            hpmExists = allHp.map(function(xi){return xi.toLowerCase().trim()}).indexOf(usePstFile.toLowerCase().trim().replace('.pst','.hp'))>=0?true:false
          }
          // if(allPst.length){
          //   self.runPCost(allPst[0])

          // }

          self.setState({
            test:true,
            uploadFile: file,
            anyForbiddenFiles:anyForbidden,
            allPstFiles:allPst,
            allHpFiles:allHp,
            pstFile:usePstFile,
            useHPFile:hpmExists,
            isHPFile:hpmExists,
            pestcheckRun:false,
            useWhisper:false
          },self.runPCostInitial);
        }, 
        function(err) {
          document.getElementById("pest_uploader").value = ""
          self.setState({
            analyzingPCost:false,
            zip_error:err,
            // zip_id:new Date().getTime()
          })
        }
     ); 

  },

  uploadProgress: function(event) {
    var progressPct=0;
    if(event && event.loaded && event.total){
      progressPct = Math.floor(event.loaded*100/event.total)
      $('.uploadLabel').text('Uploading...: ' + progressPct + '%');

      console.log({uploadProgress:progressPct})
    }

  },
  uploadComplete: function(event) {
    var newModel = this.state.newModel;
    if(newModel.toPlainObject){
      newModel = newModel.toPlainObject()
    }
    console.log({uploadComplete:event,m:newModel})
    this.props.modelDetails(newModel)
    this.props.closeLightbox()
  },
  uploadFailed: function(event) {
    console.log({uploadFailed:event})
    this.props.closeLightbox()
  },
  uploadCanceled: function(event) {
    console.log({uploadCanceled:event})
    this.props.closeLightbox()
  },
  saveSettings: function(event) {

    var self = this;

    var formData = new FormData();

    var dttm = new Date().getTime()

    var modelName = this.state.modelName.replace(/[^0-9a-z]/gi,'')+'_'+dttm
    var billingCode = React.findDOMNode(this.refs.billingCode)
    var pstFileName = this.state.modelName.replace(/[^0-9a-z]/gi,'')+'_pst_'+dttm

    // self.setState({isCreatingJob:true})

    self.setState({
      isCreatingJob:true,
      isUploading:true
    })

    formData.append('name', modelName);
    // formData.append('pst', pstFileName);
    formData.append('user', this.props.user);
    //formData.append('file', this.state.uploadFile);
    $.ajax({
      //url: '/upload',
      url: '/fprep',
      type : 'POST',
      data : formData,
      processData: false,  // tell jQuery not to process the data
      contentType: false,  // tell jQuery not to set contentType
      success : function(data) {

        if(data.error){
          alert(data.message)
        }
        else{

          var startDate = new Date();
          var expiryDate = new Date(startDate);
          expiryDate.setMinutes(startDate.getMinutes() + 60*24*30); //1 week

          console.log(data);
          var acl = new Parse.ACL(Parse.User.current());
          acl.setPublicReadAccess(false);
          acl.setPublicWriteAccess(false);

          var uj = {
            user: Parse.User.current().id,
            modelName: self.state.modelName,
            // packageUrl: data.packageUrl,
            expires:expiryDate,
            cpuCount:self.state.cpuCount,
            pstFile:self.state.pstFile,
            useHPFile:self.state.useHPFile,
            useMKL:self.state.useMKL,
            whisper:self.state.useWhisper,
            pestcheck:self.state.pestcheckRun,
            status:'not run',
            additionalFunds:0,
            ACL: acl
          }
          console.log({uj})
          if(self.state.pcost_result && self.state.pcost_result.adj_parameters){
            uj.parameterCount = self.state.pcost_result.adj_parameters
          }
          if(self.state.pcost_result && self.state.pcost_result.use_parameters){
            uj.runCount = self.state.pcost_result.use_parameters
          }
          if(self.state.pcost_result && self.state.pcost_result.use_iterations){
            uj.iterationCount = self.state.pcost_result.use_iterations
          }

          if(billingCode){
            uj.billingCode = billingCode.value

          }

          if(self.state.cloneId){
            uj.cloneId = self.state.cloneId
            uj.packageUrl = self.props.packageUrl
            uj.clonePackageUrl = data.packageUrl
          }
          else{
            uj.packageUrl = data.packageUrl
          }
          console.log({uj})
          // if(data.pstFileUrl){
          //   uj.pstFileUrl = data.pstFileUrl
          // }


          var pstFormData = new FormData();
          pstFormData.append('user', self.props.user);
          pstFormData.append('name', pstFileName);
          pstFormData.append('pst', self.pstFileContents[self.state.pstFile]);
          $.ajax({
            //url: '/upload',
            url: '/upst',
            type : 'POST',
            data : pstFormData,
            processData: false,  // tell jQuery not to process the data
            contentType: false,  // tell jQuery not to set contentType
            success : function(upst) {
              if(!upst.error){
                console.log({pst_success:true})
              }
              else{
                console.log({pst_success:false,error:upst.error})
              }
              if(upst.pstFileUrl){
                uj.pstFileUrl = upst.pstFileUrl
              }

              ParseReact.Mutation.Create('UserJob', uj).dispatch().then(function(newObj) {

                if(self.state.uploadFile){
                  self.fileSize = self.state.uploadFile.size
                  self.fileType = self.state.uploadFile.type
                 
                  console.log('Upload Size: ' + self.fileSize)
                  if(self.fileSize <= 64*1024*1024){
                    console.log('Normal')
                    //if its less than 64 MB do it the normal way

                    var xhr = new XMLHttpRequest();

                    // xhr.withCredentials = true;
                    xhr.upload.addEventListener("progress", self.uploadProgress, false);
                    xhr.addEventListener("load", self.uploadComplete, false);
                    xhr.addEventListener("error", self.uploadFailed, false);
                    xhr.addEventListener("abort", self.uploadCanceled, false);
                    xhr.open("PUT", data.packageUrl);
                    var dt = new Date()
                    xhr.setRequestHeader("x-ms-date", dt.toGMTString());
                    xhr.setRequestHeader("x-ms-version", "2015-04-05");
                    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
                    xhr.send(self.state.uploadFile)
                    
                  } 
                  else {
                    //use the chunked uploader
                    console.log('Chunked')

                    self.bytesUploaded = 0;
                    self.blockIds = new Array();
                    self.blockIdPrefix = "block-";
                    self.maxBlockSize = 512 * 1024;
                    self.currentFilePointer = 0;
                    self.totalBytesRemaining = 0;
                    if(self.fileSize < self.maxBlockSize) {
                        self.maxBlockSize = self.fileSize;
                    }
                    self.totalBytesRemaining = self.fileSize;
                    if (self.fileSize % self.maxBlockSize == 0) {
                        self.numberOfBlocks = self.fileSize / self.maxBlockSize;
                    } else {
                        self.numberOfBlocks = parseInt(self.fileSize / self.maxBlockSize, 10) + 1;
                    }
                    var baseUrl = data.containerUrl;
                    var indexOfQueryStart = baseUrl.indexOf("?");
                    self.submitUri = baseUrl.substring(0, indexOfQueryStart) + '/' + modelName + baseUrl.substring(indexOfQueryStart);
                    console.log(self.submitUri);


                    self.reader = new FileReader();

                    self.reader.onloadend = function (evt) {
                        if (evt.target.readyState == FileReader.DONE) { // DONE == 2
                            var uri = self.submitUri + '&comp=block&blockid=' + self.blockIds[self.blockIds.length - 1];
                            var requestData = new Uint8Array(evt.target.result);
                            $.ajax({
                                url: uri,
                                type: "PUT",
                                data: requestData,
                                processData: false,
                                beforeSend: function(xhr) {
                                    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
                                    //xhr.setRequestHeader('Content-Length', requestData.length);
                                },
                                success: function (data, status) {
                                    console.log(data);
                                    console.log(status);
                                    self.bytesUploaded += requestData.length;

                                    var percentComplete = Math.floor(parseFloat(self.bytesUploaded)*100 / parseFloat(self.fileSize));
                                    // progressPct = Math.floor(event.loaded*100/event.total)
                                    $('.uploadLabel').text('Uploading...: ' + percentComplete + '%');
                                    self.uploadFileInBlocks();
                                },
                                error: function(xhr, desc, err) {
                                    console.log(desc);
                                    console.log(err);
                                }
                            });
                        }
                    };

                    self.uploadFileInBlocks()

                  }


                }


                self.setState({
                  newModel:newObj
                });
              });

            },
            error: function(upstErr){
                console.log({pst_success:false,error:upstErr})

            }
          })


              
          // var pstFileData = self.pstFileContents[self.state.pstFile]
          // var pstFileObj = new Parse.File(self.state.pstFile+'.txt', {base64:btoa(pstFileData)});

          // pstFileObj.save().then(function(){
          //   uj.pstFileData = pstFileObj;
          // });
            //packageUrl


        }
      }
    });

  },
  uploadFileInBlocks:function() {
    var self=this;

    if (self.totalBytesRemaining > 0) {
        console.log("current file pointer = " + self.currentFilePointer + " bytes read = " + self.maxBlockSize);
        var fileContent = self.state.uploadFile.slice(self.currentFilePointer, self.currentFilePointer + self.maxBlockSize);
        var blockId = self.blockIdPrefix + self.pad(self.blockIds.length, 6);
        console.log("block id = " + blockId);
        self.blockIds.push(btoa(blockId));
        self.reader.readAsArrayBuffer(fileContent);
        self.currentFilePointer += self.maxBlockSize;
        self.totalBytesRemaining -= self.maxBlockSize;
        if (self.totalBytesRemaining < self.maxBlockSize) {
            self.maxBlockSize = self.totalBytesRemaining;
        }
    } else {
        self.commitBlockList();
    }
  },
  commitBlockList:function() {
    var self=this;
    var uri = self.submitUri + '&comp=blocklist';
    console.log(uri);
    var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    for (var i = 0; i < self.blockIds.length; i++) {
        requestBody += '<Latest>' + self.blockIds[i] + '</Latest>';
    }
    requestBody += '</BlockList>';
    console.log(requestBody);
    $.ajax({
        url: uri,
        type: "PUT",
        data: requestBody,
        beforeSend: function (xhr) {
            xhr.setRequestHeader('x-ms-blob-content-type', self.fileType);
            xhr.setRequestHeader('Content-Length', requestBody.length);
        },
        success: function (data, status) {
            console.log(data);
            console.log(status);

            var newModel = self.state.newModel;
            if(newModel.toPlainObject){
              newModel = newModel.toPlainObject()
            }
            console.log({uploadComplete:event,m:newModel})
            self.props.modelDetails(newModel)
            self.props.closeLightbox()

        },
        error: function (xhr, desc, err) {
            console.log(desc);
            console.log(err);
        }
    });

  },
  pad:function(number, length) {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
  },


  handleHPSelection:function(e){    
    this.setState({
      useHPFile:e.currentTarget.checked
    });
  },
  handleMKLSelection:function(e){    
    this.setState({
      useMKL:e.currentTarget.checked
    });
  },
  handlePESTCHECKSelection:function(e){    
    this.setState({
      pestcheckRun:e.currentTarget.checked,
      useWhisper:false
    });
  },
  handleWhisperSelection:function(e){    
    this.setState({
      useWhisper:e.currentTarget.checked
    });
  },
  render: function() {

    console.log({render:this.state})
    var self = this;

    var content = null;
    var pstDiv = null;
    var hpDiv = null;
    var mklDiv = null;
    var pcDiv = null;
    var whispDiv = null;
    var createButton = null;
    var cancelButton = null;

    if(self.state.isCreatingJob){
      console.log('creatingJob')
      content = (
        React.createElement("div", null, 
          React.createElement("h3", {className: "uploadLabel"}, "Creating..."), 
          React.createElement(Spinner, {spinnerName: "three-bounce"})
        )
      )
    }
    else{
      console.log('configuringJob')
      cancelButton = React.createElement("a", {className: "button buttonJob buttonLeft", onClick: this.props.closeLightbox}, React.createElement("span", null, "Cancel"))

      var curPst = null;
      if(this.state.anyForbiddenFiles.length){
        pstDiv = (                    
          React.createElement("div", null, 
            React.createElement("span", {className: "labelField"}, 
              "Zip File Error:undefinedllowing files are forbidden in your model zip file:"
            ), React.createElement("br", null), 
            React.createElement("ul", null, 
              $.map(this.state.anyForbiddenFiles,function(fv,fk){
                return (React.createElement("li", null, fv))
              })
            )
          )
        )
      }
      else if(this.state.allPstFiles.length){
        //only create if there is a pst file and model name
        if(self.state.modelName && self.state.modelName.length && this.state.pestcheckRun && ! this.state.pcost_error){
          createButton = React.createElement("a", {className: "button buttonJob buttonRight", style: {backgroundColor:'#36952e'}, onClick: this.saveSettings}, React.createElement("span", null, "Upload"))
        }
      
        curPst = self.state.pstFile || this.state.allPstFiles[0]
        pstDiv = (                    
          React.createElement("div", null, 
            React.createElement("span", {className: "labelField"}, 
              React.createElement("a", {"data-tooltip": "This is the PEST control file you would like to use in the cloud."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
              "PEST control file:"
            ), React.createElement("br", null), 
            React.createElement("select", {
              style: {border:'none'}, 
              value: curPst, 
              ref: "pstSelect", 
              onChange: self.handleChange}, 
              $.map(this.state.allPstFiles,function(pv,pk){
                return (React.createElement("option", {value: pv}, pv))
              })
              
            ), React.createElement("br", null), React.createElement("br", null)
          )
        )
      }
      if(self.state.modelName && self.state.modelName.length && curPst && this.state.isHPFile && !this.state.analyzingPCost && !this.state.pcost_error){
        // var hpmExists = this.state.allHpFiles.map(function(xi){return xi.toLowerCase().trim()}).indexOf(curPst.toLowerCase().trim().replace('.pst','.hp'))>=0?true:false

        // <br/>
        //     <select 
        //       value={self.state.hpFile || 'none'}
        //       ref='hpSelect'
        //       onChange={self.handleChange}>
        //       <option value="none">none</option>
        //       {$.map(this.state.allHpFiles,function(hv,hk){
        //         return (<option value={hv}>{hv}</option>)
        //       })}
        //     </select>
        // {this.state.isHPFile?'Yes':'No'}
        hpDiv = ( 
          React.createElement("div", null, 
            React.createElement("span", {className: "labelField"}, 
              React.createElement("input", {style: {marginRight:'8px',transform:'scale(1.5)'}, type: "checkbox", checked: this.state.useHPFile, onChange: this.handleHPSelection}), 
              React.createElement("a", {"data-tooltip": "Check this box if you have created a hp starter file on your own machine."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
              "Use hp starter file"
            )
          )
        )
      }
      if(self.state.modelName && self.state.modelName.length && curPst && !this.state.analyzingPCost && !this.state.pcost_error){
        pcDiv = (          
          React.createElement("div", null, 
            React.createElement("span", {className: "labelField"}, 
              React.createElement("input", {style: {marginRight:'8px',transform:'scale(1.5)'}, type: "checkbox", checked: this.state.pestcheckRun, onChange: this.handlePESTCHECKSelection}), 
              React.createElement("a", {"data-tooltip": "Check this box if you have run PESTCHEK to check the integrity of the PEST input dataset. This is mandatory."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
              "PESTCHEK was run"
            )
          )
        )
      }
      if(self.state.modelName && self.state.modelName.length && curPst && this.state.pestcheckRun && !this.state.analyzingPCost && !this.state.pcost_error){
        whispDiv = ( 
          React.createElement("div", null, 
            React.createElement("span", {className: "labelField"}, 
              React.createElement("input", {style: {marginRight:'8px',transform:'scale(1.5)'}, type: "checkbox", checked: this.state.useWhisper, onChange: this.handleWhisperSelection}), 
              React.createElement("a", {"data-tooltip": "The PEST whisperer takes a moment to run. It produces a text output file containing tips and insights on a just-completed PEST_HP run."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
              "Run whisperer on PEST completion"
            )
          )
        )
        mklDiv = ( 
          React.createElement("div", null, 
            React.createElement("span", {className: "labelField"}, 
              React.createElement("input", {style: {marginRight:'8px',transform:'scale(1.5)'}, type: "checkbox", checked: this.state.useMKL, onChange: this.handleMKLSelection}), 
              React.createElement("a", {"data-tooltip": "Use the MKL version of PEST_HP."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
              "Use pest_hp_mkl.exe"
            )
          )
        )
      }

      var fileLabel =(
        React.createElement("span", {className: "labelField"}, 
          React.createElement("a", {"data-tooltip": "Zip up all PEST and model files (including model executables) and use this form to upload it into the cloud"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
          "File:"
        )
      )
      var cloneInfo = null;
      if(this.state.cloneId){
        cloneInfo =   [

            React.createElement("span", {className: "labelField"}, 
              React.createElement("a", {"data-tooltip": "This is a copy of another model job."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
              "Clones:"
            ),
            React.createElement("br", null),
            React.createElement("span", null, this.state.cloneId)
        ];

        fileLabel =(
        React.createElement("span", {className: "labelField"}, 
          React.createElement("a", {"data-tooltip": "Zip up files that will overwrite those in the original package.  Include new .pst file as well."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
          "Overwrite file:"
        )
      )}
      var costFactors=null;

      if(this.state.uploadFile && this.state.analyzingPCost){
        costFactors = (
            React.createElement("div", null, 
              React.createElement("p", null, 
                React.createElement("span", {className: "labelField"}, "Analyzing...")
              )
            )
        )

      }

      if(this.state.zip_error){
        costFactors = (
            React.createElement("div", null, 
              React.createElement("p", null, 
                React.createElement("span", {className: "error"}, "Error: ", this.state.zip_error.message), React.createElement("br", null), 
                React.createElement("span", {className: "error"}, "Please re-zip the files and try again.")
              )
            )
        )
      }
      else if(this.state.pcost_error){
        costFactors = (
            React.createElement("div", null, 
              React.createElement("p", null, 
                React.createElement("span", {className: "error"}, "Error reading PEST control file.  Please run PESTCHEK for more details.")
              )
            )
        )
      }
      else if(this.state.pcost_result && this.state.pcost_result.factors){
        var costFactorItems = [React.createElement("span", {className: "labelField"}, "Specifications:")];
        this.state.pcost_result.factors.map(function(fv,fk){
          if(fk==0 || fk==1  || fk==2){  //|| fk==7 || fk==8
            // 1: PEST mode of operation
            // 2: Number of adjustable parameters
            // 3: Jacobian runs per itn before swtich to higher order derivs
                // Max number of parameters with derivatives switch
            // 4: Jacobian runs per itn after  switch to higher order derivs
            // 5: Parameter upgrade testing runs per iteration
            // 6: Number of upgrade testing cycles per iteration
            // 7: Number of iterations used in cost estimation
            // 8: Number of iterations specified in PEST control file
            costFactorItems.push(React.createElement("br", null))
            if(fk==2){
              costFactorItems.push(React.createElement("span", {style: {'fontSize':'.8em'}}, "Maximum sensitivity runs per iteration:", React.createElement("b", null, fv.value))) 
            }
            else{
              costFactorItems.push(React.createElement("span", {style: {'fontSize':'.8em'}}, fv.name, ":", React.createElement("b", null, fv.value))) 

            }
          }
        })
        //<div style={{'width':'100%','height':'130px','overflowY':'scroll','border':'inset 1px'}}>
        costFactors = (
            React.createElement("div", null, 
              React.createElement("p", null, 
              costFactorItems
              )
            )
        )
      }
      content =( 
        React.createElement("div", null, 
            React.createElement("div", {className: "modalColumn"}, 
              React.createElement("p", null, 
                React.createElement("span", {className: "labelField"}, 
                  React.createElement("a", {"data-tooltip": "Give your PEST case a unique name"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
                  "PEST case name:"
                ), React.createElement("br", null), 
                React.createElement("input", {
                  className: "rewardField", 
                  type: "text", 
                  ref: "modelName", 
                  placeholder: "Enter unique name", 
                  onChange: this.handleChange, 
                  value: self.state.modelName, 
                  defaultValue: ''}), React.createElement("br", null), React.createElement("br", null), 


                React.createElement("span", {className: "labelField"}, 
                  React.createElement("a", {"data-tooltip": "Provide a billing code for your record keeping (optional)"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
                  "Billing code:"
                ), React.createElement("br", null), 
                React.createElement("input", {
                  className: "rewardField", 
                  type: "text", 
                  ref: "billingCode", 
                  placeholder: "Enter billing code", 
                  defaultValue: ''}), React.createElement("br", null), React.createElement("br", null), 

                fileLabel, 
                React.createElement("br", null), 
                React.createElement("input", {type: "file", name: "file", id: "pest_uploader", ref: "fileUploader", onChange: this.handleFileUpload}), React.createElement("br", null), React.createElement("br", null), 


                cloneInfo
              )
            ), 
            React.createElement("div", {className: "modalColumn"}, 
              React.createElement("p", null, 
                pstDiv, 
                hpDiv, 
                pcDiv, 
                mklDiv, 
                whispDiv
              )
            ), 
            React.createElement("br", null), 

            costFactors, 
            React.createElement("div", {className: "bottomButtons"}, 
              createButton, 
              cancelButton
            )
          
        )
      )

    }
    return content;
  }

});

module.exports = ModelDetails;