var React = require('react');

var Lightbox= require('../Lightbox/Lightbox.react.js');
var LightboxModal= require('../Lightbox/LightboxModal.react.js');
var LightboxTrigger= require('../Lightbox/LightboxTrigger.react.js');


var ProductModal = require('./ProductModal.js');

var PestHp = React.createClass({displayName: "PestHp",

  getInitialState: function() {
    return {
    };
  },

  componentDidMount: function() {
  },

  render: function() {

    return (      
      React.createElement("div", null, 
        React.createElement("h1", {className: "hpHeader"}, "PEST_HP"), 

        React.createElement("p", null, 
        "PEST_HP is a version of BEOPEST that is optimised for use in highly parallelized environments. It also has greater tolerance for model defects which compromise finite-difference derivatives calculation.  Read the ", React.createElement("a", {href: "/pest_hp_manual.pdf", target: "_blank"}, "PEST_HP manual"), " to see the many other things it does. When you use PEST.cloud you are automatically using PEST_HP.", 
        React.createElement("br", null), React.createElement("br", null), 
        "PEST_HP is available for purchase as its own item. As the owner of PEST_HP, you can use it on your office network or on a high performance cluster to which you have access. Or you can use it to implement cloud-based calibration/uncertainty analysis on the cloud provider of your choice. At the same time, as a purchaser of PEST_HP, you are automatically credited with $500 free use of PEST.cloud.", 
        React.createElement("br", null), React.createElement("br", null), 
        "PEST_HP is available as a windows 64-bit executable. Contact us if you would like a unix version. In this case you will receive the source code (FORTRAN and C++). You can then compile it for your own platform"
        ), 

        React.createElement("a", {name: "pestwhisperer"}), 
        React.createElement("h2", {style: {paddingLeft:'20px'}}, "PEST Whisperer"), 
        React.createElement("p", null, 
          "With PEST_HP you also receive the “PEST whisperer” – PWHISP_HP. This reads a suite of files that PEST_HP writes in which it records the progress and results of the inversion process. After reading these files, it provides as much advice as it can on whether PEST_HP’s performance was good and, if not, what PEST_HP settings may result in better performance next time."
        ), 

        React.createElement("p", null, 
          "To get your copy of PEST_HP, please contact us at ", React.createElement("a", {href: "mailto:info@pest.cloud?subject=PEST_HP Inquiry"}, "info@pest.cloud")
        ), 
        React.createElement("ul", {className: "hpLevels", style: {display:'none'}}, 
          React.createElement("li", {style: {backgroundColor:'#cf9537'}}, 
            
            React.createElement("div", {className: "hpBuyButton"}, 
                  React.createElement(Lightbox, null, 
                    React.createElement(LightboxTrigger, null, 
                        React.createElement("a", {className: "purchaseLink", href: "#"}, 
                          React.createElement("div", {className: "hpItemDesc"}, "1 User"), 
                          "$950 USD"
                        )
                    ), 
                    React.createElement(LightboxModal, null, 
                      React.createElement(ProductModal, {
                        color: "#cf9537", 
                        sku: "PESTHP-1", 
                        gotoAccount: this.props.gotoAccount, 
                        login: this.props.login, 
                        signup: this.props.signup})
                    )
                  )
            )
          ), 
          React.createElement("li", {style: {backgroundColor:'#b6b6b6'}}, 
            React.createElement("div", {className: "hpBuyButton"}, 
                  React.createElement(Lightbox, null, 
                    React.createElement(LightboxTrigger, null, 
                        React.createElement("a", {className: "purchaseLink", href: "#"}, 
                          React.createElement("div", {className: "hpItemDesc"}, "3 Users"), 
                          "$2420 USD"
                        )
                    ), 
                    React.createElement(LightboxModal, null, 
                      React.createElement(ProductModal, {
                        color: "#b6b6b6", 
                        sku: "PESTHP-3", 
                        gotoAccount: this.props.gotoAccount, 
                        login: this.props.login, 
                        signup: this.props.signup})
                    )
                  )
            )
          ), 
          React.createElement("li", {style: {backgroundColor:'#e6c515'}}, 
            React.createElement("div", {className: "hpBuyButton"}, 
                  React.createElement(Lightbox, null, 
                    React.createElement(LightboxTrigger, null, 
                        React.createElement("a", {className: "purchaseLink", href: "#"}, 
                          React.createElement("div", {className: "hpItemDesc"}, "5 Users"), 
                          "$3800 USD"
                        )
                    ), 
                    React.createElement(LightboxModal, null, 
                      React.createElement(ProductModal, {
                        color: "#e6c515", 
                        sku: "PESTHP-5", 
                        gotoAccount: this.props.gotoAccount, 
                        login: this.props.login, 
                        signup: this.props.signup})
                    )
                  )
            )
          ), 
          React.createElement("li", {style: {backgroundColor:'#36952e'}}, 
            
            React.createElement("div", {className: "hpBuyButton"}, 
                  React.createElement(Lightbox, null, 
                    React.createElement(LightboxTrigger, null, 
                        React.createElement("a", {className: "purchaseLink", href: "#"}, 
                          React.createElement("div", {className: "hpItemDesc"}, React.createElement("span", {className: "infinity"}, "∞"), " Users"), 
                          "$7500 USD"
                        )
                    ), 
                    React.createElement(LightboxModal, null, 
                      React.createElement(ProductModal, {
                        color: "#36952e", 
                        sku: "PESTHP-UNL", 
                        gotoAccount: this.props.gotoAccount, 
                        login: this.props.login, 
                        signup: this.props.signup})
                    )
                  )
            )
          )
        )

      )
    )
  }

});

module.exports = PestHp;