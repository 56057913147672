var Parse = require('parse').Parse;
var React = require('react');
var ParseReact = require('parse-react-2');


var AppWrapper = React.createClass({displayName: "AppWrapper",
  mixins: [ParseReact.Mixin],
  getInitialState: function() {
    return {
      currentTab: 0,
      currentUser: '',
      users:[],
      customerId:null
    };
  },

  // getUsers: function() {
  //   var self=this;
  //   var query = new Parse.Query('PartnerSettings');
    
  //   query.find({
  //       success: function(p){
  //         if(p){
  //           self.setState({ isAdmin:true, users: p,currentUser:p[0]._serverData.partner});
  //         }
  //       },
  //       error: function(){
  //         // self.setState({ isAdmin: false });
  //       }
  //   });
  // },

  // checkAdmin: function() {
  //   if(this.state.isAdmin!=undefined)
  //   {
  //     return;
  //   }
  //   var self=this;
  //   var queryRole = new Parse.Query(Parse.Role);
  //   queryRole.equalTo('name', 'Admin');

  //   queryRole.first({
  //       success: function(r){
  //           var role = r;
  //           var relation = new Parse.Relation(role, 'users');
  //           var admins = relation.query();

  //           admins.equalTo('username', self.props.username)
  //           admins.first({
  //               success: function(u){
  //                   var user = u;
  //                   if(user){
  //                     //self.getUsers();
  //                     self.setState({ isAdmin: true });
  //                   }else{
  //                     self.setState({ isAdmin: false });
  //                   }
  //               },
  //               error: function(){
  //                 self.setState({ isAdmin: false });
  //               }
  //           })
  //       },
  //       error: function(){
  //         self.setState({ isAdmin: false });
  //       }
  //   });

  // },

  // changeUser: function() {
  //   this.setState({ currentUser: React.findDOMNode(this.refs.selectedPartner).value });

  // },


  // componentDidMount: function() {
  //   var self=this;
  //   setTimeout(function() {
  //     self.checkAdmin();
  //   }, 0);
  // },

  componentDidMount: function() {
    var self = this;
    if(Parse.User.current()){
      var customerId = Parse.User.current().get('stripeCustomer')
      this.setState({
        user:Parse.User.current(),
        customerId:customerId
      },this.getCards)
    }
    else{
      console.log('Not Logged In')
    }
  },


  observe: function(){
      return {
        // partners: (new Parse.Query('PartnerSettings')).equalTo('active',true)
      }
  },

  gotoModelDetails:function(jobId){
    var newState={ 
      currentTab: -4, 
      job:jobId
    }
    var customerId = Parse.User.current().get('stripeCustomer')
    if(customerId && !this.state.customerId){
      newState.customerId=customerId
    }
    this.setState(newState);

  },
  gotoModels:function(jobId){
    this.setState({ 
      currentTab: 4, 
      job:null
    });

  },
  gotoHow:function(){
    this.selectTab(1);
  },
  gotoPEST:function(){
    this.selectTab(2);
  },
  gotoTerms:function(){
    this.selectTab(10);
  },
  gotoPrivacy:function(){
    this.selectTab(11);
  },
  gotoAccount: function(){
    var uo=React.findDOMNode(this.refs.k_userOptions)
    // if(uo){
      uo.className='userOptions'
    // }
    this.selectTab(-11);
  },
  logOut: function(){
    var uo=React.findDOMNode(this.refs.k_userOptions)
    // if(uo){
      uo.className='userOptions'
    // }
    this.props.logOut();
  },
  toggleUserOptions: function(){
    var uo=React.findDOMNode(this.refs.k_userOptions)
    if(uo){
      if(uo.className.indexOf('uo-open')>0){
        uo.className='userOptions'
      }
      else
      {
        uo.className='userOptions uo-open'

      }

    }
  },
  render: function() {
    // if(this.state.isAdmin == undefined || this.pendingQueries().length>0)
    if(this.pendingQueries().length>0)
    {
      return(React.createElement("div", {className: "loading"}))
    }

    // var userSelect = null;
    // var refillClub = null;
    // var partners = null;
    // var eProps = {user:Parse.User.current().id};
    // if(this.state.isAdmin)
    // {
    //   var currentUser = this.state.currentUser;

    //   // refillClub = (
    //   //     <li ref='tab_6'><a onClick={this.selectTab.bind(this, 6)}>Refill Club</a></li>
    //   //   )
    //   partners = (
    //       <li ref='tab_7'><a onClick={this.selectTab.bind(this, 7)}>Partners</a></li>
    //     )
    //   userSelect = (
    //     <li>
    //       <select ref='selectedPartner' onChange={this.changeUser}>
    //       {this.data.partners.map(function(c, i) {
    //         if(!currentUser){
    //           currentUser = c.partner;
    //         }
    //         return (<option key={c.partner} value={c.partner}>{c.displayName}</option>);
    //       })}
    //       </select>
    //     </li>
    //     )
    //   eProps.user = currentUser;
    //   eProps.isAdmin=true;
    //   eProps.checkActiveUser = this.checkActiveUser;

    // }

    var eProps = {
      user:Parse.User.current().id,
      gotoAccount:this.gotoAccount,
      models:this.gotoModels,
      gotoPest:this.gotoPEST,
      gotoHow:this.gotoHow,
      modelDetails:this.gotoModelDetails,
      job:this.state.job,
      customerId:this.state.customerId
    };

    socket.removeAllListeners(Parse.User.current().id);
    //console.log(eProps)
    var content = (React.createElement("div", null));
    switch(this.state.currentTab) {
      case 0:
        content = React.createElement(require('./Pages/Home.js'), eProps)
        break;
      case -11:
        content = React.createElement(require('./Account/Account.js'), eProps)
        break;
      case 1:
        content = React.createElement(require('./Pages/How.js'),eProps)
        break;
      case 2:
        content = React.createElement(require('./Pages/PestHP.js'),eProps)
        break;
      case -4:
        content = React.createElement(require('./Models/ModelDetails.js'), eProps)
        break;
      case 4:
        content = React.createElement(require('./Models/Models.js'), eProps)
        break;
      case 5:
        content = React.createElement(require('./Account/Account.js'), eProps)
        break;
      case 6:
        content = React.createElement(require('./Pages/Contact.js'), eProps)
        break;
      case 10:
        content = React.createElement(require('./Pages/TermsAndConditions.js'), eProps)
        break;
      case 11:
        content = React.createElement(require('./Pages/PrivacyPolicy.js'), eProps)
        break;
      // case 1:
      //   content = React.createElement(require('./Merchandise2.react.js'), eProps)
      //   break;
      // case 2:
      //   content = React.createElement(require('./Locations2.react.js'), eProps)
      //   break;
      // case 3:
      //   content = React.createElement(require('./Rewards.react.js'), eProps)
      //   break;
      // case 4:
      //   content = React.createElement(require('./Reuse.react.js'), eProps)
      //   break;
      // case 5:
      //   content = React.createElement(require('./Alerts.react.js'), eProps)
      //   break;
      // case 6:
      //   content = React.createElement(require('./RefillClub.react.js'), eProps)
      //   break;
      // case 7:
      //   content = React.createElement(require('./Partners.react.js'), eProps)
      //   break;
    }


              // <li ref='tab_1'><a onClick={this.selectTab.bind(this, 1)}>Tags</a></li>
              // <li ref='tab_2'><a onClick={this.selectTab.bind(this, 2)}>Locations</a></li>
              // <li ref='tab_3'><a onClick={this.selectTab.bind(this, 3)}>Rewards</a></li>
              // <li ref='tab_4'><a onClick={this.selectTab.bind(this, 4)}>Track Reuse</a></li>
              // <li ref='tab_5'><a onClick={this.selectTab.bind(this, 5)}>Alerts</a></li>              
              // {partners}
              // {refillClub}
              // {userSelect}

    return (
        React.createElement("div", null, 
            React.createElement("div", {ref: "k_userOptions", className: "userOptions"}, React.createElement("div", null, React.createElement("a", {onClick: this.toggleUserOptions, style: {color:'white'}}, "Welcome ", this.props.username, " ", '\u25BE')), 
            React.createElement("ul", null, 
              React.createElement("li", null, React.createElement("a", {onClick: this.gotoAccount}, "My Account")), 
              React.createElement("li", null, React.createElement("a", {onClick: this.logOut}, "Log Out"))
            )
            
            ), 
            React.createElement("div", {className: "navBar"}, 
            React.createElement("ul", null, 
              React.createElement("li", {ref: "tab_0", className: "selected"}, React.createElement("a", {onClick: this.selectTab.bind(this, 0)}, "Home")), 
              React.createElement("li", {ref: "tab_1", className: ""}, React.createElement("a", {onClick: this.selectTab.bind(this, 1)}, "PEST.cloud")), 
              React.createElement("li", {ref: "tab_2", className: ""}, React.createElement("a", {onClick: this.selectTab.bind(this, 2)}, "PEST_HP")), 
              React.createElement("li", {ref: "tab_4", className: ""}, React.createElement("a", {onClick: this.selectTab.bind(this, 4)}, "My PEST Cases")), 
              React.createElement("li", {ref: "tab_5", className: ""}, React.createElement("a", {onClick: this.selectTab.bind(this, 5)}, "My Account")), 
              React.createElement("li", {ref: "tab_6", className: ""}, React.createElement("a", {onClick: this.selectTab.bind(this, 6)}, "Contact Us"))
            )
            
            ), 
            React.createElement("div", {className: "mainPanel"}, 
              " ", 
              React.createElement("div", {style: {marginTop:'5px',marginBottom:'10px',marginLeft:'5%',width:'90%',border:'solid 1px #0069c0', borderRadius:'5px',background:'#e1ecf4'}}, 
                React.createElement("p", null, 
                
                  // <img className="infoImg" src="../images/info.png" /><b style={{color:'#880000!important'}}>We are currently performing maintenance, and should be back up running by Nov 4,2020</b><br/><br/>
                
                  React.createElement("img", {className: "infoImg", src: "../images/info.png"}), React.createElement("b", null, "COVID-19 Response:"), React.createElement("br", null), React.createElement("br", null), 
                  "We are aware of some service disruptions that have taken place recently due to increased demand on the Microsoft Azure platform.  At this time, we do not know" + ' ' +
                  "when service health will return to normal.  We realize this might result in degraded performance, but we will try to help keep your models" + ' ' +
                  "running as long as we have the ability to utilize Microsoft services.  ", React.createElement("u", null, "For the time being, we would like to have our users launch models in the morning, so that we can" + ' ' +
                  "be available to troubleshoot during normal business hours"), ".  Thank you."
                )
              ), 
              content
            ), 
            
            React.createElement("div", {className: "footer"}, 
              React.createElement("div", {className: "copyright"}, "©", new Date().getFullYear(), " ", 
                React.createElement("a", {href: "http://www.sspa.com", target: "_blank"}, 'S.S. Papadopulos & Associates'), ", ",  
                React.createElement("a", {href: "http://www.pesthomepage.org", target: "_blank"}, "Watermark Numerical Computing"), React.createElement("br", null), 
                React.createElement("a", {onClick: this.gotoTerms}, "Terms and Conditions"), " | ", 
                React.createElement("a", {onClick: this.gotoPrivacy}, "Privacy Policy")

              )
            )
        )
    );
  },

  // checkActiveUser: function(id) {
  //   if(this.state.currentUser == id && $.grep(this.data.partners,function(ex){return ex.partner==id}).length==0)
  //   {
  //     this.setState({ currentUser: null });
  //   }
  // },

  selectTab: function(tab) {

    var tabs = {
      '0':React.findDOMNode(this.refs.tab_0),
      '1':React.findDOMNode(this.refs.tab_1),
      '2':React.findDOMNode(this.refs.tab_2),
      '3':React.findDOMNode(this.refs.tab_3),
      '4':React.findDOMNode(this.refs.tab_4),
      '5':React.findDOMNode(this.refs.tab_5),
      '6':React.findDOMNode(this.refs.tab_6),
    }
    $.each(tabs,function(k,v){
      if(tabs[k])
      {
        tabs[k].className = (k==tab?'selected':'');
      }
    });
    this.setState({ currentTab: tab });
  }
});

module.exports = AppWrapper;