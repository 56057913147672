/** @jsx React.DOM */

// CSS from http://stackoverflow.com/questions/19064987/html-css-popup-div-on-text-click
// and http://stackoverflow.com/questions/10019797/pure-css-close-button

var React = require('react');

var LightboxModal = React.createClass({displayName: "LightboxModal",

    whiteContentStyles: {
        position: 'fixed',
        top: '12%',
        left: '15%',
        right: '15%',
        backgroundColor: '#fff',
        color: '#7F7F7F',
        padding: '34px 20px 20px 20px',
        border: '2px solid #ccc',
        borderRadius: '20px',
        boxShadow: '0 1px 5px #333',
        zIndex:'101',
        // overflowY:'scroll'
    },

    blackOverlayStyles: {
        background: 'black',
        opacity: '.5',
        position: 'fixed',
        top: '0px',
        bottom: '0px',
        left: '0px',
        right: '0px',
        zIndex: '100'
    },

    closeTagStyles: {
        float: 'right',
        marginTop: '-30px',
        marginRight: '-30px',
        cursor: 'pointer',
        color: '#fff',
        border: '1px solid #AEAEAE',
        borderRadius: '30px',
        background: '#605F61',
        fontSize: '31px',
        fontWeight: 'bold',
        display: 'inline-block',
        lineHeight: '0px',
        padding: '11px 3px',
        textDecoration: 'none'
    },

    componentDidMount: function(){
        document.addEventListener("keydown", function (e) {
            if ( (this.props.display) && (e.keyCode === 27) ){
                this.props.closeLightbox();
            }
        }.bind(this));
    },

    render: function(){
        for (var j in this.props){
            if (j !== 'children'){
                this.props.children.props[j] = this.props[j];
            }
        }
//<a style={this.closeTagStyles} onClick={this.props.closeLightbox}>&times;</a>
                        
        if (this.props.display){
            return (
                React.createElement("div", null, 
                    React.createElement("div", {style: this.blackOverlayStyles, onClick: this.props.closeLightbox}), 
                    React.createElement("div", {style: this.whiteContentStyles}, 
                        this.props.children
                    )
            )
            );
        } else {
            return (React.createElement("div", null));
        }
    }
});
module.exports = LightboxModal;
