var React = require('react');
var Parse = require('parse').Parse;
var ParseReact = require('parse-react-2');

var ProductModal = React.createClass({displayName: "ProductModal",
  mixins: [ParseReact.Mixin],

  getInitialState: function() {
    return {
      loading:true,
      user:null,
      customerId:null,
      customerDetails:null,
      productDetails:null,
      paymentMethod:null,
      isConfirming:false,
      userAgreeEULA:false,
      isPurchasing:false,
      purchaseProcessed:false,
      isErrorMessage:false,
      taxRate:0,
      taxAmount:0,
      taxDescription:null
    };
  },
  
  observe: function() {
    return {
      user: ParseReact.currentUser
    };
  },


  getCredits: function(userId,cb){
    var self = this;
    if(userId){
      $.ajax({
        url: '/acct/credit/'+userId,
        type : 'GET',
        // data : formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(data) {
          cb()
        }
      });//ajax
    }
    else{
      cb()
    }
  },
  getCustomer: function(){
    var self=this;

    $.ajax({
      url: '/acct/customer/'+self.state.customerId,
      type : 'GET',

      processData: false,  // tell jQuery not to process the data
      contentType: false,  // tell jQuery not to set contentType
      success : function(data) {

        if(data.err){
          self.setState({
            loading:false,
            customerDetails:null,
          })
        }
        else{

          var customer = data.customer;

          $.ajax({
            url: '/prod/'+self.props.sku,
            type : 'GET',

            processData: false,  // tell jQuery not to process the data
            contentType: false,  // tell jQuery not to set contentType
            success : function(prodData) {

              var newState = {
                loading:false,
                customerDetails:customer
              }
              if(customer && customer.sources && customer.sources.data && customer.sources.data.length){
                newState.paymentMethod = customer.sources.data[0].id
              }


              if(data.err){
                self.setState(newState)
              }
              else{
                if(prodData && prodData.sku){
                  newState.productDetails = prodData.sku 
                }
                self.setState(newState,function(){
                  self.calculateTax(prodData.sku.price)
                })
              }
            }
          });

        }
      }
    });
  },

  componentDidMount: function() {
    var self = this;
    var user = Parse.User.current()
    if(user){
      var customerId = user.get('stripeCustomer')
      self.getCredits(user.id,function(){
        self.setState({
          user:user,
          customerId:customerId
        },self.getCustomer)
        
      })
    }
    else{
      // console.log('Not Logged In')

      self.setState({
        loading:false
      })
    }
  },

  getVal: function(prop){
    var val = React.findDOMNode(prop)
    if(val!=undefined && val!=null){
      return val.value;
    }
    return '';
  },
  calculateTax:function(amount){
    var self=this;

    var cardId  = self.state.paymentMethod
    var card = self.state.customerDetails.sources.data.filter(function(card){
                return card.id == cardId
              })[0]

    if(card){
      var isoCountry = null;
      var zip = card.address_zip
      var state = card.address_state

      if(card.address_country){
        isoCountry = getIsoCode(card.address_country)
      } 

      if(isoCountry && isoCountry.code && zip && state){
        $.ajax({
          url: '/acct/tax/'+isoCountry.code +'/' + zip  +'/' + state  +'/' + amount  +'/PEST' ,
          type : 'GET',

          processData: false,  // tell jQuery not to process the data
          contentType: false,  // tell jQuery not to set contentType
          success : function(data) {
            if(data.tax && data.tax.amount_to_collect){
              var newState = {
                taxRate:0,
                taxAmount:data.tax.amount_to_collect,
                taxDescription:state + ' tax'
              }
              if(data.tax.breakdown && data.tax.breakdown.combined_tax_rate){
                newState.taxRate =  data.tax.breakdown.combined_tax_rate
              }

              self.setState(newState)
            }
            else {
              self.setState({
                taxRate:0,
                taxAmount:0,
                taxDescription:null
              })
            }
          }
        });
      }
      else {
        self.setState({
          taxRate:0,
          taxAmount:0,
          taxDescription:null
        })
      }
    }
    else {
      self.setState({
        taxRate:0,
        taxAmount:0,
        taxDescription:null
      })
    }

  },

  updatePaymentMethod: function(){    
    var self=this;

    var cardId  = self.getVal(this.refs.paymentMethod)

    self.setState({
      paymentMethod:cardId
    },function(){
      self.calculateTax(self.state.productDetails.price)
    })
  },
  confirmPurchase: function(){
    var self=this;

    var cardId  = self.getVal(this.refs.paymentMethod)

    self.setState({
      paymentMethod:cardId,
      isConfirming:true
    })
  },
  changePayment: function(){
    var self=this;
    self.setState({
      eulaScrolled:false,
      userAgreeEULA:false,
      paymentMethod:null,
      isConfirming:false
    })
  },
  purchase: function(){
    var self=this;


    self.setState({
      isPurchasing:true
    })

    var licType = 'null';
    var price=0
    var name=self.props.sku
    if(self.state.productDetails){
      if(self.state.productDetails.attributes){
        licType = self.state.productDetails.attributes.lictype
      }
      if(self.state.productDetails.prodRef){
       
        if(self.state.productDetails.prodRef.name){
          name = self.state.productDetails.prodRef.name
        }
      }
      price = self.state.productDetails.price
    }



    var formData = new FormData();

    var cardId  = self.state.paymentMethod
    var customerId = this.state.customerId
    var description= name + ' (' + licType + ')'
    var amountUSD = price/100;
    var taxAmount = self.state.taxAmount/100
    var taxRate = self.state.taxRate

    // console.log({
    //   customerId:customerId,
    //   cardId:cardId,
    //   description:description,
    //   amountUSD:amountUSD,
    // })

    // return;

    formData.append('userId', Parse.User.current().id);
    formData.append('sku', self.props.sku);
    formData.append('customerId', customerId);
    formData.append('cardId', cardId);
    formData.append('description', description);
    formData.append('amountUSD', amountUSD);
    formData.append('taxAmount', taxAmount);
    formData.append('taxRate', taxRate);
    
    $.ajax({
      url: '/acct/purchase',
      type : 'POST',
      data : formData,
      processData: false,  // tell jQuery not to process the data
      contentType: false,  // tell jQuery not to set contentType
      success : function(data) {
        if(data.err){
          self.setState({
            isErrorMessage:true,
            purchaseProcessed:true,
            isConfirming:false
          })
            // console.log(data)
            // alert(data)

        }
        else{
          console.log(data.charge);
          if(data.charge.status == 'succeeded'){

            self.setState({
              isErrorMessage:false,
              purchaseProcessed:true,
              isConfirming:false
            })
          }
          else{

            self.setState({
              isErrorMessage:true,
              purchaseProcessed:true,
              isConfirming:false
            })
            console.log(data)
            alert(data)

          }

        }
      }
    });





  },
  cancelPurchase: function(){
    var self=this;

    self.setState({
      paymentMethod:null,
      isConfirming:false
    })
  },

  getPayButton: function(){
    console.log({getPayButton:this.state})
      
  },
  render: function() {

    var self = this;

    var result = React.createElement("div", {className: "loading"});

    if (this.state.loading || this.pendingQueries().length) {
      return result;
    }


    if(!this.data.user){
      result = React.createElement("p", null, 
        "You must have an account to purchase this license.   If you already have an account," + ' ' + 
        "please ", React.createElement("a", {onClick: this.props.login}, "log in"), ".  If you do not already have an account," + ' ' + 
        "please ", React.createElement("a", {onClick: this.props.signup}, "sign up"), "."
      )
    }
    else if(!this.state.customerId || !self.state.customerDetails || (self.state.customerDetails && !self.state.customerDetails.sources.data.length)){
      console.log(this.props)
      result = React.createElement("p", null, 
        "You must have a credit card associated with this account in order to make a purchase." + ' ' +
        "Please go to your ", React.createElement("a", {onClick: this.props.gotoAccount}, "account"), " page to add a card."
      )
    }
    else{
      if(self.state.customerDetails){
        if(self.state.customerDetails.sources.data.length){

          var licType = null;
          var price=null
          var name=self.props.sku
          var description=null
          if(self.state.productDetails){
            if(self.state.productDetails.attributes){
              licType = self.state.productDetails.attributes.lictype              
            }
            if(self.state.productDetails.prodRef){
             
              if(self.state.productDetails.prodRef.name){
                name = self.state.productDetails.prodRef.name
              }
              if(self.state.productDetails.prodRef.description){
                description = self.state.productDetails.prodRef.description
                //if(description.indexOf())
              }
            }
            price = self.state.productDetails.price
          }

          if(this.state.purchaseProcessed){
            if(this.state.isErrorMessage){
              result = React.createElement("div", null, 
                React.createElement("h3", null, "Error Completing Purchase"), 
                React.createElement("p", null, 
                  "Your purchase of ", name, " ", licType, " has failed." + ' ' +
                  "Please go to your ", React.createElement("a", {onClick: this.props.gotoAccount}, "account"), " page to ensure your payment method is valid."
                )
              );
            }
            else{
              result = React.createElement("div", null, 
                React.createElement("h3", null, "Purchase Complete"), 
                React.createElement("p", null, 
                  "Your purchase of ", name, " ", licType, " has succeeded." + ' ' +
                  "Please go to your ", React.createElement("a", {onClick: this.props.gotoAccount}, "account"), " page to download your software."
                ), 
                React.createElement("p", {style: {textAlign:'left',width:'80%',marginLeft:'10%'}}, 
                  "The license file is named pest_hp.lic. It is specific to your copy of PEST_HP. This file must be placed" + ' ' + 
                  "in the folder from which the PEST_HP run manager is run. There is no need to place it in folders used by" + ' ' + 
                  "run agents.", React.createElement("br", null), React.createElement("br", null), 

                  "The PEST whisperer (PWHISP_HP) and cost estimator (PCOST_HP) are packaged with PEST_HP. You do not need" + ' ' + 
                  "the license file to run these.", React.createElement("br", null), React.createElement("br", null), 
                  
                  "You can use PEST_HP in exactly the same way that you use BEOPEST. However, we recommend that you read the" + ' ' + 
                  "PEST_HP manual in order to acquaint yourself with its many new features.", React.createElement("br", null), React.createElement("br", null), 
                  
                  "Once you have purchased PEST_HP, you can download the latest version at any time."
                )
              );
            }
            
          }
          else{

            var paymentMethod = [
              React.createElement("span", {key: "payMethodLabel", className: "labelField"}, "Payment Method: "),
              React.createElement("select", {
                 key: "payMethodSelect", 
                 ref: "paymentMethod", 
                 value: self.state.paymentMethod, 
                 onChange: self.updatePaymentMethod}, 
                
                  self.state.customerDetails.sources.data.map(function(card){
                    return (React.createElement("option", {key: card.id, value: card.id}, card.brand + ' ending in ' + card.last4))
                  })
                
              )
              ]
            var purchaseButton = React.createElement("a", {className: "button", onClick: self.confirmPurchase}, "Continue")
            var openSource = (
                React.createElement("p", {style: {fontSize:'.9em'}}, 
                  React.createElement("a", {href: "mailto:pestcloud@sspa.com?subject=Inquiry: UNIX version of PEST_HP"}, "Contact us"), " if you would like a UNIX version of PEST_HP. We can give you source code (in FORTRAN and C++) for your own compilation."
                )
            )


            if(self.state.isConfirming){
              var cardId  = self.state.paymentMethod; //self.getVal(this.refs.paymentMethod)
              var card = self.state.customerDetails.sources.data.filter(function(card){
                            return card.id == cardId
                          })[0]

              


              if(!self.state.userAgreeEULA){
                
                openSource = null

                description = (
                  React.createElement("div", {id: "euladesc", className: "euladesc", onScroll: function(){

                    var ht = document.getElementById("euladesc").scrollHeight
                    var cht = document.getElementById("euladesc").clientHeight
                    var top  =document.getElementById("euladesc").scrollTop

                    if(ht-top<500 && !self.state.eulaScrolled){
                      self.setState({eulaScrolled:true})
                    }
                    console.log(ht-top)}}, 
React.createElement("p", null, "S. S. PAPADOPULOS ", '&', " ASSOCIATES, INC.", React.createElement("br", null), 
"SOFTWARE LICENSE AGREEMENT"
), 

React.createElement("p", null, "This Software License Agreement (this “Agreement”) is a legal agreement between you (either an individual or a legal entity) (hereinafter referred to as “Customer” or “You”) and S.S. Papadopulos ", '&', " Associates, Inc. (hereinafter referred to as “Licensor”). This Agreement states the terms and conditions under which Customer may use the software that accompanies this Agreement, which may also include associated media, printed materials, and online or electronic documentation (collectively, the “Software”)."
 
), 

React.createElement("p", null, "BY CLICKING THE ACCEPT BUTTON BELOW, OR BY DOWNLOADING, INSTALLING, ACTIVATING AND/OR OTHERWISE USING THE SOFTWARE, YOU ARE AGREEING THAT YOU HAVE READ, AND THAT YOU AGREE TO COMPLY WITH AND ARE BOUND BY, THE TERMS AND CONDITIONS OF THIS AGREEMENT AND ALL APPLICABLE LAWS AND REGULATIONS. IF YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT, THEN DO NOT DOWNLOAD, INSTALL, ACTIVATE OR OTHERWISE USE THE SOFTWARE AND PROMPTLY RETURN THE SOFTWARE TO LICENSOR FOR A REFUND OF THE LICENSE FEE. IF YOU ARE ACCEPTING THIS AGREEMENT ON BEHALF OF AN ENTITY, YOU HEREBY REPRESENT TO LICENSOR THAT YOU HAVE THE APPROPRIATE AUTHORITY TO ACCEPT THIS AGREEMENT ON BEHALF OF SUCH ENTITY. Any reproduction or redistribution of the Software not in accordance with this Agreement is expressly prohibited."

), 

React.createElement("p", null, "1. LICENSE FEE. Customer’s use of the Software is conditioned on payment of the license fee (“License Fee”) specified on Licensor’s online order form. Customer is permitted to use the Software in accordance with the number of permitted user licenses purchased by Customer (“Purchased Licenses”) by payment of the corresponding License Fee. Each Purchased License permits one individual user employed by Customer (“Individual User”) to use the Software according to the terms and conditions of this Agreement."

), 

React.createElement("p", null, "2. GRANT OF LICENSE."

), 

React.createElement("p", null, "(a) Grant. Subject to the terms and conditions of this Agreement, Licensor hereby grants Customer a nonexclusive, personal license, without the right to sublicense, to use the object code version of the Software solely for Customer’s internal business purposes to the extent permitted by Customer’s payment of applicable License Fee. Customer may use the documentation accompanying the Software in connection with permitted uses of the Software."

), 

React.createElement("p", null, "(b) Installation and Use. Customer is permitted to install, use, access, display and run one copy of the Software on a single computer, such as an office desktop computer, home computer, or laptop computer (“Workstation Computer”) of an Individual User for each Purchased License. Customer is also permitted to copy the Software onto multiple Workstation Computers of an Individual User, provided that only one copy of the Software is used at any time, and only by the Individual User. If the Software is installed onto multiple Workstation Computers, or is used on a network (local or external, including any cloud services), Customer must purchase the number of Purchased Licenses of the Software equal to the number of Individual Users who will use the Software, except when Customer has purchased an unlimited user license, which permits Customer to have an unlimited number of Individual Users employed by Customer to use the Software at any time, subject to the terms and conditions of this Agreement. Customer is permitted to make a single copy of the Software for back-up purposes, provided such copy is not installed or used on any computer. Customer is not permitted to copy the written materials accompanying the Software."

), 

React.createElement("p", null, "3. LIMITATIONS ON REVERSE ENGINEERING, DECOMPILATION, AND DISASSEMBLY.  Customer may not reverse engineer, decompile, or disassemble the Software by any means whatsoever, or alter, modify, enhance, or create a derivative work of the Software. Customer may not remove, alter, or obscure any product identification, copyright, or other intellectual property notices in the Software. The Software is licensed as a single product. Its components may not be separated for use on more than one Workstation Computer."

), 

React.createElement("p", null, "4. INTELLECTUAL PROPERTY. All right, title, and interest in and to the Software, including but not limited to all computer code, algorithms, data, proprietary information, images, photographs, animations, video, audio, music, and text, and all intellectual property rights in the foregoing, worldwide, are owned exclusively by Licensor or its suppliers. The Software is protected by copyright and other intellectual property laws and treaties. The Software is licensed, and not sold, to Customer for use only under the terms of this Agreement. Except as expressly licensed to Customer herein, Licensor and its suppliers reserve all right, title and interest in the Software and all associated copyrights, trademarks, and other intellectual property rights therein. The Agreement is limited to the intellectual property rights of Licensor and its suppliers in the Software and does not include any rights to other intellectual property."

), 

React.createElement("p", null, "5. NO SUPPORT OBLIGATIONS. Licensor has no obligation to provide maintenance or support services for the Software, or to provide updates or upgrades to the Software (“Updates”). If Licensor elects to provide Updates generally to its customers for no additional charge, it will make available such Updates at no additional charge to Customer during the period beginning with Customer’s payment of the License Fee for the Software and ending three (3) years later. Any Updates provided by Licensor shall be considered Software governed by the terms and conditions of this Agreement. To use Software identified as an Update, Customer must first be licensed for the Software identified by Licensor as eligible for the Update."

), 

React.createElement("p", null, "6. TERM AND TERMINATION. This Agreement is effective from payment of the License Fee until terminated. Customer’s rights under this Agreement will terminate immediately and automatically if Customer fails to comply with any of the terms and conditions of this Agreement. Promptly upon termination, Customer must cease all use of the Software, destroy all copies of the Software in Customer’s possession or control, and, upon request of Licensor, certify such destruction. Licensor’s termination of this Agreement will not limit any of Licensor’s other rights or remedies at law or in equity."

), 

React.createElement("p", null, "7.  LIMITED WARRANTY AND DISCLAIMER."  

), 

React.createElement("p", null, "(a) Limited Warranty. Licensor warrants that (i) the Software will perform substantially in accordance with the accompanying written materials, and (ii) the Software is properly recorded on the disk or server media from which it is downloaded (“Warranty”)." + ' ' +

"("), 

React.createElement("p", null, "b) DISCLAIMER OF WARRANTIES. THE EXPRESS WARRANTY IN SECTION 7(a) IS IN LIEU OF ALL OTHER WARRANTIES. LICENSOR DISCLAIMS ALL OTHER WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF NON-INFRINGEMENT, AND ARISING FROM USAGE OF TRADE OR COURSE OF DEALING OR PERFORMANCE, WITH RESPECT TO THE SOFTWARE, THE ACCOMPANYING WRITTEN MATERIALS, AND ANY ACCOMPANYING HARDWARE. LICENSOR DOES NOT WARRANT THAT THE USE OR OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED OR ERROR FREE. CUSTOMER ACKNOWLEDGES THAT IT HAS NOT RELIED ON ANY WARRANTIES OTHER THAN THE EXPRESS WARRANTY IN SECTION 7(a)."

), 

React.createElement("p", null, "8. EXCLUSION OF CONSEQUENTIAL AND CERTAIN OTHER DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL LICENSOR OR ITS SUPPLIERS BE LIABLE FOR ANY SPECIAL, INCIDENTAL, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS OR CONFIDENTIAL OR OTHER INFORMATION, FOR BUSINESS INTERRUPTION, FOR PERSONAL INJURY, FOR LOSS OF PRIVACY, FOR FAILURE TO MEET ANY DUTY INCLUDING OF GOOD FAITH OR OF REASONABLE CARE, FOR NEGLIGENCE, AND FOR ANY OTHER PECUNIARY OR OTHER LOSS WHATSOEVER) ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF OR INABILITY TO USE THE SOFTWARE, THE PROVISION OF OR FAILURE TO PROVIDE SUPPORT OR OTHER SERVICES, INFORMATION, SOFTWARE, AND RELATED CONTENT THROUGH THE SOFTWARE OR OTHERWISE ARISING OUT OF THE USE OF THE SOFTWARE, OR OTHERWISE UNDER OR IN CONNECTION WITH ANY PROVISION OF THIS AGREEMENT, EVEN IN THE EVENT OF THE FAULT, TORT (INCLUDING NEGLIGENCE), MISREPRESENTATION, STRICT LIABILITY, BREACH OF CONTRACT OR BREACH OF WARRANTY OF LICENSOR OR ANY SUPPLIER, AND EVEN IF LICENSOR OR ANY SUPPLIER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES."

), 

React.createElement("p", null, "9. LIMITATION OF LIABILITY. Notwithstanding any damages that Customer might incur for any reason whatsoever, the entire liability of Licensor and any of its suppliers under this Agreement, and Customer’s exclusive remedy hereunder, shall be limited to actual, direct damages up to the License Fee actually paid by Customer for use of the Software. The limitations, exclusions and disclaimers herein shall apply to the maximum extent permitted by applicable law, even if any remedy fails its essential purpose."

), 

React.createElement("p", null, "10. NOTICE.  Any notice to Licensor required or permitted hereunder shall be provided in writing and will be deemed to have been duly given if delivered via email, personal delivery, or mailed by first-class, registered or certified mail to the address below, in each case with confirmation of receipt:", 

React.createElement("br", null), "S.S. Papadopulos ", '&', " Associates, Inc.", 
React.createElement("br", null), "7944 Wisconsin Avenue", 
React.createElement("br", null), "Bethesda, MD 20814", 
React.createElement("br", null), "Attn: Chris Muffels", 
React.createElement("br", null), "Email: cmuffels@sspa.com", 
React.createElement("br", null), "Phone number: (301) 718-8900", 
React.createElement("br", null), "Fax number: (301) 718-8909"

), 

React.createElement("p", null, "11. RESTRICTION ON ASSIGNMENT OF AGREEMENT. This Agreement, and the rights, licenses and obligations hereunder, may not be assigned, by operation of law or otherwise, by Customer without the express prior written consent of Licensor, and any assignment or transfer in violation of this Section 11 shall be null and void. Customer is not permitted to sublicense or lease its rights under this Agreement to use the Software."

), 

React.createElement("p", null, "12. CHOICE OF LAW AND JURISDICTION.  This Agreement shall be deemed to have been made in, and shall be construed pursuant to the laws of the State of Maryland, without regard to conflicts of law provisions thereof. Any legal action or proceeding relating to this Agreement shall be brought exclusively in courts located in Montgomery County, Maryland, and each party consents to the exclusive jurisdiction thereof. The prevailing party in any action to enforce this Agreement shall be entitled to recover reasonable costs and expenses including, without limitation, reasonable attorneys’ fees."

), 

React.createElement("p", null, "13. ENTIRE AGREEMENT; SEVERABILITY. This Agreement sets forth Licensor's entire liability and Customer’s exclusive remedy with respect to the Software and supersedes the terms of any purchase orders and any other communications or advertising with respect to the Software. Customer acknowledges that this Agreement is a complete statement of the agreement between Customer and Licensor with respect to the Software, and that there are no other prior or contemporaneous understandings, promises, representations, or descriptions with respect to the Software. No amendment to or modification of this Agreement will be binding unless made in writing and signed by Licensor. No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof. If any provision of this Agreement is held to be invalid or unenforceable, the other provisions shall continue in full force and effect the invalid or unenforceable provision shall be amended to achieve as closely as possible the effect of the original provision."

), 

React.createElement("p", null, "14. INJUNCTIVE RELIEF. Customer agrees that a breach of this Agreement adversely affecting Licensor’s proprietary rights in the Software may cause irreparable injury to Licensor for which monetary damages would not be an adequate remedy and Licensor shall be entitled to equitable relief in addition to any remedies it may have hereunder or at law."

), 

React.createElement("p", null, "15.  SURVIVAL. The provisions of this Section 15 and Sections 3, 4, 7(b), 8, 9, 12, 13, and 14 shall survive termination of this Agreement, for any reason."
)




                  )
                )
                purchaseButton = (
                    React.createElement("span", null, 
                      React.createElement("a", {className: 'button' + (self.state.eulaScrolled?'':' buttonDisabled'), onClick: ()=>{
                        if(self.state.eulaScrolled){
                            self.setState({userAgreeEULA:true})                          
                        }

                    }}, "I Agree"), "  ", 
                      React.createElement("a", {className: "button buttonErr", onClick: self.changePayment}, "Back"), "  "
                    )
                )
                
                paymentMethod = React.createElement("span", {className: "eulalink"}, "A copy of this agreement can be downloaded ", React.createElement("a", {target: "_blank", href: "/images/SSPA_Software_License_Agreement_042518.pdf"}, "here"), ".")
              }
              else{

                
                if(card){
                  paymentMethod = React.createElement("span", null, card.brand, " - ", card.last4, " will be charged $", ((price+self.state.taxAmount)/100).toFixed(2))
                  purchaseButton = (
                    React.createElement("span", null, 
                      React.createElement("a", {className: "button", onClick: self.purchase}, "Confirm"), "  ", 
                      React.createElement("a", {className: "button buttonErr", onClick: self.changePayment}, "Back"), "  "
                    )
                  )
                  if(self.state.isPurchasing){
                    purchaseButton = React.createElement("span", null, "...processing payment");
                  }
                }
              }


            }

            var taxNote = null;
            if(self.state.taxRate){
              taxNote = React.createElement("span", null, "+ $", (self.state.taxAmount/100).toFixed(2), " ", self.state.taxDescription)
            }
            if(self.props.sku=='PESTHP-UNL'){
              licType = React.createElement("span", null, React.createElement("span", {className: "infinity"}, "∞"), " Users")
            }
// console.log(self.props)
// console.log(licType)
            result = (
                React.createElement("div", null, 
                  React.createElement("div", {className: "column-3"}, 
                    React.createElement("h3", null, name), " ", React.createElement("br", null), 
                    React.createElement("ul", {className: "hpLevels-single"}, 
                      React.createElement("li", {style: {backgroundColor:self.props.color}}, 
                        React.createElement("div", {className: "hpBuyButton"}, 
                          React.createElement("a", {className: "purchaseLink", href: "#"}, 
                            React.createElement("div", {className: "hpItemDesc"}, licType), 
                            "$", price/100, " USD"
                          )
                        )
                      )
                    ), 
                    taxNote
                  ), 
                  React.createElement("div", {className: "column-23", style: {textAlign:'left'}}, 
                    React.createElement("p", {style: {fontSize:'.9em'}}, description), 
                    openSource
                  ), 

                  React.createElement("div", null, 
                    paymentMethod, 
                    React.createElement("span", {className: "labelField"}, "    "), 
                    purchaseButton
                  )

                )
            );
          }

        }
      }
    }
    return result

  }

});

module.exports = ProductModal;



