var React = require('react');
var Parse = require('parse').Parse;
var ParseReact = require('parse-react-2');

var Spinner = require('react-spinkit');


var Lightbox= require('../Lightbox/Lightbox.react.js');
var LightboxModal= require('../Lightbox/LightboxModal.react.js');
var LightboxTrigger= require('../Lightbox/LightboxTrigger.react.js');

var NewModel= require('./NewModel.js');

var minCPUTimeVal = 0.083333333333333;//.25

var cpuCompare = {
  standard_d1_v2:{
    description:'1 Core, 3.5GB Ram, 50GB Storage',
    cost:.35
  },
  standard_d2_v2:{
    description:'2 Core, 7GB Ram, 100GB Storage',
    cost:.49
  },
}
var cpuMap = {
  standard_1:{
    name: 'standard_1',
    vm: 'standard_d1_v2',
    cpc: .35
  },
  standard_2:{
    name: 'standard_2',
    vm: 'standard_d2_v2',
    cpc: .35
  },
  standard_4:{
    name: 'standard_4',
    vm: 'standard_d3_v2',
    cpc: .35
  },
  standard_8:{
    name: 'standard_8',
    vm: 'standard_d4_v2',
    cpc: .35
  },
  standard_16:{
    name: 'standard_16',
    vm: 'standard_d5_v2',
    cpc: .35
  },
  enhanced_2:{
    name: 'enhanced_2',
    vm: 'standard_d11_v2',
    cpc: .49
  },
  enhanced_4:{
    name: 'enhanced_4',
    vm: 'standard_d12_v2',
    cpc: .49
  },
  enhanced_8:{
    name: 'enhanced_8',
    vm: 'standard_d13_v2',
    cpc: .49
  },
  enhanced_16:{
    name: 'enhanced_16',
    vm: 'standard_d14_v2',
    cpc: .49
  },
  enhanced_20:{
    name: 'enhanced_20',
    vm: 'standard_d15_v2',
    cpc: .49
  },
}
var memCompare = {
  standard:{
    description:'Standard: ~3.5GB Ram/Core',
    cost:.35
  },
  enhanced:{
    description:'Enhanced: ~7GB Ram/Core',
    cost:.49
  },
}


var ModelDetails = React.createClass({displayName: "ModelDetails",
  mixins: [ParseReact.Mixin],

  getInitialState: function() {
    var initState = {
      cpuConfigName:'standard_1',
      cpu:'standard_d1_v2',

      nodeCount:3,
      cpuPerNode:4,
      cpuCount:12,
      ccost:.35,
      npar:100,
      nparrun:100,
      niter:10,
      mrun:5,
      isConfirming:false,
      isEstimating:false,
      isDeployingJob:false,
      taskFiles:null,
      currentTaskFile:null,
      customerDetails:null,
      taxRate:0,
      taxAmount:0,
      taxDescription:null,
      costCalcType:null,
      applyCredits:false,
      creditBalance:0,
      creditRef:null,
      creditAmount:0,
      costLimit:0,
      job:this.props.job
    }


    if(initState.job.parameterCount){
      initState.npar = initState.job.parameterCount
    }
    if(initState.job.runCount){
      initState.nparrun = initState.job.runCount
    }
    if(initState.job.iterationCount){
      initState.niter = initState.job.iterationCount
    }
    var ehours = initState.nparrun*initState.niter*(initState.mrun/60)
    var ecost = ehours*cpuMap[initState.cpuConfigName].cpc//cpuCompare[initState.cpu].cost

    // ecost = 
    initState.ehours = ehours;
    initState.ecost = 'calculating';//Math.ceil(ecost*1.1*1.03+.3); //10% buffer
    initState.costLimit = initState.ecost;
    initState.costCalcType = 'cpu-compute'
    return initState;
  },

  calculatePCost: function(){
    var self = this;
    var useHPFile = self.state.job.useHPFile
    if(!self.state.niter || !self.state.mrun){
      return
    }
    if(self.state.job.pstFileUrl && self.state.job.pstFileUrl.indexOf('/') && self.state.job.pstFileUrl.indexOf('?') ){
      // https://peststore.blob.core.windows.net:443/yesgjvpigq/test426z_pst_1493214736681?st=2017-04-26T12%3A12%3A17Z&se=2017-06-02T13%3A52%3A17Z&sp=r&sv=2015-04-05&sr=b&sig=ja%2Fu%2BF8o3mXgbSgS5mNjMc8sTERfqK8FDV%2BWK%2Bk6Nk0%3D


      var parts = self.state.job.pstFileUrl.split('?')[0].split('/').slice(-2)
      if(parts.length!=2 || !parts[0].trim().length || !parts[1].trim().length){
      return;
      }
      var containerName = parts[0].trim()
      var blobName = parts[1].trim()


      var pcfd = new FormData();
      pcfd.append('user', containerName);
      pcfd.append('name', blobName);
      pcfd.append('runtime', self.state.mrun.toFixed(0));
      pcfd.append('agents', self.state.cpuCount.toFixed(0));
      pcfd.append('rate', (self.state.ccost || .39).toFixed(8));
      pcfd.append('itnor', (self.state.niter || 1).toFixed(0));

      $.ajax({
        //url: '/upload',
        url: '/pcest',
        type : 'POST',
        data : pcfd,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(upst) {
          console.log({pcest:upst})
          var resLines = (upst.data||'').split('\n')
          var kvp = resLines.map(function(rl){

            if(rl.indexOf('$')){
              var kv = rl.split('$')
              if(kv.length>1){
                var kvres = {}
                var kk = kv[0].trim();
                var vv = kv[1].trim();
                if(kk && kk.length && vv && vv.length){
                  kvres.name=kk;
                  kvres.value=vv;
                  return kvres                   
                }
              }
            }
          })

          resLines = (upst.data||'').split('Some specifications:-')[1].split('\n')
          var kvp2 = resLines.map(function(rl){
            if(rl.indexOf(':')){
              var kv = rl.split(':')
              if(kv.length>1){
                var kvres = {}
                var kk = kv[0].trim();
                var vv = kv[1].trim();
                if(kk && kk.length && vv && vv.length){
                  kvres.name=kk;
                  kvres.value=vv;
                  return kvres                   
                }
              }
            }
          })


          var values = kvp.filter(function(t){return t})
          var values2 = kvp2.filter(function(t){return t})

          var newNiter = self.state.niter
          if(values2.length>6 && values2[6].value){
              newNiter = values2[6].value
          }


          var withHP=0;
          var withoutHP=0;

          values.map(function(vv,vx){
            if(vv.name.indexOf('without hp')){
              withoutHP = vv.value
            }
            if(vv.name.indexOf('with hp')){
              withHP = vv.value
            }
          })
          var eeCost = withoutHP
          if(useHPFile){
            eeCost = withHP
          }
          eeCost= Math.max(Math.ceil(eeCost*1.03+.3),5)
          if(self.state.minCost && self.state.minCost>5){
            eeCost= Math.max(eeCost,self.state.minCost)
          }

          var ttotal = eeCost
          if(self.state.taxRate && +self.state.taxRate>0){
            ttotal = eeCost*(1+(+self.state.taxRate))
          }
          self.setState({
            niter:newNiter,
            ecost: eeCost,
            costLimit:Math.ceil(ttotal),
          })
          console.log({withHP:withHP,withoutHP:withoutHP,minCost:self.state.minCost})


        },
        error:function(pstErr){
          console.log({pcestErr:pstErr,minCost:self.state.minCost})
        }
      })

      // var pstFileDataUrl = initState.job.pstFileData.url()
      // $.ajax({
      //   url: '/pcest',
      //   //url: pstFileDataUrl,
      //   type : 'GET',
      //   processData: false,  // tell jQuery not to process the data
      //   contentType: false,  // tell jQuery not to set contentType
      //   success : function(data) {
      //     console.log({pst:data})
      //     // callback()
      //   }
      // })

    }
  },

  calculateCpuUtilization: function(mem,pnum,pact){
    var self=this;

    var nodes = 0
    var pact = pact || pnum
    if(mem && pnum%20==0){
      nodes = pnum/20
      if(nodes>49){
        return {nodes:50,coresNeeded:pact,coresAqcuired:1000,cpn:20,ntype:cpuMap[(mem?'enhanced':'standard')+'_20']}
      }
      return {nodes:nodes,coresNeeded:pact,coresAqcuired:20*nodes,cpn:20,ntype:cpuMap[(mem?'enhanced':'standard')+'_20']}
    }
    if(pnum%16==0){
      nodes = pnum/16
      if(nodes>49){
        if(mem){
          return self.calculateCpuUtilization(mem,pnum+1,pact)
        }
        return {nodes:50,coresNeeded:pact,coresAqcuired:800,cpn:16,ntype:cpuMap[(mem?'enhanced':'standard')+'_16']}
      }
      return {nodes:nodes,coresNeeded:pact,coresAqcuired:16*nodes,cpn:16,ntype:cpuMap[(mem?'enhanced':'standard')+'_16']}
    }
    if(pnum%8==0){
      nodes = pnum/8
        if(nodes>=49){
          return self.calculateCpuUtilization(mem,pnum+1,pact)
        }
      return {nodes:nodes,coresNeeded:pact,coresAqcuired:8*nodes,cpn:8,ntype:cpuMap[(mem?'enhanced':'standard')+'_8']}
    }
    if(pnum%4==0){
      nodes = pnum/4
        if(nodes>=49){
          return self.calculateCpuUtilization(mem,pnum+1,pact)
        }
      return {nodes:nodes,coresNeeded:pact,coresAqcuired:4*nodes,cpn:4,ntype:cpuMap[(mem?'enhanced':'standard')+'_4']}
    }
    if(pnum%2==0){
      nodes = pnum/2
        if(nodes>=49){
          return self.calculateCpuUtilization(mem,pnum+1,pact)
        }
      return {nodes:nodes,coresNeeded:pact,coresAqcuired:2*nodes,cpn:2,ntype:cpuMap[(mem?'enhanced':'standard')+'_2']}
    }
    if(pnum>=49){
      return self.calculateCpuUtilization(mem,pnum+1,pact)
    }
    if(mem && pnum%2==1){
      return self.calculateCpuUtilization(mem,pnum+1,pact)
    }
    return {nodes:pnum,coresNeeded:pact,coresAqcuired:pnum,cpn:1,ntype:cpuMap['standard_1']}     
  },

  cloneModel: function(){
    this.refs.cloneModel.openLightbox()
    // console.log(React.findDOMNode('cloneModel'))
  },

  getVal: function(prop){
    var val = React.findDOMNode(prop)
    if(val!=undefined && val!=null){
      return val.value;
    }
    return '';
  },
  estimateCost: function(event) {

    var self=this;

    var mrun  = +self.getVal(this.refs.mrun).replace(/[^0-9]/gi,'');
    var niter  = +self.getVal(this.refs.niter).replace(/[^0-9]/gi,'');
    //var npar = +self.getVal(this.refs.npar).replace(/[^0-9]/gi,'');
    // var niter = this.state.niter;
    var nparrun = this.state.nparrun;
    var npar = this.state.npar;

    //removed enhanced memory for now 2019-08-23 DH
    var vmsPerPool = +self.getVal(this.refs.vmsPerPool)
    var cpusPerVM = +self.getVal(this.refs.cpusPerVM)
    var mem = 'standard'//self.getVal(this.refs.memChoice)
    var cpu = 'standard_d1_v2';//self.getVal(this.refs.cpuChoice)

    // var cpuCount  = React.findDOMNode(this.refs.cpuCount);
    var cpuCountVal = 0//+cpuCount.value.replace(/[^0-9]/gi,'')
    // var cpuConfig = self.calculateCpuUtilization(mem==='enhanced',cpuCountVal+1)
    
    var cpuCount = vmsPerPool*cpusPerVM
    // var cpuConfig = self.calculateCpuUtilization(mem==='enhanced',cpuCount)
    var cpuConfig = {
      nodes:vmsPerPool,
      coresNeeded:+cpuCount,
      coresAqcuired:+cpuCount,
      cpn:+cpusPerVM,
      ntype:cpuMap['standard_'+cpusPerVM]
    }
    console.log({cpuConfig,vmsPerPool,cpusPerVM,cpuCount})

    var cpuConfigName = cpuConfig.ntype.name

    cpuCountVal = cpuConfig.coresAqcuired
    cpu =cpuConfig.ntype.vm

    var nodeCount = +cpuConfig.nodes;
    var cpuPerNode = +cpuConfig.cpn;

    var ccost = +cpuConfig.ntype.cpc;
    // if(cpuCountVal>npar){
    //   cpuCountVal=npar
    // }
    // if(mrun<1){
    //   mrun=1
    // }
    // if(niter<1){
    //   niter=1
    // }
    // if(npar<1){
    //   npar=1
    // }
    // if(cpuCountVal<1){
    //   cpuCountVal=1
    // }

    // var ccost = +cpuCompare[cpu].cost;

    var ehours = nparrun*niter*(mrun/60)
    var ecostCalc = Math.ceil((ehours*ccost*1.1*1.03+.3))             //added 10% buffer
    var minCost = Math.ceil(((cpuCountVal+1)*ccost*minCPUTimeVal)*1.03+.3)  //added 10% buffer

    costCalcType='min'

    var ecost = Math.max(Math.max(ecostCalc,5),minCost)

    if(ecost==minCost){
      costCalcType = 'cpu-min'
    }
    else if(ecost==ecostCalc){
      costCalcType = 'cpu-compute'
    }

    //self.calculateTax(ecost)

    self.setState({
      cpuConfigName:cpuConfigName,
      nodeCount:nodeCount,
      cpuPerNode:cpuPerNode,
      cpuCount:cpuCountVal,
      cpu:cpu,
      ehours:ehours,
      // costLimit:ecost,
      ccost:ccost,
      minCost:minCost,
      ecost: 'calculating',
      mrun:mrun,
      niter:niter,
      npar:npar,
      costCalcType:costCalcType,
      isAddingFunds:false
    },function(){self.calculateTax(100)})

  },

// applyCreditsAmount
// applyCredits
// creditAmount
  handleApplyCreditsSelection: function(e){    
      this.setState({
        applyCredits:e.currentTarget.checked,
        creditAmount:0
      });
  },
  handleCreditAmount: function(){
    var creditAmount = React.findDOMNode(this.refs.creditAmount);
      this.setState({
        creditAmount:+creditAmount.value
      });
  },
  handleCostLimit: function(){ 

    var costLimit = React.findDOMNode(this.refs.costLimit);
    var clValue = costLimit.value.replace(/[^0-9\.]/gi,'').split('.').slice(0,2).join('.')


      this.setState({
        costLimit:clValue
      });
  },

  lightboxOpened: function(){
    this.isLightBoxOpen = true;
    // console.log('LightBoxOpen')
  },
  lightboxClosed: function(){
    var self=this;
    this.isLightBoxOpen = false;
    // console.log('LightBoxClosed')

    setTimeout(self.refreshCurrentJob, 1000);
  },
  refreshCurrentJob: function(){
    this.refreshJob(this.state.job.objectId)
  },
  deleteJobFile: function(url,callback){

    var self = this;
    var parts = url.split('?')[0].split('/').slice(-2)
    if(parts.length!=2 || !parts[0].trim().length || !parts[1].trim().length){
      return;
    }
    var containerName = parts[0].trim()
    var blobName = parts[1].trim()

    var formData = new FormData();

    formData.append('name', blobName);
    formData.append('user', containerName);
    //formData.append('file', this.state.uploadFile);
    $.ajax({
      //url: '/upload',
      url: '/fdel',
      type : 'POST',
      data : formData,
      processData: false,  // tell jQuery not to process the data
      contentType: false,  // tell jQuery not to set contentType
      success : function(data) {
        console.log(data)
        callback()
      }
    })
  },
  deleteCurrentJob: function(){
    var self=this;
    var currentJob = self.state.job;
    if(confirm('Are you sure you want to delete this job?  This will also PERMANENTLY delete any files stored in the cloud associated with this job.  If you proceed, you will not be able to recover any files associated with this job.')){

      //update db
      var updateFunc = function(){ 
        ParseReact.Mutation.Set(
          {className: 'UserJob', objectId: currentJob.objectId},
          {deleted:true})
        .dispatch()
        .then(function(){
          self.props.models()
        });
      }
      //delete package
      var delPackageFunc = function(){
        updateFunc()
      }
      if(currentJob.packageUrl){
          delPackageFunc = function(){
          self.deleteJobFile(currentJob.packageUrl,updateFunc)
        }
      }

      //delete cloned package
      var delClonePackageFunc = function(){
        delPackageFunc()
      }
      if(currentJob.clonePackageUrl){
          delClonePackageFunc = function(){
          self.deleteJobFile(currentJob.clonePackageUrl,delPackageFunc)
        }
      }

      //delete summary
      var delSummaryFunc = function(){
        delClonePackageFunc()
      }
      if(currentJob.summaryUrl){
          delSummaryFunc = function(){
          self.deleteJobFile(currentJob.summaryUrl,delClonePackageFunc)
        }
      }

      //delete result
      var delResultFunc = function(){
        delSummaryFunc()
      }
      if(currentJob.resultUrl){
          delResultFunc = function(){
          self.deleteJobFile(currentJob.resultUrl,delSummaryFunc)
        }
      }

      delResultFunc()

    }
  },
  refreshJob: function(jobId){
    var self = this;

    var query = new Parse.Query("UserJob");
    query.equalTo('objectId', jobId)
    
    query.first({
        success: function(uj){
          self.setState({job:uj.toPlainObject()},self.checkFiles)
          // console.log({
          //   refresh:jobId,
          //   cj:self.state.job,
          //   nj:uj.toPlainObject()
          // })
        },
        error: function(err){
          console.log({refreshErr:err});
        }
    });
  },

  // componentWillUnMount: function(){
  //   console.log({modelDetails:'componentWillUnMount'});
  //   socket.removeAllListeners(this.props.user);
  // },
  componentDidMount: function() {
    var self = this
    Parse.User.current().fetch()

    socket.on(this.props.user, function(m){
      //{jobStatus:{job:job.id,status:notifyStatus}}
      if(!self.isLightBoxOpen && m.jobStatus && m.jobStatus.job == self.state.job.objectId){
        console.log({modelDetails:'socketEvent',d:m});
        self.refreshJob(m.jobStatus.job)
      }
    });
    this.refreshCurrentJob();
    this.getCredits();
    this.getCustomer();
  },

  componentWillReceiveProps: function(p) {
    if(p && p.job && this.props && this.props.job && this.props.job.objectId != p.job.objectId){
     
      console.log({componentWillReceiveProps:p})
      this.props = p;

      this.setState({
        job:p.job
      },this.checkFiles)
      // this.checkFiles()
    }
  },
  updatePaymentMethod: function(){    
    var self=this;

    var cardId  = self.getVal(this.refs.paymentMethod)

    self.setState({
      paymentMethod:cardId
    },self.estimateCost)
  },
  calculateTax:function(amount){
    var self=this;

    var cardId  = self.state.paymentMethod
    var card = null;

    if(cardId && self.state.customerDetails && self.state.customerDetails.sources && self.state.customerDetails.sources.data){
      card = self.state.customerDetails.sources.data.filter(function(card){
                return card.id == cardId
              })[0] 
    }

    if(card){
      var isoCountry = null;
      var zip = card.address_zip
      var state = card.address_state

      if(card.address_country){
        isoCountry = getIsoCode(card.address_country)
      } 

      if(isoCountry && isoCountry.code && zip && state){
        $.ajax({
          url: '/acct/tax/'+isoCountry.code +'/' + zip  +'/' + state  +'/' + amount  +'/SAAS' ,
          type : 'GET',

          processData: false,  // tell jQuery not to process the data
          contentType: false,  // tell jQuery not to set contentType
          success : function(data) {
            if(data.tax && data.tax.amount_to_collect){
              var newState = {
                taxRate:0,
                taxAmount:data.tax.amount_to_collect,
                taxDescription:state + ' tax'
              }
              if(data.tax.breakdown && data.tax.breakdown.combined_tax_rate){
                newState.taxRate =  data.tax.breakdown.combined_tax_rate
              }

              self.setState(newState,self.calculatePCost)
            }
            else {
              self.setState({
                taxRate:0,
                taxAmount:0,
                taxDescription:null
              },self.calculatePCost)
            }
          }
        });
      }
      else {
        self.setState({
          taxRate:0,
          taxAmount:0,
          taxDescription:null
        },self.calculatePCost)
      }
    }
    else {
      self.setState({
        taxRate:0,
        taxAmount:0,
        taxDescription:null
      },self.calculatePCost)
    }


    
  },

  getCredits: function(userId){
    var self = this;
    var userId = this.props.user || userId
    if(userId){
      $.ajax({
        url: '/acct/credit/'+userId,
        type : 'GET',
        // data : formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(data) {
          if(data.err){
            //console.log(data);
            self.setState({
              creditBalance:0
            })
          }
          else{
            self.setState({
              creditBalance:data.balance,
              creditRef:data.ref
            })
          }
        }
      });//ajax
    }
    else{
      self.setState({
        creditBalance:0
      })
    }
  },
  getCustomer: function(){
    var self=this;

    $.ajax({
      url: '/acct/customer/'+self.props.customerId,
      type : 'GET',

      processData: false,  // tell jQuery not to process the data
      contentType: false,  // tell jQuery not to set contentType
      success : function(data) {

        if(data.err){
          self.setState({
            customerDetails:null,
          })
        }
        else{
          var newState = {
            customerDetails:data.customer
          }
          if(data.customer && data.customer.sources && data.customer.sources.data && data.customer.sources.data.length){
            newState.paymentMethod = data.customer.sources.data[0].id
          }
          self.setState(newState)
        }
      }
    });
  },
  checkFiles:function(){
    if(this.state.job.status != 'processing'){
      return;
    }
    var self = this;

    $.ajax({
      url: '/ls/'+this.state.job.objectId,
      type : 'GET',
      processData: false,  // tell jQuery not to process the data
      contentType: false,  // tell jQuery not to set contentType
      success : function(data) {
        if(data.work && data.work.length){
          self.setState({taskFiles:data.work})
        }
        console.log({checkFiles:data})
      }
    });
  },
  getFile:function(fname){
    var self = this;

    $.ajax({
      url: '/fget/'+this.state.job.objectId +'/'+fname,
      type : 'GET',
      processData: false,  // tell jQuery not to process the data
      contentType: false,  // tell jQuery not to set contentType
      success : function(data) {
        if(data && !data.err){
          var editData = data;
          if(fname=='stdout.txt'){
            editData = editData.split('file pest_hp.lic written ok.')[1]
          }
          // console.log({getFile:fname,data:editData})
          self.downloadContent=editData;
          $('.fileViewer').text(editData);          
        }
      }
    });
  },
  
  
  observe: function() {
    return {
      // jobs:(new Parse.Query('UserJob')).equalTo('user',this.props.user)
    };
  },

  stop: function() {
    var self = this;

    if(confirm('Are you sure you want to stop? PEST_HP has not finished. \r\n\r\nHowever if you stop now, you will only be charged for the time that you have used. (Minimum cost is $5.00)')){

    ParseReact.Mutation.Set(
      {className: 'UserJob', objectId: self.state.job.objectId},
      {userTerminated:true})
    .dispatch()
    .then(function(){
      self.props.models()
    });
    }


  },

  estimate: function(){
    var self = this;

    self.setState({
      isConfirming:false,
      isEstimating:true,
      isDeployingJob:false
    })

    // self.calculatePCost();
    self.calculateTax(100)


  },

  confirmDeploy: function(){
    var self=this;
    var costLimit = +self.state.costLimit
    if(isNaN(self.state.costLimit) || costLimit<5){
      alert('Invalid cost limit.  The minimum cost to run in the cloud is $5')
      return;
    }
    if(self.state.creditAmount>self.state.creditBalance){
      alert('Insufficient credit balance for amount specified')
      return;
    }
    if(self.state.creditAmount>self.state.costLimit){
      alert('Credit amount exceeds cost limit, please update and try again.')
      return;
    }

    var cpuMinCost = self.state.cpuCount*self.state.ccost*5/60
    if(costLimit<cpuMinCost){
      alert('The minimum cost to run this number of agents exceeds the cost limit provided. Please enter a value greater than $'+cpuMinCost.toFixed(2) )
      return;
    }

    var cardId  = self.getVal(this.refs.paymentMethod)

    self.setState({
      paymentMethod:cardId,
      isConfirming:true,
      isEstimating:false,
      isDeployingJob:false
    })
  },

  deploy: function(){
    var self = this;
    var job = this.state.job;



    var formData = new FormData();

    var cardId  = self.state.paymentMethod
    var customerId = self.props.customerId
    var jobId = self.state.job.objectId
    //var amountUSD = self.state.ecost + self.state.taxAmount
    var amountUSD = self.state.costLimit// + self.state.taxAmount
    var taxRate = self.state.taxRate
    

    if(self.state.creditAmount && self.state.creditAmount>0){

      if(self.state.creditAmount>self.state.costLimit){
        alert('Credit amount exceeds cost limit')
        return;
      }
      //amountUSD = Math.max((self.state.costLimit-self.state.creditAmount)+self.state.taxAmount,0)
      amountUSD = Math.max((+self.state.costLimit)-(+self.state.creditAmount),0)
      formData.append('creditAmount', self.state.creditAmount); 
      formData.append('creditRef', self.state.creditRef); 
    }

    self.setState({
      isDeployingJob:true
    })


    formData.append('userId', this.props.user);
    formData.append('customerId', customerId);
    formData.append('cardId', cardId);
    formData.append('jobId', jobId);
    formData.append('amountUSD', amountUSD);
    formData.append('taxRate', taxRate);

    
    $.ajax({
      url: '/acct/charge',
      type : 'POST',
      data : formData,
      processData: false,  // tell jQuery not to process the data
      contentType: false,  // tell jQuery not to set contentType
      success : function(data) {
        console.log({charged:data})
        if(data.err){
          self.setState({
            isErrorMessage:true,
            isDeployingJob:false
          })
          // alert(data)

            ParseReact.Mutation.Set(
              {className: 'UserJob', objectId: self.state.job.objectId},
              {
                lastError:data.err.message
              }
            )
            .dispatch()
        }
        else{
          console.log(data.charge);
          if(data.charge.status == 'succeeded'){

            var jobDetails = {
                taxRate:taxRate,
                cpuCount:self.state.cpuCount,
                nodeCount:self.state.nodeCount,
                cpuPerNode:self.state.cpuPerNode, 
                vmSize:self.state.cpu,
                cpuCost:cpuMap[self.state.cpuConfigName].cpc,//cpuCompare[self.state.cpu].cost,
                estHours:self.state.ehours,
                estCost:+self.state.costLimit,
                runTime:self.state.mrun,
                status:'deploy',
                email:data.email
            }
            if(data.charge && data.charge.id){
              jobDetails.chargeRef=data.charge.id;
            }
            if(self.state.creditAmount && self.state.creditAmount>0){
              jobDetails.credits=self.state.creditAmount
              jobDetails.creditRef=self.state.creditRef
            }
            else if(self.state.creditRef){
              jobDetails.credits=0
              jobDetails.creditRef=self.state.creditRef
            }

            ParseReact.Mutation.Set(
              {className: 'UserJob', objectId: self.state.job.objectId},
              jobDetails
            )
            .dispatch()
            .then(function(){
              self.props.models()

              // self.setState({
              //   isConfirming:false,
              //   isEstimating:false,
              //   isDeployingJob:false,
              // })
            });
          }
          else{
            ParseReact.Mutation.Set(
              {className: 'UserJob', objectId: self.state.job.objectId},
              {
                lastError:data.charge.failure_message
              }
            )
            .dispatch()

          }

        }
      }
    });
  },

  downloadFile: function(){
    if(this.state.currentTaskFile){
      var dlFilename = this.state.currentTaskFile
      if(dlFilename.slice(-4)!=='.txt'){
        dlFilename = this.state.currentTaskFile+'.txt'
      }
      var pom = document.createElement('a');
      pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.downloadContent));
      // pom.setAttribute('download', this.state.currentTaskFile);
      pom.setAttribute('download', dlFilename);
      pom.click();
    }
  },
  refreshFile: function(){
    var self = this;
    if(this.state.currentTaskFile){
      setTimeout(
      function(){
        self.getFile(self.state.currentTaskFile)
      },
      10)
    }
  },

  chooseFile: function(event){
    var newVal = event.target.value
    var self = this;

    self.setState({currentTaskFile: newVal});
     setTimeout(
      function(){
        self.getFile(newVal)
      },
      300)
  },

  gotoJob: function(jobId){
    var self = this;

      var query = new Parse.Query("UserJob");
      query.equalTo('objectId',jobId);
      query.find({
          success: function(jobs) {
            if(jobs && jobs.length){
              self.props.modelDetails(jobs[0].toPlainObject())
            }
            else{
              alert('Could not open job')
            }
          },

          error: function(qErr) {
              alert('Could not open job')
          }
      });

    
  },
  noop: function(){},

  calculateElapsedTime: function(d1,d2){
    if(!d1 || !d2 || !d1.getTime || !d2.getTime){
      return null
    }
    var val= (Math.abs(d1-d2))/(1000*60*60*24);
    var days = Math.floor(val)
    var tm = (val-days)* 1000*60*60*24
    var tms = new Date(tm).toISOString().split('T')[1].split('.')[0]
    return (days? days + 'D ':'') + tms
  },

  addFunds: function(){
    var self=this;
    var amount  = +self.getVal(this.refs.addFundsAmount).replace(/[^0-9\.]/gi,'');
    if(!amount || isNaN(amount) || amount <5){
      // this.refs.addFundsAmount.value=0.00
      alert('please enter a value of 5 dollars or greater')
    }
    else{


      var formData = new FormData();

      var cardId  = self.getVal(this.refs.addFundsPaymentMethod)
      var customerId = self.props.customerId
      var jobId = self.state.job.objectId
      var amountUSD = amount
      var taxRate = 0

      console.log({
        addFunds:true,
        cardId:cardId,
        customerId:customerId,
        jobId:jobId,
        amountUSD:amountUSD,
        taxRate:taxRate
      })

      formData.append('userId', this.props.user);
      formData.append('customerId', customerId);
      formData.append('cardId', cardId);
      formData.append('jobId', jobId);
      formData.append('amountUSD', amountUSD);
      formData.append('taxRate', taxRate);
      formData.append('capture', 'true');
      formData.append('recharge', 'true');
      
      $.ajax({
        url: '/acct/charge',
        type : 'POST',
        data : formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(data) {
          if(data.err){
            alert(data.err)
            // self.setState({
            //   isErrorMessage:true,
            //   isDeployingJob:false
            // })
            // // alert(data)

            //   ParseReact.Mutation.Set(
            //     {className: 'UserJob', objectId: self.state.job.objectId},
            //     {
            //       lastError:data.err.message
            //     }
            //   )
            //   .dispatch()
          }
          else{
            console.log(data.charge);
            if(data.charge.status == 'succeeded'){

              ParseReact.Mutation.Increment(
                {className: 'UserJob', objectId: self.state.job.objectId},
                'additionalFunds',
                amountUSD
              )
              .dispatch()
              .then(function(){
                  ParseReact.Mutation.AddUnique(
                    {className: 'UserJob', objectId: self.state.job.objectId},
                    'additionalTransactions',
                    data.charge.id
                  )
                  .dispatch()
                  .then(function(){
                     self.refreshCurrentJob()
                  })
              });
            }
            else{
              alert('error adding funds')
              // ParseReact.Mutation.Set(
              //   {className: 'UserJob', objectId: self.state.job.objectId},
              //   {
              //     lastError:data.charge.failure_message
              //   }
              // )
              // .dispatch()

            }

          }
        }
      });
      self.setState({isAddingFunds: false});
    }
  },

  render: function() {
    var self = this;
    console.log({modelDetails:'render'})
    if(self.state.isDeployingJob){
      return(
        React.createElement("div", null, 
          React.createElement("h3", null, "Deploying..."), 
          React.createElement(Spinner, {spinnerName: "three-bounce"})
        )
      )
    }
    else{
      var summaryUrl = null
      var resultUrl = null
      var deployButton = null
      var instructionText =null
      var cloneModal = null
      var cloneDetails = null

      var cancelButton = React.createElement("a", {className: "button buttonJob buttonLeft", onClick: this.props.models}, React.createElement("span", null, "Cancel"))

      if(self.state.isEstimating){

        // var confirmButton = null;
        // var cardAction = null;

        var cardAction = (
          React.createElement("span", null, "No Card on file")
        )
        var confirmButton = (
            React.createElement("a", {className: "button buttonJob buttonRight", onClick: function(){
              // self.props.models();
              self.props.gotoAccount();
            }}, "Add Card")
          )
        if(self.state.customerDetails || self.state.creditBalance){

          cardAction = []
          if(self.state.customerDetails.sources.data.length){
            cardAction.push(
                React.createElement("select", {
                  ref: "paymentMethod", 
                  value: self.state.paymentMethod, 
                  onChange: self.updatePaymentMethod}, 
                  
                    self.state.customerDetails.sources.data.map(function(card){
                      return (React.createElement("option", {key: card.id, value: card.id}, card.brand + ' ending in ' + card.last4))
                    })
                  
                )
            );
            confirmButton = (
              React.createElement("a", {className: "button buttonJob buttonRight", style: {backgroundColor:'#36952e'}, onClick: self.confirmDeploy}, "Continue")
            )
          }
          if(self.state.creditBalance>0){

            cardAction.push(React.createElement("br", null))
            cardAction.push(React.createElement("br", null))
            cardAction.push(React.createElement("a", {"data-tooltip": "Any credits will be used to pay for your"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})))
            cardAction.push(React.createElement("span", {className: "labelField"}, "Apply credits:"))
            cardAction.push(React.createElement("input", {style: {marginRight:'8px',transform:'scale(1.5)'}, type: "checkbox", checked: this.state.applyCredits, onChange: this.handleApplyCreditsSelection}))
            cardAction.push(React.createElement("span", {style: {fontSize:".8em"}}, "($", self.state.creditBalance.toFixed(2), ")")) 
            if(this.state.applyCredits){

              cardAction.push(React.createElement("br", null))
              cardAction.push(
                React.createElement("input", {
                  className: "rewardField", 
                  type: "text", 
                  ref: "creditAmount", 
                  placeholder: "Enter Amount", 
                  onChange: this.handleCreditAmount, 
                  value: self.state.creditAmount, 
                  defaultValue: self.state.ecost})
              )
            }
          }
        }


        var estNote = null;

      // costCalcType='min'
      // costCalcType = 'cpu-min'
      // costCalcType = 'cpu-compute'

        var estCostLabel = null;
        if(self.state.ecost=='calculating'){
          estCostLabel = 'Calculating...'
          if(!self.state.niter){
            estCostLabel = 'Enter num iterations'  
          }
          if(!self.state.mrun){
            estCostLabel = 'Enter model run time'  
          }
          
        }
        else if(self.state.ecost<=5){
          estCostLabel = '~ $'+self.state.ecost
        }
        else
        {
          estCostLabel = '~ $' + ((+self.state.ecost)-1) + ' to $'+self.state.ecost
        }

        if(self.state.costCalcType=='min'){
          estNote = React.createElement("a", {"data-tooltip": "There is a $5 minimum to use this service"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"}))
        }
        else if(self.state.costCalcType=='cpu-min'){
          estNote = React.createElement("a", {"data-tooltip": "There is a 15 minute per-core minimum to use this service"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"}))
        }

        var taxNote = null;
        if(self.state.taxAmount){
          taxNote = []
          //taxNote = ' +$' + self.state.taxAmount.toFixed(2) + ' ' + self.state.taxDescription 
          taxNote.push(React.createElement("br", null))
          taxNote.push(React.createElement("span", null, '+ '+ self.state.taxDescription  + ' @' + (self.state.taxRate*100 ).toFixed(2) + '%'))
        }

        /*
<select
                    onChange={this.estimateCost}
                    ref="cpuChoice">
                    {
                      Object.keys(cpuCompare).map(function(cpu){
                        return (<option key={cpu} value={cpu}>{cpuCompare[cpu].description}</option>)
                      })
                    }
                  </select><br />
        */

        var cpuOpts=[]
        var doEnhanced = (this.state.cpuConfigName.indexOf('enhanced')>=0?true:false)
        for (var i = 1; i < (doEnhanced?1000:800); i++) {
          if(i<50 || (i<100 && i%2==1) || (i<200 && i%4==3) || (i<400 && i%8==7) || (i<800 && i%16==15) || (doEnhanced && i<1000 && i%20==19)){
            cpuOpts.push(React.createElement("option", {key: 'cpu_'+i, value: i}, i))

          }
        };
        // <input
        //   className='rewardField'
        //   type='text'
        //   ref='npar'
        //   placeholder='number of parameters'
        //   onChange={this.estimateCost}
        //   value={self.state.npar}
        //   defaultValue={''} />
        // <span><b>{self.state.niter}</b></span>
        

        // var crLf = String.fromCharCode(13) + String.fromCharCode(10);
        var iterationsTT = 'Number of iterations.  If the NOPTMAX control variable in the PEST control file is greater than 8, the PEST::cloud cost estimator assumes 8 iterations will be required for completion of the inversion process, or that you will stop PEST yourself at this stage. You can override this estimate if you like by entering a different number in this box. However the cost estimator will not allow this number to exceed NOPTMAX (or pertinent Pareto settings if PEST if run in “pareto” mode).'
               

        var memorySelection=null
        if(false){
          memorySelection = (
            React.createElement(React.Fragment, {key: "memorySelection"}, 
              React.createElement("a", {"data-tooltip": "Standard memory option allows you to scale up to 800 CPUs.  Enhanced memory option allows you to scale up to 1000 CPUs."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
                  React.createElement("span", {className: "labelField"}, "Memory option:"), React.createElement("br", null), 
                  React.createElement("select", {
                    onChange: this.estimateCost, 
                    ref: "memChoice"}, 
                    
                      Object.keys(memCompare).map(function(mem){
                        return (React.createElement("option", {key: mem, value: mem}, memCompare[mem].description))
                      })
                    
                  )
            )
          )
        }
        advancedCPUOptions = null
        if(Parse.User.current().get('advancedCPUOptions')){
          advancedCPUOptions = [
            React.createElement("option", {key: "advopt_8", value: "8"}, "8"),
            React.createElement("option", {key: "advopt_16", value: "16"}, "16")
          ]
        }
                    

                  // <select
                  //   onChange={this.estimateCost}
                  //   value={self.state.cpuCount-1}
                  //   defaultValue={10}
                  //   ref="cpuCount">
                  //   {cpuOpts}
                  // </select>
               // <span className='labelField'>Estimated compute time:</span><br/>
               //    <span><b>{(self.state.ehours||1).toFixed(2)} hours</b></span><br /><br />
   
        return (
            React.createElement("div", {style: {padding:'20px'}}, 
              React.createElement("h3", null, "Deploy PEST case: ", this.state.job.modelName), 
              React.createElement("div", {className: "modalColumn"}, 
                React.createElement("p", null, 
                  React.createElement("a", {"data-tooltip": "Number of adjustable parameters"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
                  React.createElement("span", {className: "labelField"}, "Number of adjustable parameters:"), React.createElement("br", null), 
                  React.createElement("span", null, React.createElement("b", null, self.state.npar)), React.createElement("br", null), React.createElement("br", null), 

                  React.createElement("a", {"data-tooltip": "This is the maximum number of model runs that PEST needs to fill the Jacobian matrix. This exceeds the maximum number of adjustable parameters if higher order finite-difference derivatives are requested, either initially or later in the inversion process."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
                  React.createElement("span", {className: "labelField"}, "Maximum sensitivity runs per iteration:"), React.createElement("br", null), 
                  React.createElement("span", null, React.createElement("b", null, self.state.nparrun)), React.createElement("br", null), React.createElement("br", null), 

                  React.createElement("a", {"data-tooltip": iterationsTT}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
                  React.createElement("span", {className: "labelField"}, "Number of iterations:"), React.createElement("br", null), 
                  React.createElement("input", {
                    className: "rewardField", 
                    type: "text", 
                    ref: "niter", 
                    placeholder: "number of iterations", 
                    onChange: this.estimateCost, 
                    value: self.state.niter, 
                    defaultValue: ''}), 
                  React.createElement("br", null), React.createElement("br", null), 

                  React.createElement("a", {"data-tooltip": "Estimate the average model run time for your model in minutes.  If less than a minute, use '1'"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
                  React.createElement("span", {className: "labelField"}, "Model run time (minutes):"), React.createElement("br", null), 
                  React.createElement("input", {
                    className: "rewardField", 
                    type: "text", 
                    ref: "mrun", 
                    placeholder: "duration (minutes)", 
                    onChange: this.estimateCost, 
                    value: self.state.mrun, 
                    defaultValue: ''}), React.createElement("br", null), React.createElement("br", null), 

                  React.createElement("a", {"data-tooltip": "The number of compute instances (virtual machines) that will be used in calibrating your model."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
                  React.createElement("span", {className: "labelField"}, "Compute: ", React.createElement("b", null, self.state.cpuCount, " CPU cores")), React.createElement("br", null), 
                  
                  React.createElement("select", {
                    style: {width:'50px'}, 
                    onChange: this.estimateCost, 
                    ref: "vmsPerPool", 
                    defaultValue: 3}, 
                    React.createElement("option", {value: "1"}, "1"), 
                    React.createElement("option", {value: "2"}, "2"), 
                    React.createElement("option", {value: "3"}, "3"), 
                    React.createElement("option", {value: "4"}, "4"), 
                    React.createElement("option", {value: "5"}, "5"), 
                    React.createElement("option", {value: "6"}, "6"), 
                    React.createElement("option", {value: "7"}, "7"), 
                    React.createElement("option", {value: "8"}, "8"), 
                    React.createElement("option", {value: "9"}, "9"), 
                    React.createElement("option", {value: "10"}, "10"), 
                    React.createElement("option", {value: "11"}, "11"), 
                    React.createElement("option", {value: "12"}, "12"), 
                    React.createElement("option", {value: "13"}, "13"), 
                    React.createElement("option", {value: "14"}, "14"), 
                    React.createElement("option", {value: "15"}, "15"), 
                    React.createElement("option", {value: "16"}, "16"), 
                    React.createElement("option", {value: "17"}, "17"), 
                    React.createElement("option", {value: "18"}, "18"), 
                    React.createElement("option", {value: "19"}, "19"), 
                    React.createElement("option", {value: "20"}, "20"), 
                    React.createElement("option", {value: "21"}, "21"), 
                    React.createElement("option", {value: "22"}, "22"), 
                    React.createElement("option", {value: "23"}, "23"), 
                    React.createElement("option", {value: "24"}, "24"), 
                    React.createElement("option", {value: "25"}, "25"), 
                    React.createElement("option", {value: "26"}, "26"), 
                    React.createElement("option", {value: "27"}, "27"), 
                    React.createElement("option", {value: "28"}, "28"), 
                    React.createElement("option", {value: "29"}, "29"), 
                    React.createElement("option", {value: "30"}, "30"), 
                    React.createElement("option", {value: "31"}, "31"), 
                    React.createElement("option", {value: "32"}, "32"), 
                    React.createElement("option", {value: "33"}, "33"), 
                    React.createElement("option", {value: "34"}, "34"), 
                    React.createElement("option", {value: "35"}, "35"), 
                    React.createElement("option", {value: "36"}, "36"), 
                    React.createElement("option", {value: "37"}, "37"), 
                    React.createElement("option", {value: "38"}, "38"), 
                    React.createElement("option", {value: "39"}, "39"), 
                    React.createElement("option", {value: "40"}, "40"), 
                    React.createElement("option", {value: "41"}, "41"), 
                    React.createElement("option", {value: "42"}, "42"), 
                    React.createElement("option", {value: "43"}, "43"), 
                    React.createElement("option", {value: "44"}, "44"), 
                    React.createElement("option", {value: "45"}, "45"), 
                    React.createElement("option", {value: "46"}, "46"), 
                    React.createElement("option", {value: "47"}, "47"), 
                    React.createElement("option", {value: "48"}, "48"), 
                    React.createElement("option", {value: "49"}, "49"), 
                    React.createElement("option", {value: "50"}, "50")
                  ), 
                  React.createElement("span", {className: "labelField"}, "   Virtual Machines"), React.createElement("br", null), 

                  React.createElement("select", {
                    style: {width:'50px'}, 
                    onChange: this.estimateCost, 
                    ref: "cpusPerVM", 
                    defaultValue: "4"}, 
                    React.createElement("option", {value: "1"}, "1"), 
                    React.createElement("option", {value: "2"}, "2"), 
                    React.createElement("option", {value: "4"}, "4"), 
                    advancedCPUOptions
                  ), 
                  React.createElement("span", {className: "labelField"}, "   CPU/Machine"), 

                  React.createElement("br", null), 
                  React.createElement("span", null, "(", self.state.cpuCount-1, " agents + 1 master)"), 
                  
                  
                  React.createElement("br", null), React.createElement("br", null), 
                  
                  memorySelection

                )
              ), 
              React.createElement("div", {className: "modalColumn"}, 
                React.createElement("p", null, 

                  
                  React.createElement("span", {className: "labelField"}, "Hourly rate per CPU:"), React.createElement("br", null), 
                  React.createElement("span", null, React.createElement("b", null, "$", cpuMap[self.state.cpuConfigName].cpc)), React.createElement("br", null), React.createElement("br", null), 
                  
                  React.createElement("span", {className: "labelField"}, "Total estimated cost:"), React.createElement("br", null), 
                  React.createElement("span", {style: {fontSize:'.7em'}}, "(*5 minute minimum charge per CPU)"), React.createElement("br", null), 
                  React.createElement("span", null, React.createElement("b", null, estCostLabel), estNote, taxNote), React.createElement("br", null), React.createElement("br", null), 

                  React.createElement("a", {"data-tooltip": "This is the maximum you are willing to spend to calibrate this model.  Note: if your limit is lower than the estimated cost, PEST may not finish running before it is terminated."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
                  React.createElement("span", {className: "labelField"}, "Cost limit:"), React.createElement("br", null), React.createElement("input", {
                    className: "rewardField", 
                    type: "text", 
                    ref: "costLimit", 
                    placeholder: "Enter Amount", 
                    onChange: this.handleCostLimit, 
                    value: self.state.costLimit, 
                    defaultValue: self.state.ecost}), React.createElement("br", null), React.createElement("br", null), 

                  React.createElement("span", {className: "labelField"}, "Payment method:"), React.createElement("br", null), 
                  cardAction
                )
              ), 
              React.createElement("div", {className: "bottomButtons"}, 
                cancelButton, 
                confirmButton
              ), 
              React.createElement("p", null, 
                "Please try to be as precise as possible with your estimated model run time. Your card" + ' ' + 
                "will be pre-authorized for the amount in the “cost limit” box. If PEST runs for longer" + ' ' + 
                "than this, PEST execution will be terminated." + ' ' + 
                "You can extend the time that PEST is allowed to run by setting a higher limit in the “cost limit”" + ' ' + 
                "box. However if PEST finishes earlier than this, you will only be charged for the compute time you" + ' ' + 
                "use. You will not be charged more than the figure in the “cost limit” box.  ", React.createElement("b", null, "Please note, there is a" + ' ' + 
                "minimum charge.  This minimum charge is $5 or the cost of 5 minutes per CPU, whichever is greater.")
              )
            )
        )
        
      }
      else if(self.state.isConfirming){

        var cardId  = self.state.paymentMethod; //self.getVal(this.refs.paymentMethod)
        var card = self.state.customerDetails.sources.data.filter(function(card){
                    return card.id == cardId
                  })[0] || {last4:'xxxx'}

        var creditChargeAmount = null;
        var cardChargeDetails = null;
        var chargeWarning = null;

        if(self.state.creditAmount>0){
          creditChargeAmount = (
            React.createElement("span", null, 
              "You haved opted to apply $", self.state.creditAmount, " against your credit balance."  
            )
          )
        }
        if(Math.max(self.state.costLimit,self.state.chargeAmount)<self.state.ecost+self.state.taxAmount){
          chargeWarning = (
            React.createElement("span", null, 
              React.createElement("br", null), 
              React.createElement("br", null), 
              React.createElement("b", {style: {color:'red'}}, "WARNING:"), " The amount you have chosen is less than the estimated cost (plus tax if applicable).  If you continue, there is a chance your model will not complete."
            )
          )
        }
        // if(self.state.creditAmount<self.state.ecost){
          //${Math.max((self.state.costLimit-self.state.creditAmount)+self.state.taxAmount,.5).toFixed(2)}
        if(self.state.creditAmount<self.state.costLimit){
          //calculate cost less credits
          var cardCharge = Math.max((+self.state.costLimit)-(+self.state.creditAmount),.5)

          cardChargeDetails = (
            React.createElement("span", null, 
              "Your card ending in ", card.last4, " will be pre-authorized in the amount" + ' ' + 
              "of $", cardCharge.toFixed(2), "." + ' ' +
              "It will be charged once PEST_HP has finished." 
            )
          )
        }

        cancelButton = React.createElement("a", {className: "button buttonJob buttonLeft", onClick: self.estimate}, React.createElement("span", null, "Back"))
        confirmButton = (React.createElement("a", {className: "button buttonJob buttonRight", style: {backgroundColor:'#36952e'}, onClick: self.deploy}, "Confirm"))
        return (
            React.createElement("div", {style: {padding:'20px'}}, 
              React.createElement("h3", null, "Confirm deploy PEST case: ", this.state.job.modelName), 
              React.createElement("p", null, "You are about to deploy case ", '"' + this.state.job.modelName + '"', " using ", this.state.cpuCount, React.createElement("sup", null, "1"), " CPUs.",   
              creditChargeAmount, " ", cardChargeDetails, " The amount charged will be equal or less than the amount pre-authorized.  If you" + ' ' +
              "underestimated the resources needed to calibrate your model, PEST_HP may be terminated before completion.  ", React.createElement("b", null, "Please note, there is a" + ' ' + 
                "minimum charge.  This minimum charge is $5 or the cost of 5 minutes per CPU, whichever is greater.  This may be" + ' ' + 
              "reflected in the amount above."), 
              chargeWarning
              ), 
              React.createElement("p", null, "Do you wish to continue?"), 
              React.createElement("p", null, React.createElement("span", {className: "footnote"}, React.createElement("sup", null, "1"), " One additional node is allocated to communicate runs to agents.")), 
              React.createElement("div", {className: "bottomButtons"}, 
                cancelButton, 
                confirmButton
              )
            )
        )
        
      }


      var now = new Date().getTime()
      var isExpired=false
      console.log({
        n:new Date(),
        e:this.state.job.expires

      })
      if(this.state.job.expires){
        var expireTime = this.state.job.expires.getTime()

        if(now-expireTime>0){
          isExpired=true
        }
      }
      
      //['deploy','launching','starting','master','workers','processing','terminateJob','terminateJobPending','saveResult','computeWork','chargeWork','cleanupPool','cleanupJob']
      if(this.state.job.status == 'deploy' || this.state.job.status == 'launching' || this.state.job.status == 'starting'){
        instructionText=(
          React.createElement("p", null, "Your job has been deployed.  Please wait a moment while we prepare the servers with your files.")
        )
      }
      if(this.state.job.status == 'master' || this.state.job.status == 'workers'){
        instructionText=(
          React.createElement("p", null, "Your job has been deployed.  We have some servers started and will begin processing shortly.")
        )
      }
      if(this.state.job.status == 'terminateJob' || this.state.job.status == 'terminateJobPending' || this.state.job.status == 'saveResult'){
        if(this.state.job.userTerminated){
          instructionText=(
            React.createElement("p", null, "Your job was terminated before it finished.  Your results are being prepared.  Please wait a moment.")
          )
        }
        else{         
          instructionText=(
            React.createElement("p", null, "Your job has finished, and your results are being prepared.  Please wait a moment.")
          ) 
        }
      }
      if(this.state.job.status == 'computeWork' || this.state.job.status == 'chargeWork' || this.state.job.status == 'cleanupPool'){
        instructionText=(
          React.createElement("p", null, "Your results are ready!  We just have to compute how much work we did.")
        )
      }
      
      if(this.state.job.status == 'not run'){
        instructionText=(
          React.createElement("p", null, "Click on “deploy” to obtain a cost estimate, and to set limits on the cost. Then, when you are ready, you can initiate the PEST_HP inversion process.")
        )
        if(!isExpired){
          deployButton = (
              React.createElement("a", {className: "button buttonJob", onClick: this.estimate}, React.createElement("span", null, "Deploy"))
          )
          // deployButton = (
          //     <a className='button buttonJob' onClick={()=>{}} style={{width:'350px!important'}}><span>Deploy (Disabled - under maintenance)</span></a>
          // )
        }
      }
      else if(this.state.job.status != 'complete' && this.state.job.status != 'error'){
        if(this.state.job.userTerminated){
          deployButton = (
              React.createElement("a", {className: "button buttonJob buttonErr"}, React.createElement("span", null, "Stopping")) 
          )

        }
        else if(['deploy','launching','starting','master','workers','processing'].indexOf(this.state.job.status)>=0){
          deployButton = (
              React.createElement("a", {className: "button buttonJob", style: {backgroundColor:'red'}, onClick: this.stop}, React.createElement("span", null, "Stop"))      
          )

        }
      }

      // else if(!this.state.job.cloneId && (this.state.job.status == 'complete' || this.state.job.status == 'error')){
      //   if(!isExpired){
      //     cloneModal=(
      //       <Lightbox ref={'cloneModel'} key={'cloneModel'} opened={self.lightboxOpened} closed={self.lightboxClosed}>
      //         <LightboxTrigger><span></span></LightboxTrigger>
      //         <LightboxModal>
      //           <NewModel 
      //             user={self.props.user}
      //             cloneId={this.state.job.objectId}
      //             modelName={this.state.job.modelName + '_clone'}
      //             packageUrl={this.state.job.packageUrl}
      //             modelDetails={self.props.modelDetails} />
      //         </LightboxModal>
      //       </Lightbox>
      //     ) 
      //     deployButton = <a className='button buttonJob' onClick={self.cloneModel}><span>Clone</span></a>  
      //   }
      // }

      if(this.state.job.cloneId){

          cloneDetails =(
            React.createElement("tr", null, 
              React.createElement("td", null, React.createElement("span", {className: "labelField"}, "Cloned From:")), 
              React.createElement("td", null, React.createElement("span", null, React.createElement("a", {onClick: function(){self.gotoJob(this.state.job.cloneId)}}, this.state.job.cloneId))), 
              React.createElement("td", null)
            )
          )
      }
      if(this.state.job.resultUrl){
        resultUrl = (
          React.createElement("span", null, 
            React.createElement("a", {"data-tooltip": "Zip file containing all files from working directory including result output"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
            React.createElement("a", {href: this.state.job.resultUrl}, "results")
          )
        )
      }
      if(this.state.job.summaryUrl){
        summaryUrl = (
          React.createElement("span", null, 
            React.createElement("a", {"data-tooltip": "Zip file containing several results files from the job"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
            React.createElement("a", {href: this.state.job.summaryUrl}, "summary")
          )
        )
        
        
      }
      var lastError = null;
      if(this.state.job.lastError){
        lastError = (
          React.createElement("tr", null, 
            React.createElement("td", null, React.createElement("span", {className: "labelField"}, "Last Error:")), 
            React.createElement("td", null, React.createElement("span", null, this.state.job.lastError)), 
            React.createElement("td", null)
          )
        )
      }
      var fileSection = null;
      var fileViewer = null;

      if(this.state.job.status=='processing' && this.state.taskFiles && this.state.taskFiles.length){
        fileSection = []


        fileSection.push(
          React.createElement("tr", null, 
            React.createElement("td", null, React.createElement("span", {className: "labelField"}, "Show Files:")), 
            React.createElement("td", {colSpan: "2"}, 
              React.createElement("select", {
                key: "fileSelector", 
                onChange: self.chooseFile, 
                value: self.state.currentTaskFile || ''}, 
                React.createElement("option", {value: ''}, "Choose File"), 
                this.state.taskFiles.map(function(v){
                  var fOnly = v.split('\\').slice(-1)
                  return React.createElement("option", {key: "f_"+fOnly, value: fOnly}, fOnly)
                })
              )
            )
          )
        )

        fileSection.push(
          React.createElement("tr", null, 

            React.createElement("td", null, " "), 
            React.createElement("td", null, 
              React.createElement("span", null, React.createElement("a", {onClick: self.refreshFile}, "refresh"))
            ), 
            React.createElement("td", null, React.createElement("span", null, React.createElement("a", {onClick: self.downloadFile}, "download")))
          )
        )

        fileViewer = (
          React.createElement("div", {className: "fileViewerArea"}, 
            React.createElement("div", {className: "fileViewer"})
          )
        )
      }

      var cpuSection=null
      if(this.state.job.cpuCountActual){
        cpuSection = []


        var poolStart=this.state.job.poolStart
        var poolEnd = this.state.job.poolEnd || new Date()

        var elapsed = this.calculateElapsedTime(poolEnd,poolStart);
        if(elapsed && elapsed.length){

          cpuSection.push(
            React.createElement("tr", null, 
              React.createElement("td", null, 
                React.createElement("span", {className: "labelField"}, "Elapsed Time:")
              ), 
              React.createElement("td", null, " "), 
              React.createElement("td", null, 
                React.createElement("span", null, elapsed)
              )
            )
          )
        }

        cpuSection.push(
          React.createElement("tr", null, 
            React.createElement("td", null, " "), 
            React.createElement("td", null, " "), 
            React.createElement("td", {style: {fontWeight:'bold',textDecoration:'underline'}}, "Target"), 
            React.createElement("td", null, " "), 
            React.createElement("td", {style: {fontWeight:'bold',textDecoration:'underline'}}, "Actual")
          )
        )
        cpuSection.push(
          React.createElement("tr", null, 
            React.createElement("td", null, React.createElement("span", {className: "labelField"}, "CPU Count:")), 
            React.createElement("td", null, " "), 
            React.createElement("td", null, React.createElement("span", null, this.state.job.cpuCount)), 
            React.createElement("td", null, " "), 
            React.createElement("td", null, React.createElement("span", null, this.state.job.cpuCountActual))
          )
        )
        // cpuSection.push(
        //   <tr>
        //     <td><span className='labelField'>Actual CPU Count:</span></td>
        //     <td>&nbsp;</td>
        //     <td><span>{this.state.job.cpuCountActual}</span></td>
        //   </tr>
        // )
      }

      var chargeSection = [];
      if(!isNaN(this.state.job.amountCharged)){ 
        // chargeSection = [];


        chargeSection.push(
          React.createElement("tr", null, 
            React.createElement("td", null, React.createElement("span", {className: "labelField"}, "Card Charged:")), 
            React.createElement("td", null, " "), 
            React.createElement("td", null, React.createElement("span", null, (this.state.job.chargeCaptured && this.state.job.amountCharged>0)?'true':'false')), 
            React.createElement("td", null, " "), 
            React.createElement("td", null, React.createElement("span", null, "$", (this.state.job.amountCharged/100).toFixed(2)))
          )
        )
        if(!isNaN(this.state.job.creditsUsed)){ 
          chargeSection.push(
            React.createElement("tr", null, 
              React.createElement("td", null, React.createElement("span", {className: "labelField"}, "Credits Used:")), 
              React.createElement("td", null, " "), 
              React.createElement("td", null, React.createElement("span", null, this.state.job.credits?'true':'false')), 
              React.createElement("td", null, " "), 
              React.createElement("td", null, React.createElement("span", null, "$", (this.state.job.creditsUsed).toFixed(2)))
            )
          )
        }
        // chargeSection.push(
        //   <tr>
        //     <td><span className='labelField'>Charge Amount:</span></td>
        //     <td>&nbsp;</td>
        //     <td><span>${(this.state.job.amountCharged/100).toFixed(2)}</span></td>
        //   </tr>
        // )
      }
      else if(['launching','starting','master','workers','processing'].indexOf(this.state.job.status)>=0 && this.state.job.cpuCountActual){
        if(self.state.customerDetails){
          if(self.state.customerDetails.sources.data.length){
            var addFundsCardAction = (
                React.createElement("select", {
                  ref: "addFundsPaymentMethod", 
                  value: self.state.paymentMethod}, 
                  
                    self.state.customerDetails.sources.data.map(function(card){
                      return (React.createElement("option", {key: card.id, value: card.id}, card.brand + ' ending in ' + card.last4))
                    })
                  
                )
            );
            var addFundsButton=React.createElement("a", {onClick: function(){self.setState({isAddingFunds:true})}}, "add")
            if(self.state.isAddingFunds){
              addFundsButton=React.createElement("a", {onClick: function(){self.setState({isAddingFunds:false})}}, "cancel")
            }
            var addFundsConfirmButton = (
              React.createElement("a", {className: "button buttonJob", onClick: self.addFunds}, "Add Funds")
            )

            chargeSection.push(
              React.createElement("tr", null, 
                React.createElement("td", null, React.createElement("span", {className: "labelField"}, "Add Funds:")), 
                React.createElement("td", null, 
                  React.createElement("span", null, 
                    React.createElement("a", {"data-tooltip": "If you think you may have underestimated the amount of PEST_HP processing required to finish the calibration process, you can add more funds here."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"}))
                  )
                ), 
                React.createElement("td", {colSpan: "3"}, addFundsButton)
              )
            )
            if(self.state.isAddingFunds){
              chargeSection.push(
                React.createElement("tr", null, 
                  React.createElement("td", {colSpan: "3"}, 
                    React.createElement("input", {
                        className: "rewardField", 
                        type: "text", 
                        ref: "addFundsAmount", 
                        placeholder: "Amount (ex: 50.00)", 
                        defaultValue: ''})
                  ), 
                  React.createElement("td", {colSpan: "2"}, addFundsConfirmButton)
                )
              )
              chargeSection.push(
                React.createElement("tr", null, 
                  React.createElement("td", {colSpan: "5"}, addFundsCardAction)
                )
              )
            }
          }
        }


      }
      var statsSection = null
      if(this.state.job.hoursActual){ 
        statsSection = [];

        
        var cpuCount = +this.state.job.cpuCount
        // cpuCount++
        var cpuActual = +this.state.job.cpuCountActual

        var estHours = +this.state.job.estHours
        var hoursActual = +this.state.job.hoursActual
        var cpuCost = +this.state.job.cpuCost


        statsSection.push(
          React.createElement("tr", null, 
            React.createElement("td", null, 
              React.createElement("span", {className: "labelField"}, "CPU Time:")
            ), 
            React.createElement("td", null, 
              React.createElement("span", null, 
                React.createElement("a", {"data-tooltip": + cpuCount+ ' cpu x '+ (estHours/cpuCount).toFixed(5) + ' hr'}, React.createElement("img", {className: "infoImg", src: "../images/info.png"}))
              )
            ), 
            React.createElement("td", null, 
              React.createElement("span", null, estHours.toFixed(2) + ' hr')
            ), 
            React.createElement("td", null, 
              React.createElement("span", null, 
                React.createElement("a", {"data-tooltip": + cpuActual+ ' cpu x '+ (hoursActual/cpuActual).toFixed(5) + ' hr' + ''}, React.createElement("img", {className: "infoImg", src: "../images/info.png"}))
              )
            ), 
            React.createElement("td", null, 
              React.createElement("span", null, hoursActual.toFixed(2) + ' hr')
            )
          )
        )
        // statsSection.push(
        //   <tr>
        //     <td>
        //       <span className='labelField'>Actual CPU Time:</span>
        //     </td>
        //     <td>
        //       <span>
        //         <a data-tooltip={+ cpuActual+ ' cpu x '+ (hoursActual/cpuActual).toFixed(5) + ' hr'}><img className="infoImg" src="../images/info.png" /></a>
        //       </span>
        //     </td>
        //     <td>
        //       <span>{hoursActual.toFixed(2) + ' hr'}</span>
        //     </td>
        //   </tr>
        // )

        //COST EST
        //+ cpuCount+ ' cpu x '+ (estHours/cpuCount).toFixed(5) + ' hr x $' + cpuCost
        if(cpuCost){

          statsSection.push(
            React.createElement("tr", null, 
              React.createElement("td", null, 
                React.createElement("span", {className: "labelField"}, "Cost:")
              ), 
              React.createElement("td", null, 
                React.createElement("span", null, 
                  React.createElement("a", {"data-tooltip": 'This is the price you agreed to pay.'}, React.createElement("img", {className: "infoImg", src: "../images/info.png"}))
                )
              ), 
              React.createElement("td", null, 
                React.createElement("span", null, "$", (this.state.job.estCost +(this.state.job.additionalFunds||0)).toFixed(2))
              ), 
              React.createElement("td", null, 
                React.createElement("span", null, 
                  React.createElement("a", {"data-tooltip": + cpuActual+ ' cpu x '+ (hoursActual/cpuActual).toFixed(5) + ' hr x $' + cpuCost + '   (Minimum $5 charge plus tax if applicable)'}, React.createElement("img", {className: "infoImg", src: "../images/info.png"}))
                )
              ), 
              React.createElement("td", null, 
                React.createElement("span", null, "$", this.state.job.amountCharged? (this.state.job.amountCharged/100).toFixed(2):((hoursActual*cpuCost).toFixed(2) + ((this.state.job.taxRate && this.state.job.chargeRef)?' + tax':'')))
              )
            )
          )
        }
      }

      var userTerminatedSection = null
      if(this.state.job.userTerminated){        
        userTerminatedSection = (
          React.createElement("tr", null, 
            React.createElement("td", null, React.createElement("span", {className: "labelField"}, "User Terminated:")), 
            React.createElement("td", null, " "), 
            React.createElement("td", null, React.createElement("span", null, "true")), 
            React.createElement("td", null, " "), 
            React.createElement("td", null, " ")
          )
        )
      }


      var packageUrl = (
          React.createElement("span", null, 
            React.createElement("a", {"data-tooltip": "This is the file you originally uploaded"}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
            React.createElement("a", {href: this.state.job.packageUrl}, "package")
          )
        )

      var clonePackageUrl = null;
      if(this.state.job.clonePackageUrl){
        clonePackageUrl = (
          React.createElement("span", null, 
            React.createElement("a", {"data-tooltip": "This is the file you uploaded, which contained any overwrites."}, React.createElement("img", {className: "infoImg", src: "../images/info.png"})), 
            React.createElement("a", {href: this.state.job.clonePackageUrl}, "updates")
          )
        )
      }


      var refreshButton = null;
      if(this.state.job.status != 'complete' && this.state.job.status != 'not run'){
        refreshButton = React.createElement("a", {onClick: this.refreshCurrentJob}, "refresh")
      }
      var deleteButton = null;
      if(this.state.job.status == 'complete' || this.state.job.status == 'not run'){
        deleteButton = React.createElement("a", {className: "button buttonJob", style: {backgroundColor:'red',marginLeft:'0px'}, onClick: this.deleteCurrentJob}, "Delete")
      }
      return (

          React.createElement("div", {style: {padding:'20px'}}, 
            React.createElement("h3", {style: {marginBottom:'10px'}}, "PEST case details: ", this.state.job.modelName, "   "), 
            cloneModal, 
            React.createElement("div", {className: "column-2"}, 
              React.createElement("table", {className: "md-table"}, 
                React.createElement("tbody", null, 
                React.createElement("tr", null, 
                  React.createElement("td", null, React.createElement("span", {className: "labelField"}, "Job ID:")), 
                  React.createElement("td", null, React.createElement("span", null, this.state.job.objectId)), 
                  React.createElement("td", null, deployButton)
                ), 
                React.createElement("tr", null, 
                  React.createElement("td", null, React.createElement("span", {className: "labelField"}, "Status:")), 
                  React.createElement("td", {colSpan: "2"}, React.createElement("span", null, this.state.job.status, "  ", refreshButton))
                ), 
                React.createElement("tr", null, 
                  React.createElement("td", null, React.createElement("span", {className: "labelField"}, "Expires:")), 
                  React.createElement("td", {colSpan: "2"}, React.createElement("span", null, this.state.job.expires?this.state.job.expires.toISOString().replace(/[a-z\.]/gi,' ').trim().split(' ').slice(0,2).join(' '):'', "  ", deleteButton))
                ), 
                cloneDetails, 

                React.createElement("tr", null, 
                  React.createElement("td", null, React.createElement("span", {className: "labelField"}, "Downloadables:")), 
                  React.createElement("td", null, packageUrl), 
                  React.createElement("td", null, clonePackageUrl)
                ), 
                React.createElement("tr", null, 
                  React.createElement("td", null, " "), 
                  React.createElement("td", null, resultUrl), 
                  React.createElement("td", null, summaryUrl)
                ), 
                lastError, 
                fileSection
                )
              )

            ), 
            React.createElement("div", {className: "column-2"}, 
              React.createElement("table", {className: "md-table"}, 
                React.createElement("tbody", null, 
                userTerminatedSection, 
                cpuSection, 
                statsSection, 
                chargeSection
                )
              )
            ), 
            instructionText, 
            fileViewer
            
          )
      )

    }

  }

});

module.exports = ModelDetails;