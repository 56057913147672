var React = require('react');

var Lightbox= require('../Lightbox/Lightbox.react.js');
var LightboxModal= require('../Lightbox/LightboxModal.react.js');
var LightboxTrigger= require('../Lightbox/LightboxTrigger.react.js');

var SimpleCalculator= require('./SimpleCalculator.js');

var HowItWorks = React.createClass({displayName: "HowItWorks",

  getInitialState: function() {
    return {
    };
  },
  
  observe: function() {
    return {
    };
  },

  gotoPEST:function(){
    this.props.gotoPest()
  },
  render: function() {
      return (
        
        React.createElement("div", null, 
          React.createElement("h1", {className: "howHeader"}, "PEST.cloud"), 

          React.createElement("p", null, 
            "Welcome to PEST.cloud – a site that makes it easy for you to calibrate your model on the Microsoft Azure cloud using ", React.createElement("a", {onClick: this.gotoPEST}, "PEST_HP"), ".", 
            React.createElement("br", null), React.createElement("br", null), 
            "Running PEST in the cloud is easy.  You just need to upload your model, choose the number of compute agents, and deploy. For an" + ' ' + 
            "overview of what to expect from the PEST.cloud interface, ", React.createElement("a", {href: "/PESTCloud_PEST_HP.pdf", target: "_blank"}, "click here"), "."
          ), 
          React.createElement("ul", {className: "howSteps"}, 
            React.createElement("li", null, React.createElement("img", {src: "/images/cloud_zip.png", style: {height:'140px',width:'140px'}})), 
            React.createElement("li", null, React.createElement("img", {src: "/images/cloud_upload.png", style: {height:'140px',width:'140px'}})), 
            React.createElement("li", null, React.createElement("img", {src: "/images/cloud_deploy.png", style: {height:'140px',width:'140px'}}))
          ), 
          React.createElement("ul", {className: "howSteps-desc"}, 
            React.createElement("li", null, "1: Zip up all PEST and model files (including model executables)", React.createElement("br", null), React.createElement("a", {href: "#zip"}, "more...")), 
            React.createElement("li", null, "2: Upload your zip file ", React.createElement("br", null), "to PEST.cloud", React.createElement("br", null), React.createElement("a", {href: "#upload"}, "more...")), 
            React.createElement("li", null, "3: Choose number of agents", React.createElement("br", null), "and deploy", React.createElement("br", null), React.createElement("a", {href: "#deploy"}, "more..."))
          ), 
          

          React.createElement("a", {name: "zip"}), 
          React.createElement("h2", {style: {paddingLeft:'20px'}}, "Zip"), 
          React.createElement("p", null, 
            
            "On your own computer, use a compression utility that will generate a .zip file from a folder containing all of your calibration files." + ' ' +  
            "This includes:"
          ), 
          React.createElement("div", {style: {width:'90%',marginLeft:'5%'}}, 
            React.createElement("ul", null, 
              React.createElement("li", null, "PEST files (.pst, .tpl, .ins, etc.)", 

                React.createElement("ul", {style: {marginLeft:'10px'}}, 
                  React.createElement("li", null, "Do not include PEST executable - PEST.cloud uses the latest version of PEST_HP automatically.")
                )
              ), 
              React.createElement("li", null, "All model files, Windows executables, any dependencies", 
                React.createElement("ul", {style: {marginLeft:'10px'}}, 
                  React.createElement("li", null, "Please ensure your programs will work without any 3rd party software installed at the system level.  Any 3rd party executables and their dependencies should be contained in your zip file.")
                )
              )
            )
          ), 

          React.createElement("p", null, " "), 
          React.createElement("a", {name: "upload"}), 
          React.createElement("h2", {style: {paddingLeft:'20px'}}, "Upload"), 
          React.createElement("p", null, 
            "PEST.cloud will transfer the zip file to secure cloud storage.", React.createElement("br", null)
          ), 
          React.createElement("div", {style: {width:'90%',marginLeft:'5%'}}, 
            React.createElement("ul", null, 
              React.createElement("li", null, "Upload your zip file and give your PEST case a unique name (include project number, etc.)."), 
              React.createElement("li", null, "PEST.cloud will automatically detect any PEST control files in your upload."), 
              React.createElement("li", null, "Select the PEST control file to use.  PEST.cloud will analyze the file and list details about your chosen PEST case for you to review. During the deployment step, PEST.cloud uses these details to provide an initial cost estimate.")
            )
          ), 


          React.createElement("p", null, " "), 
          React.createElement("a", {name: "deploy"}), 
          React.createElement("h2", {style: {paddingLeft:'20px'}}, "Deploy"), 
          React.createElement("p", null, 
            "Before any computing takes place, you will be asked to provide a few additional details about your PEST case, including:"
          ), 

          React.createElement("div", {style: {width:'90%',marginLeft:'5%'}}, 
            React.createElement("ul", null, 
              React.createElement("li", null, "Model run time - This is important in order to get a good estimate of the expected cost."), 
              React.createElement("li", null, "Number of compute agents desired - See ", React.createElement("a", {href: "#considerations"}, "below"), " for example costs and considerations."), 
              React.createElement("li", null, "Confirmation of parameter and iteration counts."), 
              React.createElement("li", null, "Type of compute agent - If your model requires more than 4GB of RAM, you have an option to upgrade."), 
              React.createElement("li", null, "Maximum amount to spend on deployment - you will never be charged more than this amount, unless you decide to add additional funds.")
            )
          ), 


          React.createElement("p", null, 
            "Upon deployment, PEST.cloud unzips your contents, starts up as many virtual machines as you specified," + ' ' + 
            "transfers your files to those machines, and then starts PEST_HP. As PEST_HP runs, you can use your browser to review, in real time," + ' ' + 
            "the output files it produces, or download them to your desktop.   When PEST_HP has finished, PEST.cloud will run the “", React.createElement("a", {onClick: this.gotoPEST}, "PEST_whisperer"), "” if desired."
          ), 
          

          React.createElement("p", null, " "), 
          React.createElement("a", {name: "cost"}), 
          React.createElement("h2", {style: {paddingLeft:'20px'}}, "How much does it cost?"), 
          
          React.createElement("p", null, 
            "We offer two different compute agent levels.  They differ in the amount of available RAM." + ' ' +  
            "The first, which should satisfy most users, is $0.35 per hour per agent.  This level provides 3.5 GB of RAM per agent." + ' ' +  
            "The second level costs $0.49 per hour per node and provides 7GB of RAM per agent."
          ), 
          React.createElement("p", null, 
            "We do have a minimum cost.  This minimum cost is the greater of $5 or 5 minutes of running the number of agents you have selected.",   
            React.createElement("br", null), "For example, if you choose 500 agents, the minimum cost would be $14.58 plus any applicable taxes and fees." 
          ), 
          React.createElement("p", null, 
            "There is no cost to upload your PEST case or to get a cost estimate.  You will only be charged if you decide to deploy your PEST case.  Estimates provided include applicable taxes and fees."
          ), 
          React.createElement("p", null, 
            "Not all regions are subject to tax.  We do charge a credit card processing fee, which is 3% of the cloud compute cost plus 30 cents."
          ), 

                React.createElement(Lightbox, {style: {padding:'0px',margin:'0px',marginTop:'10px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
                  React.createElement(LightboxTrigger, null, 
                      React.createElement("button", {className: "StripeCheckout stripeButton"}, 
                          React.createElement("span", null, "Estimate Cost")
                      )
                  ), 
                  React.createElement(LightboxModal, null, 
                    React.createElement(SimpleCalculator, null)
                  )
                ), 

          React.createElement("p", null, " "), 
          React.createElement("a", {name: "considerations"}), 
          React.createElement("h2", {style: {paddingLeft:'20px'}}, "Considerations"), 
          
          React.createElement("p", null, 
            "When deciding how many agents to use for your deployment, you need to consider cost and efficiency. Below, you will find some sample scenarios which show the time benefits when choosing more agents, and the cost benefits when choosing fewer agents. The more agents you choose, the quicker the time to complete each PEST_HP iteration. However the overall inversion process may be less efficient as some agents may be idle when testing parameter updates. Fewer agents will complete the workload more efficiently, but also more slowly.", 
            React.createElement("br", null), React.createElement("br", null), "The overall cost of calibration can also be reduced if you use a “hp starter file”. This can be generated using the latest version of standard PEST or I64PEST. See the PEST_HP manual for details."
          ), 
          
          React.createElement("div", {style: {width:'90%',marginLeft:'5%'}}, 
          React.createElement("h3", {style: {paddingLeft:'0px'}}, "Example 1"), 
            React.createElement("table", {className: "cloudCost", style: {width:'80%'}}, 
              React.createElement("tr", null, 
                React.createElement("td", {style: {fontWeight:'bold',textDecoration:'underline'}}, "Item"), 
                React.createElement("td", {style: {width:'16%',fontWeight:'bold',textDecoration:'underline'}}, "Scenario 1"), 
                React.createElement("td", {style: {width:'16%',fontWeight:'bold',textDecoration:'underline'}}, "Scenario 2")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Model run time (hours):"), 
                React.createElement("td", null, "1.0"), 
                React.createElement("td", null, "1.0")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of parameters:"), 
                React.createElement("td", null, "500"), 
                React.createElement("td", null, "500")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of agents:"), 
                React.createElement("td", null, "500"), 
                React.createElement("td", null, "250")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of parameter upgrade tests per iteration:"), 
                React.createElement("td", null, "140"), 
                React.createElement("td", null, "140")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost of parameter upgrade tests:"), 
                React.createElement("td", null, "$175.00"), 
                React.createElement("td", null, "$87.50")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Time for parameter upgrade tests (hours):"), 
                React.createElement("td", null, "1.0"), 
                React.createElement("td", null, "1.0")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of runs to fill sensitivity matrix (2-pt derivatives):"), 
                React.createElement("td", null, "500"), 
                React.createElement("td", null, "500")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost of filling sensitivity matrix:"), 
                React.createElement("td", null, "$175.00 "), 
                React.createElement("td", null, "$175.00 ")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Time to fill sensitivity matrix (hours):"), 
                React.createElement("td", null, "1.0 "), 
                React.createElement("td", null, "2.0 ")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost per iteration (2-pt derivatives):"), 
                React.createElement("td", null, "$350.00"), 
                React.createElement("td", null, "$263.00")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of runs to fill sensitivity matrix (3-pt derivatives):"), 
                React.createElement("td", null, "1000"), 
                React.createElement("td", null, "1000")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost of filling sensitivity matrix:"), 
                React.createElement("td", null, "$350.00"), 
                React.createElement("td", null, "$350.00")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Time to fill sensitivity matrix (hours):"), 
                React.createElement("td", null, "2.0"), 
                React.createElement("td", null, "4.0")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost per iteration (3-pt derivatives):"), 
                React.createElement("td", null, "$525.00"), 
                React.createElement("td", null, "$437.50")
              )
            ), 

            React.createElement("p", null, " "), 
            React.createElement("h3", {style: {paddingLeft:'0px'}}, "Example 2"), 
            React.createElement("table", {className: "cloudCost", style: {width:'80%'}}, 
              React.createElement("tr", null, 
                React.createElement("td", {style: {fontWeight:'bold',textDecoration:'underline'}}, "Item"), 
                React.createElement("td", {style: {width:'16%',fontWeight:'bold',textDecoration:'underline'}}, "Scenario 1"), 
                React.createElement("td", {style: {width:'16%',fontWeight:'bold',textDecoration:'underline'}}, "Scenario 2")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Model run time (hours):"), 
                React.createElement("td", null, "0.25"), 
                React.createElement("td", null, "0.25")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of parameters:"), 
                React.createElement("td", null, "3000"), 
                React.createElement("td", null, "3000")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of agents:"), 
                React.createElement("td", null, "1000"), 
                React.createElement("td", null, "250")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of parameter upgrade tests per iteration:"), 
                React.createElement("td", null, "140"), 
                React.createElement("td", null, "140")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost of parameter upgrade tests:"), 
                React.createElement("td", null, "$87.50"), 
                React.createElement("td", null, "$21.86")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Time for parameter upgrade tests (hours):"), 
                React.createElement("td", null, "0.25"), 
                React.createElement("td", null, "0.25")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of runs to fill sensitivity matrix (2-pt derivatives):"), 
                React.createElement("td", null, "3000"), 
                React.createElement("td", null, "3000")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost of filling sensitivity matrix:"), 
                React.createElement("td", null, "$262.50 "), 
                React.createElement("td", null, "$262.50 ")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Time to fill sensitivity matrix (hours):"), 
                React.createElement("td", null, "0.75 "), 
                React.createElement("td", null, "3.0 ")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost per iteration (2-pt derivatives):"), 
                React.createElement("td", null, "$350.00"), 
                React.createElement("td", null, "$284.36")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of runs to fill sensitivity matrix (3-pt derivatives):"), 
                React.createElement("td", null, "6000"), 
                React.createElement("td", null, "6000")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost of filling sensitivity matrix:"), 
                React.createElement("td", null, "$525.00"), 
                React.createElement("td", null, "$525.00")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Time to fill sensitivity matrix (hours):"), 
                React.createElement("td", null, "1.5"), 
                React.createElement("td", null, "6.0")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost per iteration (3-pt derivatives):"), 
                React.createElement("td", null, "$612.50"), 
                React.createElement("td", null, "$546.86")
              )
            ), 

            React.createElement("p", null, " "), 

            React.createElement("h3", {style: {paddingLeft:'0px'}}, "Example 3"), 
            React.createElement("table", {className: "cloudCost", style: {width:'80%'}}, 
              React.createElement("tr", null, 
                React.createElement("td", {style: {fontWeight:'bold',textDecoration:'underline'}}, "Item"), 
                React.createElement("td", {style: {width:'16%',fontWeight:'bold',textDecoration:'underline'}}, "Scenario 1"), 
                React.createElement("td", {style: {width:'16%',fontWeight:'bold',textDecoration:'underline'}}, "Scenario 2")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Model run time (hours):"), 
                React.createElement("td", null, "0.5"), 
                React.createElement("td", null, "0.5")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of parameters:"), 
                React.createElement("td", null, "1500"), 
                React.createElement("td", null, "1500")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of agents:"), 
                React.createElement("td", null, "300"), 
                React.createElement("td", null, "50")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of parameter upgrade tests per iteration:"), 
                React.createElement("td", null, "140"), 
                React.createElement("td", null, "50")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost of parameter upgrade tests:"), 
                React.createElement("td", null, "$52.50"), 
                React.createElement("td", null, "$8.75")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Time for parameter upgrade tests (hours):"), 
                React.createElement("td", null, "0.5"), 
                React.createElement("td", null, "0.5")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of runs to fill sensitivity matrix (2-pt derivatives):"), 
                React.createElement("td", null, "1500"), 
                React.createElement("td", null, "1500")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost of filling sensitivity matrix:"), 
                React.createElement("td", null, "$262.50 "), 
                React.createElement("td", null, "$262.50 ")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Time to fill sensitivity matrix (hours):"), 
                React.createElement("td", null, "2.5 "), 
                React.createElement("td", null, "15")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost per iteration (2-pt derivatives):"), 
                React.createElement("td", null, "$315.00"), 
                React.createElement("td", null, "$271.25")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Number of runs to fill sensitivity matrix (3-pt derivatives):"), 
                React.createElement("td", null, "3000"), 
                React.createElement("td", null, "3000")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost of filling sensitivity matrix:"), 
                React.createElement("td", null, "$525.00"), 
                React.createElement("td", null, "$525.00")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Time to fill sensitivity matrix (hours):"), 
                React.createElement("td", null, "5.0"), 
                React.createElement("td", null, "30")
              ), 
              React.createElement("tr", null, 
                React.createElement("td", null, "Cost per iteration (3-pt derivatives):"), 
                React.createElement("td", null, "$577.50"), 
                React.createElement("td", null, "$533.75")
              )
            ), 
            
            React.createElement("p", null, " ")
          )
        )
      )
  }

});

module.exports = HowItWorks;