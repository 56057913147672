var React = require('react');
var Parse = require('parse').Parse;
var ParseReact = require('parse-react-2');

var StripeCheckout = require('react-stripe-checkout');

var Account = React.createClass({displayName: "Account",
  mixins: [ParseReact.Mixin],

  getInitialState: function() {
    return {
      creditBalance:0,
      customerDetails:null,
      licenses:null,
      xpage:0,
      xpageLimit:10
    };
  },
  nextPage: function(){
    var page = this.state.xpage
    page++;
    // console.log({nextPage:page})
    this.setState({
      xpage: page
    })
  },
  prevPage: function(){
    var page = this.state.xpage
    page--;
    page = Math.max(0,page)
    // console.log({prevPage:page})
    this.setState({
      xpage: page
    })
  },
  
  observe: function(props, state) {
    console.log(state.xpage)
    return {
      transactions:(new Parse.Query('userTransaction')).descending("createdAt").limit(state.xpageLimit).skip(state.xpage * state.xpageLimit),
      // user:null,
      // customerId:null
    };
  },
  componentDidMount: function() {
    if(Parse.User.current()){
      var customerId = Parse.User.current().get('stripeCustomer')
      this.setState({
        user:Parse.User.current(),
        customerId:customerId
      },this.getInitialData)
    }
    else{
      console.log('Not Logged In')
    }
  },
  getInitialData: function(){
    this.getCredits();
    this.getCards();
    this.getLicenses();
  },
  getCredits: function(userId){
    var self = this;
    var userId = self.state.user.id || userId
    if(userId){
      $.ajax({
        url: '/acct/credit/'+userId,
        type : 'GET',
        // data : formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(data) {
          if(data.err){
            //console.log(data);
            self.setState({
              loaded:true,
              creditBalance:0
            })
          }
          else{
            self.setState({
              loaded:true,
              creditBalance:data.balance
            })
          }
        }
      });//ajax
    }
    else{
      self.setState({
        loaded:true,
        creditBalance:0
      })
    }
  },
  getCards: function(custId){

    var self = this;
    var customerId = self.state.customerId || custId
    if(customerId){
      $.ajax({
        url: '/acct/customer/'+customerId,
        type : 'GET',
        // data : formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(data) {
          if(data.err){
            //console.log(data);
            self.setState({
              loaded:true,
              customerDetails:null
            })
          }
          else{
            self.setState({
              loaded:true,
              customerDetails:data.customer
            })
          }
        }
      });//ajax
    }
    else{
      self.setState({
        loaded:true,
        customerDetails:null
      })
    }
  },
  getLicenses: function(){

    var self = this;
    if(self.state.user){
      $.ajax({
        url: '/acct/lic/'+self.state.user.id,
        type : 'GET',
        // data : formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(data) {
          if(data.err){
            //console.log(data);
            self.setState({
              loaded:true,
              licenses:null
            })
          }
          else{
            self.setState({
              loaded:true,
              licenses:data.licenses
            })
          }
        }
      });//ajax
    }
  },
  deleteCard: function(cardId){
    var self = this;
      var formData = new FormData();

      formData.append('cardId', cardId);

      if(self.state.customerId){
        formData.append('customerId', self.state.customerId);
      }

      $.ajax({
        url: '/acct/deletecard',
        type : 'POST',
        data : formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(data) {
          if(data.err){
            alert(data.err.message)
          }
          else{
            self.getCards()
          }
        }
      });
  },

  onToken: function(token) {
    var self = this;

      var formData = new FormData();

      formData.append('tokenId', token.id);
      formData.append('email', token.email);

      if(self.state.customerId){
        formData.append('customerId', self.state.customerId);
      }

      $.ajax({
        url: '/acct/customer',
        type : 'POST',
        data : formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(data) {
          if(data.err){
            alert(data.err.message)
          }
          else{
            console.log({updateCustomer:data});
            if(data.customer){
              var customerId = data.customer.id
              ParseReact.Mutation.Set({ 
                className: '_User', 
                objectId: self.state.user.id,
                email:token.email
              },{
                stripeCustomer:customerId
              })
              .dispatch()
              .then(function(){
                setTimeout(function() {
                  self.getCards(customerId)
                }, 1000);
              });
            }
            else{
              self.getCards()
            }
          }
        }
      });

  },

  downloadPest: function(lic){

    var a = window.document.createElement('a');
    a.href = '/acct/download/pest_hp';
    a.download = 'pest_hp.zip';
    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)

  },

  downloadLic: function(lic){

    var self = this;
    if(lic){
      $.ajax({
        url: '/acct/licdl/'+lic,
        type : 'GET',
        // data : formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success : function(data) {
          if(data.lic){

            var licArr = new Uint8Array(atob(data.lic).split('').map(function(c) {return c.charCodeAt(0); }));
            // console.log(licArr)

            var a = window.document.createElement('a');
            var blob = new Blob([licArr], { type: 'application/octet-stream' })
            // console.log(blob)
            a.href = window.URL.createObjectURL(blob);
            // console.log(a.href)
            a.download = 'pest_hp.lic';

            // Append anchor to body.
            document.body.appendChild(a)
            a.click();



            // // Remove anchor from body
            document.body.removeChild(a)

            // var pom = document.createElement('a');
            // //pom.setAttribute('href', 'data:text/plain;charset=us-ascii,' + encodeURIComponent(data.lic));
            // var b64Lic =  btoa(data.lic);
            // console.log(b64Lic)
            // pom.setAttribute('href', 'data:application/octet-stream;charset=us-ascii;base64,' + b64Lic);
            // pom.setAttribute('download', 'pest_hp.lic');
            // pom.click();
            //console.log(data);
            // console.log(data)
          }
        }
      });//ajax
    }
  },

  render: function() {
    var self=this;

    if(!self.state.loaded){
      return React.createElement("div", null, "loading...")
    }

    var xactTable = null;
        
    if(self.data.transactions){
      var prevPageLink = React.createElement("span", null, "   ", React.createElement("a", {onClick: self.prevPage}, '<<<'), "   ")
      var nextPageLink = React.createElement("span", null, "   ", React.createElement("a", {onClick: self.nextPage}, '>>>'), "   ")

console.log(self.data.transactions.length)
      if(self.state.xpage==0){
        prevPageLink = null
      }
      if(self.data.transactions.length<10){
        nextPageLink = null
      }

      if(self.data.transactions && self.data.transactions.length){
        xactTable = []
        xactTable.push(React.createElement("span", {style: {fontSize:'.8em'}}, "This table shows all of your transactions."))
        xactTable.push(
          React.createElement("table", {key: "transactionTable", className: "jobTable"}, 
            React.createElement("thead", null, 
              React.createElement("tr", null, 
                React.createElement("td", null, "Date"), 
                React.createElement("td", null, "Description"), 
                React.createElement("td", null, "Amount"), 
                React.createElement("td", null, "Account"), 
                React.createElement("td", null, "More")

              )
            ), 
            React.createElement("tbody", null, 
          $.map(self.data.transactions,function(tx,txidx){
            return (
              React.createElement("tr", {key: 'xactRow_'+tx.objectId}, 
                React.createElement("td", null, tx.createdAt.toISOString().split('T')[0]), 
                React.createElement("td", null, tx.description), 
                React.createElement("td", {style: {textAlign:'right'}}, "$", tx.amount.toFixed(2)), 
                React.createElement("td", null, tx.card4), 
                React.createElement("td", null, 
                  React.createElement("a", {href: '/acct/receipt/'+tx.objectId, target: "_blank"}, "receipt")
                )
              )
            )
          }), 
              React.createElement("tr", null, 
                React.createElement("td", {colSpan: "5", style: {textAlign:'center'}}, 
                  prevPageLink, 
                  nextPageLink
                )
              )
            )
          )
        )
      }
      else{
        xactTable=React.createElement("span", null, "You currently have no transactions.")
      }
    }

    var cards = React.createElement("span", null, "No Cards")
    if(self.state.loaded && self.state.customerDetails  && self.state.customerDetails.sources.data.length){
      cards = self.state.customerDetails.sources.data.map(function(card){
        return (
          React.createElement("div", {key: 'card_'+card.id, className: "cardRow"}, 
            React.createElement("div", {className: "cardRowImage"}, 
              React.createElement("img", {className: "cardImage", src: '/images/card/'+card.brand + '.png'})
            ), 
            React.createElement("div", {className: "cardLabel"}, 
              React.createElement("span", {className: "cardNumber"}, '...' + card.last4), 
              React.createElement("span", {className: "cardExp"}, ' (' + card.exp_month + '/' + card.exp_year + ')')
            ), 
            React.createElement("div", {className: "deleteCard", onClick: function(){self.deleteCard(card.id)}}, "delete")
          )
        )
      })
    }
    var licenses = React.createElement("span", null, "No Licenses")
    if(self.state.loaded && self.state.licenses  && self.state.licenses.length){
      licenses = self.state.licenses.map(function(lic){
        return (
          React.createElement("div", {key: 'lic'+lic.objectId, className: "licRow"}, 
            React.createElement("div", {className: "licLabel"}, 
              lic.description
            ), 
            React.createElement("div", {className: "licDl", onClick: function(){self.downloadLic(lic.objectId)}}, "license"), 
            React.createElement("div", {className: "appDl", onClick: function(){self.downloadPest()}}, "program")
          )
        )
      })
      licenses.push(

          React.createElement("div", {key: 'beta_v16', className: "licRow"}, 
            React.createElement("div", {className: "licLabel"}, 
              "PEST_HP v16 BETA"
            ), 
            React.createElement("div", {className: "licDl"}, React.createElement("a", {href: "/beta/pest_hp_16_beta.pdf", target: "_blank", style: {color:'white'}}, "manual")), 
            React.createElement("div", {className: "appDl"}, React.createElement("a", {href: "/beta/pest_hp_16_beta.zip", target: "_blank", style: {color:'white'}}, "program"))
          )
      )
    }

    return (


          React.createElement("div", {style: {padding:'40px'}}, 
            React.createElement("div", {className: "column-3"}, 
                React.createElement("span", {className: "labelField"}, "Credits:"), React.createElement("br", null), 
                React.createElement("span", null, "$", self.state.creditBalance.toFixed(2)), React.createElement("br", null), React.createElement("br", null), 
                React.createElement("span", {className: "labelField"}, "Payment methods:"), React.createElement("br", null), 

                cards, 
                React.createElement("a", {href: "https://stripe.com/", target: "_blank"}, 
                React.createElement("img", {src: "/images/stripe.svg", style: {float:'right',marginTop:'15px', zoom:.8,marginRight:'30px'}})
                ), 
                React.createElement(StripeCheckout.default, {
                  token: this.onToken, 
                  stripeKey: "pk_live_8t3sjRHc8qksw448VvJR4QLC", 
                  name: "PEST.cloud", 
                  description: "Add New Card", 
                  image: "http://www.pest.cloud/images/dots.png", 
                  ComponentClass: "div", 
                  panelLabel: "Use Card", 
                  // amount={1000*100}
                  currency: "USD", 
                  email: self.state.user.get('email'), 
                  // Note: Enabling either address option will give the user the ability to
                  // fill out both. Addresses are sent as a second parameter in the token callback.
                  // shippingAddress
                  billingAddress: true, 
                  // ={false}
                  // Note: enabling both zipCode checks and billing or shipping address will
                  // cause zipCheck to be pulled from billing address (set to shipping if none provided).
                  zipCode: true, 
                  // ={false}
                  allowRememberMe: false}, 
                    React.createElement("button", {className: "StripeCheckout stripeButton", style: {marginTop:'15px'}}, 
                        React.createElement("span", null, "Add Card")
                    )
                )
            ), 
            React.createElement("div", {className: "column-23"}, 
              React.createElement("span", {className: "labelField"}, "Software licenses:"), React.createElement("br", null), 
              licenses
            ), 
            React.createElement("div", {style: {marginTop:'35px'}}, 
            xactTable
            )
          )

      
    )
  }

});

module.exports = Account;