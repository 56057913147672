var sha1 = require('sha-1');
var React = require('react');
var Parse = require('parse').Parse;
var ParseReact = require('parse-react-2');

var AppDashboard = require('./AppWrapper.js');
var AppDashboardNoAuth = require('./AppWrapperNoAuth.js');

var LoginWrapper = React.createClass({displayName: "LoginWrapper",
  mixins: [ParseReact.Mixin],

  getInitialState: function() {
    return {
      userVerified:false
    };
  },

  observe: function() {
    return {
      user: ParseReact.currentUser
    };
  },

  checkUserVerified: function(){
    var self=this;
    if(Parse.User.current()){

      Parse.User.current().fetch().then(function(){

        self.setState({
          userVerified:Parse.User.current().get('emailVerified')
        })

      })
    }
  },
  render: function() {
    console.log({'AppWrapper-render':this.data})
    if (this.data.user) {
      if(!(this.data.user.emailVerified || this.state.userVerified)){

        return (
          React.createElement("div", null, 
        React.createElement("h1", null), 
        React.createElement("h2", null), 
          React.createElement("div", {className: "loginForm"}, 
              React.createElement("span", {className: "labelField", style: {whiteSpace: 'normal'}}, "Thank you for signing up.  You should have received an email to verify your account.  Please click the link in that email to continue."), 
                React.createElement("br", null), 
                React.createElement("br", null), 
                React.createElement("br", null), 
                React.createElement("br", null), 
              React.createElement("div", {className: "row centered"}, 
                React.createElement("a", {className: "button", onClick: this.logOut}, 
                  "Log out"
                ), "   ", 
                React.createElement("a", {className: "button", onClick: this.checkUserVerified}, 
                  "I Verified"
                )
              )
          )
          )
        );

      }
      else{

        return (
          React.createElement("div", null, 
              React.createElement(AppDashboard, {username: this.data.user.username, logOut: this.logOut})
          )
        );        
      }


    }
    else{
        return (
          React.createElement("div", null, 
              React.createElement(AppDashboardNoAuth, null)
          )
        );  

    }
  },

  logOut: function() {
    Parse.User.logOut();
  },

});

module.exports = LoginWrapper;