var React = require('react');


var SimpleCalculator = React.createClass({displayName: "SimpleCalculator",

  getInitialState: function() {
    return {
      niter:5,
      npar:100,
      nagent:50,
      mrun:30,
      acost:.35,
      errorItems:[],
      estimatedCost:null
    };
  },
  
  observe: function() {
    return {
    };
  },

  estimateCost: function() {
    var self=this;
    var errorItems = []


    var niterObj = React.findDOMNode(this.refs.niter)
    var nparObj = React.findDOMNode(this.refs.npar)
    var mrunObj = React.findDOMNode(this.refs.mrun)
    var nagentObj = React.findDOMNode(this.refs.nagent)
    var memChoiceObj = React.findDOMNode(this.refs.memChoice)

    var niter = +niterObj.value
    var npar = +nparObj.value
    var mrun = +mrunObj.value
    var nagent = +nagentObj.value
    var memChoice = +memChoiceObj.value
    var estimatedCost=null

    if(!niter || isNaN(+niter) || niter<1){
      errorItems.push('Please enter a valid number of iterations.')
    }
    if(!npar || isNaN(+npar) || npar<1){
      errorItems.push('Please enter a valid number of parameters.')
    }
    if(!mrun || isNaN(+mrun) || mrun<1){
      errorItems.push('Please enter a valid model run time.')
    }
    if(!nagent || isNaN(+nagent) || nagent<1){
      errorItems.push('Please enter a valid number of agents.')
    }

    if(!errorItems.length){

      var minCost = 5;
      var cpuMinCost = nagent*(5/60)*memChoice
      minCost = Math.max(minCost,cpuMinCost)

      estimatedCost = niter * ( (npar + Math.min(140,npar)) * mrun/60 * memChoice)
      estimatedCost = Math.max(estimatedCost,minCost)
    }

    self.setState({errorItems:errorItems,estimatedCost:estimatedCost})
  },

  render: function() {
    var self=this;

    var calcStyle={marginLeft:'10%'}
    var closeButton = null
    if(self.props.closeLightbox){
      calcStyle = {marginLeft:'10%',marginRight:'10%'}
      closeButton=React.createElement("a", {onClick: self.props.closeLightbox}, "close")
      // console.log('can close lightbox')
    }

    var errorItems = null
    var estimatedCost = null
    if(self.state.errorItems && self.state.errorItems.length){
      errorItems = (
        React.createElement("div", {className: "error", style: {color:'red'}}, 
          "Please fix the following fields:", 
          React.createElement("ul", {style: {marginLeft:'20px'}}, 
                      
                  self.state.errorItems.map(function(errItem,eidx){
                    return (React.createElement("li", {key: 'errorItem_'+eidx}, errItem))
                  })
            
          )
        )
      )
    }
    else if(self.state.estimatedCost){
      estimatedCost = (
        React.createElement("div", null, 
            React.createElement("span", {className: "labelField"}, React.createElement("b", null, "Estimated Cost:")), React.createElement("br", null), 
            React.createElement("span", {className: "labelField"}, "$", self.state.estimatedCost.toFixed(2)), React.createElement("br", null), 
            React.createElement("span", {className: "labelField", style: {fontSize:'.8em'}}, "(plus applicable taxes and fees)"), React.createElement("br", null)
        )
      )
    }
    return (

      React.createElement("div", {style: {textAlign:'left'}}, 
        closeButton, 
        React.createElement("div", {style: calcStyle}, 
          React.createElement("div", {className: "column-2"}, 
            React.createElement("span", {className: "labelField"}, "Number of iterations:"), React.createElement("br", null), 
            React.createElement("input", {
              className: "rewardField", 
              type: "text", 
              ref: "niter", 
              placeholder: "enter number", 
              defaultValue: self.state.niter}), 
            React.createElement("br", null), React.createElement("br", null), 

            React.createElement("span", {className: "labelField"}, "Number of parameters:"), React.createElement("br", null), 
            React.createElement("input", {
              className: "rewardField", 
              type: "text", 
              ref: "npar", 
              placeholder: "enter number", 
              defaultValue: self.state.npar}), 
            React.createElement("br", null), React.createElement("br", null), 

            React.createElement("span", {className: "labelField"}, "Model run time (minutes):"), React.createElement("br", null), 
            React.createElement("input", {
              className: "rewardField", 
              type: "text", 
              ref: "mrun", 
              placeholder: "enter number", 
              defaultValue: self.state.mrun}), 
            React.createElement("br", null), React.createElement("br", null), 

            React.createElement("span", {className: "labelField"}, "Number of agents:"), React.createElement("br", null), 
            React.createElement("input", {
              className: "rewardField", 
              type: "text", 
              ref: "nagent", 
              placeholder: "1-1000", 
              defaultValue: self.state.nagent}), 
            React.createElement("br", null), React.createElement("br", null), 

            React.createElement("span", {className: "labelField"}, "Memory option:"), React.createElement("br", null), 
            React.createElement("select", {ref: "memChoice"}, 
              React.createElement("option", {value: ".35"}, "Standard: ~3.5GB Ram/Core"), 
              React.createElement("option", {value: ".49"}, "Enhanced: ~7GB Ram/Core")
            ), 
            React.createElement("br", null), React.createElement("br", null), 
            React.createElement("button", {className: "StripeCheckout stripeButton", onClick: self.estimateCost}, 
                React.createElement("span", null, "Calculate")
            ), 
            React.createElement("br", null), React.createElement("br", null)
          ), 

          React.createElement("div", {className: "column-2"}, 
            errorItems, 
            estimatedCost
          )


        ), 
        React.createElement("div", {className: "disclaimer", style: {fontSize:'11px!important',height:'auto!important'}}, 
          "Please note, your actual cost estimates may vary.  For a more precise estimate, please ensure you" + ' ' + 
          "have created an account and upload a .zip file containing your PEST case files from the \"My PEST Cases\"" + ' ' + 
          "section of the website.  There is no charge to get cost estimates using PEST.cloud.", 
          React.createElement("br", null), React.createElement("br", null), 
          "There is a minimum charge to use this service.  This minimum charge is the" + ' ' + 
          "greater of $5 or 5 minutes of running the number of agents you have selected.  Taxes are" + ' ' + 
          "applied where applicable.  There is a processing fee of 3% plus 30 cents for all credit card transactions."
        ), 
            React.createElement("br", null), React.createElement("br", null)
      )
    )
  }

});

module.exports = SimpleCalculator;