var Parse = require('parse').Parse;
var React = require('react');
var ParseReact = require('parse-react-2');


var AppWrapper = React.createClass({displayName: "AppWrapper",
  mixins: [ParseReact.Mixin],
  getInitialState: function() {
    return {
      currentTab: 0,
    };
  },

  observe: function(){
      return {
        // partners: (new Parse.Query('PartnerSettings')).equalTo('active',true)
      }
  },

  gotoAccount: function(){
    this.selectTab(0);
  },

  gotoHow:function(){
    this.selectTab(1);
  },
  gotoPEST:function(){
    this.selectTab(2);
  },
  gotoTerms:function(){
    this.selectTab(10);
  },
  gotoPrivacy:function(){
    this.selectTab(11);
  },
  signup: function(){
    this.setState({ currentTab: -2 });
  },
  login: function(){
    this.setState({ currentTab: -1 });
  },
  render: function() {
    // if(this.state.isAdmin == undefined || this.pendingQueries().length>0)
    if(this.pendingQueries().length>0)
    {
      return(React.createElement("div", {className: "loading"}))
    }

    //console.log(eProps)
    var content = (React.createElement("div", null));
    switch(this.state.currentTab) {
      case 0:
        content = React.createElement(require('./Pages/Home.js'),{signup:this.signup,login:this.login,gotoPest:this.gotoPEST,gotoHow:this.gotoHow})
        break;
      case -2:
        content = React.createElement(require('./Pages/Login.js'),{signup:true})
        break;
      case -1:
        content = React.createElement(require('./Pages/Login.js'))
        break;
      case 1:
        content = React.createElement(require('./Pages/How.js'),{gotoPest:this.gotoPEST})
        break;
      case 2:
        content = React.createElement(require('./Pages/PestHP.js'),{signup:this.signup,login:this.login})
        break;
      case 6:
        content = React.createElement(require('./Pages/Contact.js'))
        break;
      case 10:
        content = React.createElement(require('./Pages/TermsAndConditions.js'))
        break;
      case 11:
        content = React.createElement(require('./Pages/PrivacyPolicy.js'))
        break;
    }

    return (
        React.createElement("div", null, 
            React.createElement("div", {className: "userOptionsNoAuth"}, React.createElement("a", {onClick: this.login, style: {color:'white'}}, 'log in')), 
            React.createElement("div", {className: "navBar"}, 
            React.createElement("ul", null, 
              React.createElement("li", {ref: "tab_0", className: "selected"}, React.createElement("a", {onClick: this.selectTab.bind(this, 0)}, "Home")), 
              React.createElement("li", {ref: "tab_1", className: ""}, React.createElement("a", {onClick: this.selectTab.bind(this, 1)}, "PEST.cloud")), 
              React.createElement("li", {ref: "tab_2", className: ""}, React.createElement("a", {onClick: this.selectTab.bind(this, 2)}, "PEST_HP")), 
              React.createElement("li", {ref: "tab_6", className: ""}, React.createElement("a", {onClick: this.selectTab.bind(this, 6)}, "Contact Us"))
            )
            
            ), 
            React.createElement("div", {className: "mainPanel"}, 
              content
            ), 
            
            React.createElement("div", {className: "footer"}, 
              React.createElement("div", {className: "copyright"}, "©", new Date().getFullYear(), " ", 
                React.createElement("a", {href: "http://www.sspa.com", target: "_blank"}, 'S.S. Papadopulos & Associates'), ", ",  
                React.createElement("a", {href: "http://www.pesthomepage.org", target: "_blank"}, "Watermark Numerical Computing"), React.createElement("br", null), 
                React.createElement("a", {onClick: this.gotoTerms}, "Terms and Conditions"), " | ", 
                React.createElement("a", {onClick: this.gotoPrivacy}, "Privacy Policy")
              )
            )
        )
    );
  },

  // checkActiveUser: function(id) {
  //   if(this.state.currentUser == id && $.grep(this.data.partners,function(ex){return ex.partner==id}).length==0)
  //   {
  //     this.setState({ currentUser: null });
  //   }
  // },

  selectTab: function(tab) {

    var tabs = {
      '0':React.findDOMNode(this.refs.tab_0),
      '1':React.findDOMNode(this.refs.tab_1),
      '2':React.findDOMNode(this.refs.tab_2),
      '3':React.findDOMNode(this.refs.tab_3),
      '4':React.findDOMNode(this.refs.tab_4),
      '5':React.findDOMNode(this.refs.tab_5),
      '6':React.findDOMNode(this.refs.tab_6),
    }
    $.each(tabs,function(k,v){
      if(tabs[k])
      {
        tabs[k].className = (k==tab?'selected':'');
      }
    });
    this.setState({ currentTab: tab });
  }
});

module.exports = AppWrapper;