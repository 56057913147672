var React = require('react');
var Parse = require('parse').Parse;

var Lightbox= require('../Lightbox/Lightbox.react.js');
var LightboxModal= require('../Lightbox/LightboxModal.react.js');
var LightboxTrigger= require('../Lightbox/LightboxTrigger.react.js');


var SimpleCalculator= require('./SimpleCalculator.js');

var Home = React.createClass({displayName: "Home",

  getInitialState: function() {
    return {
    };
  },
  
  observe: function() {
    return {
    };
  },

  render: function() {
    var self=this;

    var signupQuestion = (

      React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'15px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
        React.createElement("img", {className: "homeLogo", src: "/images/dots.png", style: {width:'70px!important',height:'66px!important',minWidth:'70px'}}), 
        React.createElement("br", null), " ", React.createElement("br", null), 
        "New to PEST.cloud?"
      )
    )
    var signupSection = (
      React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'10px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
        React.createElement("button", {onClick: this.props.signup, className: "StripeCheckout stripeButton"}, 
            React.createElement("span", null, "Get started")
        )
      )
    )

    var signinSection = (

            React.createElement("tr", null, 
              React.createElement("td", null, 
                React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'30px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
                  "Already a user?", React.createElement("br", null), 
                  React.createElement("button", {onClick: this.props.login, className: "StripeCheckout stripeButton", style: {marginTop:'10px',marginLeft:'10px'}}, 
                      React.createElement("span", null, "Sign in")
                  )
                )
              ), 
              React.createElement("td", null, " ")
            )
    );
    if(Parse.User.current()){
      signinSection = null
      signupQuestion = (

        React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'15px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
          React.createElement("img", {className: "homeLogo", src: "/images/dots.png", style: {width:'70px!important',height:'66px!important',minWidth:'70px'}}), 
          React.createElement("br", null), " ", React.createElement("br", null), 
          "Go to:"
        )
      )
      signupSection = (
      React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'10px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
        React.createElement("button", {onClick: this.props.gotoAccount, className: "StripeCheckout stripeButton", style: {width:'110px'}}, 
            React.createElement("span", null, "My Account")
        ), React.createElement("br", null), " ", React.createElement("br", null), 
        React.createElement("button", {onClick: this.props.models, className: "StripeCheckout stripeButton", style: {width:'135px'}}, 
            React.createElement("span", null, "My PEST Cases")
        ), React.createElement("br", null)
      )
    )
    }



        //   <div style={{padding:'20px'}}>
        //     <div className='column-2'>
        //       <img className="homeLogo" src="/images/pc_logo.png" />
        //       <p style={{marginLeft:'10%',marginRight:'10%',textAlign:'center'}}>
        //         Calibrate your model on<br/> the Microsoft Azure<br/> cloud using PEST_HP.
        //         <br/>
        //         <button className="StripeCheckout stripeButton" style={{marginTop:'10px'}}>
        //             <span>More Info</span>
        //         </button>
        //       </p>

        //       <p style={{marginLeft:'10%',marginRight:'10%',textAlign:'center'}}>

        //         <img className="homeLogo" src="/images/dots.png" style={{width:'110px!important',height:'104px!important',minWidth:'110px'}} /><br/>
        //         New to PEST.cloud?
        //         <br/>
        //         <button className="StripeCheckout stripeButton" style={{marginTop:'10px'}}>
        //             <span>Get started</span>
        //         </button>
        //       </p>
        //       {signinSection}

        //     </div>
        //     <div className='column-2'>
        //       <img className="homeLogo" src="/images/hp_logo.png" />
        //       <p style={{marginLeft:'10%',marginRight:'10%',textAlign:'center'}}>
        //         A version of PEST<br/> optimised for use in<br/> highly parallelized<br/>environments.
        //         <br/>
        //         <button className="StripeCheckout stripeButton" style={{marginTop:'10px'}}>
        //             <span>More Info</span>
        //         </button>
        //       </p>

        //       <p style={{marginLeft:'10%',marginRight:'10%',textAlign:'center'}}>
        //         PEST_HP is free to users<br/> of PEST.cloud<br/>
        //       </p>
        //       <p style={{marginLeft:'10%',marginRight:'10%',textAlign:'center'}}>
        //         Want to use it on your<br/> office network or high<br/> performance cluster?
        //         <br/>
        //         <button className="StripeCheckout stripeButton" style={{marginTop:'10px'}}>
        //             <span>More Info</span>
        //         </button>
        //       </p>
        //     </div>
        //   </div>

        // <div className="fullWidth"></div>
        // <div className='column-2'>
        //       <a href="http://www.sspa.com" target="_blank"><img className="homeLogo" src="/images/sspa.png" /></a>
        // </div>
        // <div className='column-2'>
        //       <a href="http://www.pesthomepage.org" target="_blank"><img className="homeLogo" src="/images/watermark.png" /></a>
        // </div>
      return (
        React.createElement("div", {className: "fullWidth"}, 
          React.createElement("table", null, 
            React.createElement("tr", null, 
              React.createElement("td", {style: {width:'45%'}}, 
                React.createElement("img", {className: "homeLogo", src: "/images/pc_logo.png"})
              ), 
              React.createElement("td", {style: {width:'45%'}}, 
                React.createElement("img", {className: "homeLogo", src: "/images/hp_logo.png"})
              )
            ), 
            React.createElement("tr", null, 
              React.createElement("td", null, 
                React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'25px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
                  "Calibrate your model on the Microsoft", React.createElement("br", null), " Azure cloud using PEST_HP."
                )
              ), 
              React.createElement("td", null, 
                React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'25px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
                  "A version of PEST optimised for use in", React.createElement("br", null), " highly parallelized environments."
                )
              )
            ), 
            React.createElement("tr", null, 
              React.createElement("td", null, 
                React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'10px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
                  React.createElement("button", {onClick: this.props.gotoHow, className: "StripeCheckout stripeButton"}, 
                      React.createElement("span", null, "More Info")
                  )
                ), 
                React.createElement(Lightbox, {style: {padding:'0px',margin:'0px',marginTop:'10px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
                  React.createElement(LightboxTrigger, null, 
                      React.createElement("button", {className: "StripeCheckout stripeButton"}, 
                          React.createElement("span", null, "Estimate Cost")
                      )
                  ), 
                  React.createElement(LightboxModal, null, 
                    React.createElement(SimpleCalculator, null)
                  )
                )
              ), 
              React.createElement("td", null, 
                React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'10px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
                  React.createElement("button", {onClick: this.props.gotoPest, className: "StripeCheckout stripeButton"}, 
                      React.createElement("span", null, "More Info")
                  )
                )
              )
            ), 

            React.createElement("tr", null, 
              React.createElement("td", null, 
                signupQuestion
              ), 
              React.createElement("td", {style: {verticalAlign:'top'}}, 
                React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'25px',marginBottom:'-54px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
                  "Want to use it on your office network", React.createElement("br", null), " or high performance cluster?", React.createElement("br", null), " ", React.createElement("br", null), 
                  React.createElement("button", {onClick: this.props.gotoPest, className: "StripeCheckout stripeButton"}, 
                      React.createElement("span", null, "Purchase")
                  ), React.createElement("br", null), " ", React.createElement("br", null)
                )
              )
            ), 
            React.createElement("tr", null, 
              React.createElement("td", null, 
                signupSection
              ), 
              React.createElement("td", null, 
                React.createElement("p", {style: {padding:'0px',margin:'0px',marginTop:'10px',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}, 
                  "There is no need to purchase PEST_HP", React.createElement("br", null), "separately if you are using PEST.cloud."
                )
              )
            ), 
            signinSection, 
            React.createElement("tr", null, 
              React.createElement("td", {style: {width:'45%'}}, 
                React.createElement("a", {href: "http://www.sspa.com", target: "_blank"}, React.createElement("img", {className: "homeLogo", src: "/images/sspa.png"}))
              ), 
              React.createElement("td", {style: {width:'45%'}}, 
                React.createElement("a", {href: "http://www.pesthomepage.org", target: "_blank"}, React.createElement("img", {className: "homeLogo", src: "/images/watermark.png"}))
              )
            )
          )





        )
      )
  }

});

module.exports = Home;